import { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Typography, Button, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Container, FlexBetween } from "../../../commonStyles";
import { useAppDispatch } from "../../../app/hooks";
import { showErrorSnackbar } from "../../../features/snackbar/snackbarSlice";
import { selectProject } from "../../../features/project/projectSlice";
import { IProcess } from "../../../types/process";
import ProcessList from "./ProcessList";
import { selectUser } from "../../../features/auth/authSlice";

const Scrum = () => {
  const project = useSelector(selectProject);
  const storeDispatch = useAppDispatch();

  const [processes, setProcesses] = useState<IProcess[]>([]);

  useQuery(GETPROCESSES, {
    skip: !project,
    variables: {
      filter: {
        project: project?._id,
        users: [],
        currentProcess: true,
      },
      page: 1,
      perPage: 3,
      sort: "SOURCETYPE",
    },
    onCompleted: (data) => {
      setProcesses(data.processPagination.items);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  return (
    <Container sx={{ height: "100%" }}>
      <FlexBetween>
        <Typography sx={{ mb: 0, alignSelf: "center" }} variant="h2" component="div" gutterBottom>
          <strong>Unassigned Processes</strong>
        </Typography>
        {processes.length ? (
          <Button component={Link} to={`/dashboard/project/${project?._id}/unassigned-processes`} color="primary" variant="contained">
            View All
          </Button>
        ) : null}
      </FlexBetween>
      {processes.length ? (
        <Box sx={{ mt: 2 }}>
          {processes.map((process: IProcess, index: number) => {
            return <ProcessList key={index} process={process} index={index} />;
          })}
        </Box>
      ) : (
        <Box sx={{ mt: 1 }}>There are currently no unassigned processes</Box>
      )}
    </Container>
  );
};

const GETPROCESSES = gql`
  query processPagination($filter: FilterFindManyProcessInput, $page: Int!, $perPage: Int!, $sort: SortFindManyProcessInput) {
    processPagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      items {
        _id
        project {
          _id
          name
        }
        processType
        sourceType
        userType
        name
        currentProcess
        default
        steps {
          _id
          completed
          default
          dueDate
          type
          emailTemplate {
            _id
            name
          }
          user {
            firstName
            lastName
            email
          }
          name
        }
        users {
          _id
          firstName
          lastName
          email
        }
        timeBetweenSteps {
          days
          hours
          minutes
        }
        registrant {
          _id
          firstName
          lastName
          fullName
          email
        }
        createdAt
      }
      count
    }
  }
`;

export default Scrum;
