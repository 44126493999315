/* Dependency Imports */
import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Typography, Button } from "@mui/material";
import { useLazyQuery, gql } from "@apollo/client";
import { useLocation } from "react-router";
import SendIcon from "@mui/icons-material/Send";

/* Project Imports */
import BasicTabs from "../../common/BasicTabs";
import { selectProject } from "../../../features/project/projectSlice";
import { FlexBetween } from "../../../commonStyles";
import RegistrantReporting from "./RegistrantReporting";
import ProcessReporting from "./ProcessReporting";
import UserReporting from "./UserReporting";
import Mapping from "./Mapping";
import Report from "./generateReport/Report";
import { useAppDispatch } from "../../../app/hooks";
import { showSuccessSnackbar } from "../../../features/snackbar/snackbarSlice";
import SmsReport from "./smsReport/SmsReport";

const Reporting = () => {
  const location: any = useLocation();

  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const [value, setValue] = useState(location.state?.tab || 0);

  const [downloadGlobalReport] = useLazyQuery(DOWNLOADREPORT, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const downloadTraffic = () => {
    storeDispatch(showSuccessSnackbar("An email will be sent to you shortly."));
    downloadGlobalReport({ variables: { projects: [project?._id], dateStart: new Date(new Date().setHours(0,0,0,0)), dateEnd: new Date(), title: 'Daily' } });
  };

  const tabs = useMemo(() => {
    let tabs = [
      {
        label: "Registrant",
        component: <RegistrantReporting project={project || undefined} />,
      },
      {
        label: "User",
        component: <UserReporting />,
      },
      {
        label: "Process",
        component: <ProcessReporting project={project || undefined} />,
      },
      {
        label: "Mapping",
        component: <Mapping project={project || undefined} />,
      },
    ];

    if (project) {
      tabs.push({
        label: "SMS Report",
        component: <SmsReport project={project || undefined} />,
      });
      tabs.push({
        label: "Generate Report",
        component: <Report project={project || undefined} />,
      });
    }

    return tabs;
  }, [project]);

  return (
    <>
      <FlexBetween sx={{ mb: 2 }}>
        <Typography variant="h2" component="div" gutterBottom>
          <strong>{project?.name || "General"} Reporting</strong>
        </Typography>
        <Button
          onClick={() => downloadTraffic()}
          color="success"
          variant="contained"
          sx={{ mb: { xs: 2, sm: 0 }, width: { xs: "150px", sm: "max-content" } }}
        >
          <SendIcon /> &nbsp;Email Project Report
        </Button>
      </FlexBetween>
      <BasicTabs color="#00142a" tabs={tabs} value={value} setValue={setValue} />
    </>
  );
};

const DOWNLOADREPORT = gql`
  query globalReport($projects: [MongoID!], $dateStart: Date, $dateEnd: Date, $title: String) {
    globalReport(projects: $projects, dateStart: $dateStart, dateEnd: $dateEnd, title: $title)
  }
`;

export default Reporting;
