import { useState, useRef, useMemo, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import { SettingContainer } from "../../../commonStyles";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { gql, useLazyQuery } from "@apollo/client";
import { useAppDispatch } from "../../../app/hooks";
import { IRegistrant } from "../../../types/registrant";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import { showWarningSnackbar } from "../../../features/snackbar/snackbarSlice";
import postalCodes from "../../../assets/postalCodes.json";
import ClusterMap from "../../common/ClusterMap";
import { downloadReport, getLabels } from "../../../utils/function";
import { IProject } from "../../../types/project";
import { blobToBase64, getNumChars } from "../../../utils/function";
import ReportingFilters from "../report/ReportingFilters";
import StandardTable from "../../tables/StandardTable";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);
const postalCodesObj = postalCodes as any;

const RegistrantReporting = ({ project }: RegistrantReportingProps) => {
  const dispatch = useAppDispatch();
  const { projectid } = useParams();

  const [startDate, setStartDate] = useState<Date | null | undefined>(
    new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 6)
  );
  const [endDate, setEndDate] = useState<Date | null | undefined>(
    new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)
  );

  const [registrants, setRegistrants] = useState<IRegistrant[]>([]);
  const [emailUnsubscribes, setEmailUnsubscribes] = useState<string>("");
  const [totalEmailUnsubscribes, setTotalEmailUnsubscribes] = useState<number>(0);
  const [textUnsubscribes, setTextUnsubscribes] = useState<string>("");
  const [totalTextUnsubscribes, setTotalTextUnsubscribes] = useState<number>(0);
  const numberOfRegistrantsRef = useRef<any>(null);
  const realtorTypesRef = useRef<any>(null);
  const ratingRef = useRef<any>(null);
  const sourceRef = useRef<any>(null);
  const [mapImgUrl, setMapImgUrl] = useState<string>("");

  const [getUnsubscribers] = useLazyQuery(GETUNSUBSCRIBECOUNT, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      let filterEmailUnsubscribe = data.unsubscribeCount.emailSchedules.filter((schedule: any) => schedule._id);
      let filterTextUnsubscribe = data.unsubscribeCount.textSchedules.filter((schedule: any) => schedule._id);
      let emailUnsubscribes = filterEmailUnsubscribe.reduce(function (r: any, a: any) {
        return r + a.unsubscribeCount;
      }, 0);
      let textUnsubscribes = filterTextUnsubscribe.reduce(function (r: any, a: any) {
        return r + a.unsubscribeCount;
      }, 0);

      setEmailUnsubscribes(
        `${emailUnsubscribes} (${(emailUnsubscribes / filterEmailUnsubscribe.length).toFixed(2)} Avg - ${
          filterEmailUnsubscribe.length
        } E-blasts sent)`
      );
      setTextUnsubscribes(
        filterTextUnsubscribe.length
          ? `${textUnsubscribes} (${(textUnsubscribes / filterTextUnsubscribe.length).toFixed(2)} Avg - ${
              filterTextUnsubscribe.length
            } text blasts sent)`
          : "0"
      );

      setTotalTextUnsubscribes(
        data.unsubscribeCount.textSchedules.reduce(function (r: any, a: any) {
          return r + a.unsubscribeCount;
        }, 0)
      );
      setTotalEmailUnsubscribes(
        data.unsubscribeCount.emailSchedules.reduce(function (r: any, a: any) {
          return r + a.unsubscribeCount;
        }, 0)
      );
    },
    onError: (e) => console.log(e),
  });

  const getRegistrantsDatasets = (labels: string[]) => {
    let markerLocations: any[] = [];
    let dateRangeData: any[] = [];
    let postalCodeCounts: any = {};

    let results = {
      locations: {
        markerLocations,
        inCanada: 0,
        outOfCanada: 0,
        noPostalCode: 0,
        postalCodeCounts,
      },
      dateRangeData,
    };

    if (!registrants.length) {
      results.locations.postalCodeCounts = [];
      return results;
    }

    for (let i = 0; i < labels.length; ++i) {
      let thisDateRangesData = {
        numberOfRegistrants: 0,
        realtorTypes: {
          isRealtor: 0,
          hasRealtor: 0,
          noRealtor: 0,
        },
        rating: {
          n: 0,
          a: 0,
          b: 0,
          c: 0,
          p: 0,
          r: 0,
          nq: 0,
        },
        source: {
          onlineRegistration: 0,
          walkIn: 0,
        },
      };
      const curDateIndex = new Date(labels[i]);
      const nextDateIndex = i !== labels.length - 1 ? new Date(labels[i + 1]) : undefined;

      for (let registrant of registrants) {
        const inThisDateRange =
          (nextDateIndex &&
            curDateIndex &&
            registrant.createdAt &&
            new Date(registrant.createdAt).getTime() >= curDateIndex.getTime() &&
            new Date(registrant.createdAt).getTime() < nextDateIndex.getTime()) ||
          (!nextDateIndex && curDateIndex && registrant.createdAt && new Date(registrant.createdAt).getTime() >= curDateIndex.getTime());

        if (!inThisDateRange) {
          continue;
        }
        let firstFourPostalCodeChars;
        let coordinates;
        if (registrant.postalCode) {
          firstFourPostalCodeChars = getNumChars(registrant.postalCode, 4).toLocaleUpperCase();
          if (results.locations.postalCodeCounts[firstFourPostalCodeChars]) {
            ++results.locations.postalCodeCounts[firstFourPostalCodeChars];
          } else {
            results.locations.postalCodeCounts[firstFourPostalCodeChars] = 1;
          }

          const coordObj = postalCodesObj[firstFourPostalCodeChars];
          if (coordObj) {
            coordinates = {
              lat: parseFloat(coordObj.latitude),
              lng: parseFloat(coordObj.longitude),
              label: firstFourPostalCodeChars,
            };
          }
        }

        ++thisDateRangesData.numberOfRegistrants;
        registrant.realtorType === "isRealtor" && ++thisDateRangesData.realtorTypes.isRealtor;
        registrant.realtorType === "hasRealtor" && ++thisDateRangesData.realtorTypes.hasRealtor;
        registrant.realtorType === "noRealtor" && ++thisDateRangesData.realtorTypes.noRealtor;
        registrant.rating === "N" && ++thisDateRangesData.rating.n;
        registrant.rating === "A" && ++thisDateRangesData.rating.a;
        registrant.rating === "B" && ++thisDateRangesData.rating.b;
        registrant.rating === "C" && ++thisDateRangesData.rating.c;
        registrant.rating === "P" && ++thisDateRangesData.rating.p;
        registrant.rating === "R" && ++thisDateRangesData.rating.r;
        registrant.rating === "NQ" && ++thisDateRangesData.rating.nq;
        registrant.source.toLowerCase() === "online registration" && ++thisDateRangesData.source.onlineRegistration;
        registrant.source.toLowerCase() === "walk in" && ++thisDateRangesData.source.walkIn;
        if (coordinates) {
          results.locations.markerLocations.push(coordinates);
          ++results.locations.inCanada;
        }
        if (!registrant.postalCode) ++results.locations.noPostalCode;
        if (!coordinates && registrant.postalCode) ++results.locations.outOfCanada;
      }
      results.dateRangeData.push(thisDateRangesData);
    }

    results.locations.postalCodeCounts = Object.entries(results.locations.postalCodeCounts)
      .map((entry: any) => {
        return { postalCode: entry[0], numRegistrants: entry[1] };
      })
      .sort((count1: any, count2: any) => count2.numRegistrants - count1.numRegistrants);
    return results;
  };

  const chartLabels = getLabels(startDate, endDate);
  const { dateRangeData: chartData, locations } = getRegistrantsDatasets(chartLabels);

  const numberRegistrantsOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Number of Registrants",
      },
    },
  };

  const numberRegistrantsData = {
    labels: chartLabels,
    datasets: [
      {
        label: "Number of Registrants",
        data: chartData.map((chartDatum) => chartDatum.numberOfRegistrants),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const realtorTypeOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Realtor Types",
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const realtorTypeData = {
    labels: chartLabels,
    datasets: [
      {
        label: "isRealtor",
        data: chartData.map((chartDatum) => chartDatum.realtorTypes.isRealtor),
        backgroundColor: "rgb(255, 99, 132, 0.5)",
      },
      {
        label: "hasRealtor",
        data: chartData.map((chartDatum) => chartDatum.realtorTypes.hasRealtor),
        backgroundColor: "rgb(75, 192, 192, 0.5)",
      },
      {
        label: "noRealtor",
        data: chartData.map((chartDatum) => chartDatum.realtorTypes.noRealtor),
        backgroundColor: "rgb(53, 162, 235, 0.5)",
      },
    ],
  };

  const ratingOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Rating",
      },
    },
  };

  const ratingData = {
    labels: chartLabels,
    datasets: [
      {
        label: "N",
        data: chartData.map((datum) => datum.rating.n),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "A",
        data: chartData.map((datum) => datum.rating.a),
        borderColor: "rgb(235, 158, 52)",
        backgroundColor: "rgba(235, 158, 52, 0.5)",
      },
      {
        label: "B",
        data: chartData.map((datum) => datum.rating.b),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "C",
        data: chartData.map((datum) => datum.rating.c),
        borderColor: "rgb(129, 53, 235)",
        backgroundColor: "rgba(129, 53, 235, 0.5)",
      },
      {
        label: "P",
        data: chartData.map((datum) => datum.rating.p),
        borderColor: "rgb(64, 194, 164)",
        backgroundColor: "rgba(64, 194, 164, 0.5)",
      },
      {
        label: "R",
        data: chartData.map((datum) => datum.rating.r),
        borderColor: "rgb(65, 117, 59)",
        backgroundColor: "rgba(65, 117, 59, 0.5)",
      },
      {
        label: "NQ",
        data: chartData.map((datum) => datum.rating.nq),
        borderColor: "rgb(188, 33, 194)",
        backgroundColor: "rgba(188, 33, 194, 0.5)",
      },
      {
        label: "Spam",
        data: chartData.map((datum) => datum.rating.spam),
        borderColor: "rgb(188, 33, 200)",
        backgroundColor: "rgba(188, 33, 200, 0.5)",
      },
    ],
  };

  const sourceOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Source",
      },
    },
  };

  const sourceData = {
    labels: chartLabels,
    datasets: [
      {
        label: "Online Registration",
        data: chartData.map((datum) => datum.source.onlineRegistration),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Walk In",
        data: chartData.map((datum) => datum.source.walkIn),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const topPostalCodeColumns = useMemo(() => {
    return [
      {
        Header: "Postal Code",
        accessor: "postalCode",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return <Typography>{value}</Typography>;
        },
      },
      {
        Header: "Number of Registrants",
        accessor: "numRegistrants",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return <Typography>{value}</Typography>;
        },
      },
    ];
  }, []);

  const download = async () => {
    const numberOfRegistrantsImage = numberOfRegistrantsRef?.current.toBase64Image();
    const realtorTypesImage = realtorTypesRef?.current.toBase64Image();
    const ratingImage = ratingRef?.current.toBase64Image();
    const sourceImage = sourceRef?.current.toBase64Image();
    const mapsImage = await blobToBase64(mapImgUrl);

    let graphs = [
      { label: "", image: numberOfRegistrantsImage, table: null },
      { label: "", image: realtorTypesImage, table: null },
      { label: "", image: ratingImage, table: null },
      { label: "", image: sourceImage, table: null },
      { label: "Registrant Locations", image: mapsImage, table: null },
      {
        label: "Most Populated Postal Codes",
        image: null,
        table: {
          headers: ["Postal Code", "Num Registrants"],
          data: locations.postalCodeCounts.slice(0, 15),
        },
      },
    ];

    graphs = graphs.filter((graph) => graph.image !== undefined);

    await downloadReport(
      `${project?.name || "General"} Registrants Report ${startDate?.toDateString()} - ${endDate?.toDateString()}`,
      graphs
    );
  };

  const [getRegistrants] = useLazyQuery(GETREGISTRANTS, {
    onCompleted: (data) => {
      setRegistrants(data.registrantMany);
    },
    onError: (e) => console.log(e),
  });

  const getCountsAndRegistrants = useCallback(async () => {
    if (!startDate || !endDate) {
      dispatch(showWarningSnackbar("Please set a start and end date."));
      return;
    }

    await getRegistrants({
      variables: {
        startDate: startDate,
        endDate: endDate,
        projectId: project?._id,
      },
    });
    if (projectid) {
      await getUnsubscribers({
        variables: {
          dateStart: new Date(startDate),
          dateEnd: new Date(endDate),
          project: projectid,
        },
      });
    } else {
      await getUnsubscribers({
        variables: {
          dateStart: new Date(startDate),
          dateEnd: new Date(endDate),
          project: null,
        },
      });
    }
  }, [getRegistrants, project?._id, endDate, startDate, dispatch]);

  return (
    <SettingContainer>
      <ReportingFilters
        download={download}
        dataArray={registrants}
        setDataArray={setRegistrants}
        queryData={getCountsAndRegistrants}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      <Typography sx={{ mt: 2, mb: 2 }}>
        Total Number of Registrants in this Period: <strong>{registrants.length}</strong>
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        Total Number of Email Unsubscribers: <strong>{totalEmailUnsubscribes}</strong>
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        Total Number of Email Unsubscribers from E-Blasts: <strong>{emailUnsubscribes}</strong>
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        Total Number of Sms Unsubscribers: <strong>{totalTextUnsubscribes}</strong>
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        Total Number of Sms Unsubscribers from Text Blasts: <strong>{textUnsubscribes}</strong>
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box>
            <Bar
              style={{ minHeight: "300px" }}
              ref={numberOfRegistrantsRef}
              options={numberRegistrantsOptions}
              data={numberRegistrantsData}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Bar style={{ minHeight: "300px" }} ref={realtorTypesRef} options={realtorTypeOptions} data={realtorTypeData} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Line style={{ minHeight: "300px" }} ref={ratingRef} options={ratingOptions} data={ratingData} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Line style={{ minHeight: "300px" }} ref={sourceRef} options={sourceOptions} data={sourceData} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "0.75rem", color: "#575757", textAlign: "center", mb: 1 }}>
            <strong>Most Populated Postal Codes</strong>
          </Typography>
          <StandardTable data={locations.postalCodeCounts} loading={false} columns={topPostalCodeColumns} maxHeight={"340px"} />
        </Grid>
        <Grid item xs={12} md={6}>
          <>
            <Typography sx={{ fontSize: "0.75rem", color: "#575757", textAlign: "center", mb: 1 }}>
              <strong>Registrant Locations</strong>
            </Typography>
            {registrants.length > 0 ? (
              <Stack spacing={2}>
                <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                  <Typography sx={{ fontSize: "0.85rem" }}>In Canada: {locations.inCanada}</Typography>
                  <Typography sx={{ fontSize: "0.85rem" }}>Out of Canada: {locations.outOfCanada}</Typography>
                  <Typography sx={{ fontSize: "0.85rem" }}>No Postal Code: {locations.noPostalCode}</Typography>
                </Box>
                <ClusterMap markerLocations={locations.markerLocations} setMapUrl={setMapImgUrl} />
              </Stack>
            ) : (
              <Box
                sx={{
                  background: "linear-gradient(lightblue, lightgreen)",
                  width: "100%",
                  height: {
                    xs: "10rem",
                    sm: "15rem",
                    lg: "20rem",
                  },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                }}
              >
                <img
                  style={{ width: "40%", height: "40%" }}
                  src={"https://upload.wikimedia.org/wikipedia/commons/3/39/Google_Maps_icon_%282015-2020%29.svg"}
                  alt={"Google Maps Logo"}
                />
              </Box>
            )}
          </>
        </Grid>
      </Grid>
    </SettingContainer>
  );
};

const GETREGISTRANTS = gql`
  query getRegistrants($projectId: MongoID, $startDate: String, $endDate: String) {
    registrantMany(filter: { project: $projectId, dateGreaterThanEqual: $startDate, dateLessThanEqual: $endDate }, limit: 100000) {
      firstName
      lastName
      email
      rating
      realtorType
      source
      postalCode
      createdAt
      project {
        name
      }
    }
  }
`;

const GETUNSUBSCRIBECOUNT = gql`
  query unsubscribeCount($project: MongoID, $dateStart: Date!, $dateEnd: Date!) {
    unsubscribeCount(project: $project, dateStart: $dateStart, dateEnd: $dateEnd) {
      textSchedules {
        _id {
          _id
          project {
            _id
          }
        }
        unsubscribeCount
        totalRegistrants
      }
      emailSchedules {
        _id {
          _id
          project {
            _id
          }
        }
        unsubscribeCount
        totalRegistrants
      }
    }
  }
`;

interface RegistrantReportingProps {
  project?: IProject;
}

export default RegistrantReporting;
