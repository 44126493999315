import { useState, Dispatch, SetStateAction } from "react";
import { Typography, Box, Button, TablePagination } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { gql, useLazyQuery, useSubscription, useMutation } from "@apollo/client";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../app/hooks";
import StandardTable from "../tables/StandardTable";
import {
  selectRegistrantCount,
  selectRegistrantList,
  selectProjectQuestions,
  updateRegistrants,
} from "../../features/projectSetting/projectSettingSlice";
import { selectProject } from "../../features/project/projectSlice";
import { FlexBetween } from "../../commonStyles";
import { normalToCamel, downloadPdf, camelToNormal, dashToTitle } from "../../utils/function";
import { GlobalModal } from "../../features/modal/Modal";
import { handleModal } from "../../features/modal/modalSlice";
import { showErrorSnackbar, showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";
import { IUser } from "../../types/user";
import CreateRegistrant from "../projectSettings/CreateRegistrant";
import { selectUser } from "../../features/auth/authSlice";
import MassMail from "./MassMail";
import RegistrantFilter from "./RegistrantFilter";
import MassText from "./MassText";
import ReassignProcesses from "./ReassignProcesses";
import TagRegistrants from "./TagRegistrants";

const RegistrantTable = (props: ChildProps) => {
  const {
    purchasers,
    selectedRegistrants,
    handleGlobalFilterValue,
    columns,
    loading,
    id,
    name,
    pageNumber,
    perPage,
    getPurchasers,
    purchaserCount,
    handleQueryParams,
    handlePerRows,
    realtorTypeFilter,
    statusFilter,
    ratingFilter,
    salesRepFilter,
    onlineSalesRepFilter,
    sourceFilter,
    emailUnsubscribedFilter,
    textUnsubscribedFilter,
    connectedFilter,
    startDateFilter,
    endDateFilter,
    cameInStartDateFilter,
    cameInEndDateFilter,
    tags,
    query,
  } = props;

  const { projectid } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const registrants = useSelector(selectRegistrantList);
  const auth = useSelector(selectUser);
  const count = useSelector(selectRegistrantCount);
  const questions = useSelector(selectProjectQuestions);
  const project = useSelector(selectProject);
  const storeDispatch = useAppDispatch();
  let navigate = useNavigate();

  const [modalType, setModalType] = useState<string>("");

  const [showFilter, setShowFilter] = useState<boolean>(window.innerWidth < 600 ? false : true);

  const [getRegistrants, { loading: downloadLoading }] = useLazyQuery(GETREGISTRANTSFROMLIST, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar("An email will be sent to you shortly"));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar("There was an error emailing list"));
    },
  });

  const [getMailRegistrants, { loading: mailDownloadLoading }] = useLazyQuery(GETREGISTRANTSFROMMAIL, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar("An email will be sent to you shortly"));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar("There was an error emailing list"));
    },
  });

  const [deleteRegistrant, { loading: deleteLoading }] = useMutation(DELETEREGISTRANT, {
    onCompleted: (data) => {
      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar("Registrants have been terminated"));
      navigate(`/dashboard/project/${project?._id}`);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  useSubscription(REGISTRANTSUBSCRIPTION, {
    onSubscriptionData: (data) => {
      const updatedRegistrants = data.subscriptionData.data.updatedRegistrants;
      for (let updatedRegistrant of updatedRegistrants) {
        const curIndex = registrants.findIndex((r: any) => r._id === updatedRegistrant._id);
        if (curIndex === -1) continue;
        let oldRegistrant = registrants[curIndex];
        if (oldRegistrant.rating !== updatedRegistrant.rating) {
          storeDispatch(updateRegistrants({ value: updatedRegistrant.rating, name: "rating", data: oldRegistrant }));
          oldRegistrant = { ...oldRegistrant, rating: updatedRegistrant.rating };
        }
        if (oldRegistrant.salesRep?._id !== updatedRegistrant.salesRep?._id) {
          storeDispatch(updateRegistrants({ value: updatedRegistrant.salesRep, name: "salesRep", data: oldRegistrant }));
          oldRegistrant = { ...oldRegistrant, salesRep: updatedRegistrant.salesRep };
        }
      }
    },
  });

  const download = async (type: string) => {
    if (type === "pdf") {
      let columns = [
        {
          label: "First Name",
          id: "firstName",
        },
        {
          label: "Last Name",
          id: "lastName",
        },
        {
          label: "Email",
          id: "email",
        },
        {
          label: "Phone Number",
          id: "primaryPhone",
        },
        {
          label: "Rating",
          id: "rating",
        },
        {
          label: "Source",
          id: "source",
        },
        {
          label: "Realtor Type",
          id: "realtorType",
        },
        {
          label: "Onsite Rep",
          id: "onsiteRep",
        },
        {
          label: "Online Rep",
          id: "onlineRep",
        },
      ];

      let pdfWidths: any = {
        firstName: 200,
        lastName: 200,
        email: 200,
        primaryPhone: 200,
        rating: 200,
        onsiteRep: 200,
        onlineRep: 200,
        source: 200,
        realtorType: 200,
      };

      let allData = registrants.map((data: any) => {
        return {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          primaryPhone: data.primaryPhone,
          rating: data.rating,
          onsiteRep: data.salesRep ? data.salesRep.fullName : "",
          onlineRep: data.onlineSalesRep ? data.onlineSalesRep.fullName : "",
          source: data.source,
          realtorType: camelToNormal(data.realtorType),
        };
      });

      let sheetTitle = `${project?.name} - Registrants`;

      downloadPdf([allData], [columns], [], [pdfWidths], [sheetTitle], sheetTitle);
    } else {
      if (!query) {
        await getRegistrants({
          variables: {
            project: projectid,
            listId: id,
            page: pageNumber,
            perPage: 100000,
            search: "",
            filter: {
              realtorType: !realtorTypeFilter || realtorTypeFilter === "any" ? null : realtorTypeFilter,
              status: !statusFilter || statusFilter === "any" ? null : statusFilter,
              rating: !ratingFilter || ratingFilter === "any" ? null : ratingFilter,
              salesRep: !salesRepFilter || salesRepFilter === "any" ? null : (salesRepFilter as any)._id,
              onlineSalesRep: !onlineSalesRepFilter || onlineSalesRepFilter === "any" ? null : (onlineSalesRepFilter as any)._id,
              source: !sourceFilter || sourceFilter === "any" ? null : sourceFilter,
              emailUnsubscribed: !emailUnsubscribedFilter || emailUnsubscribedFilter === "any" ? null : emailUnsubscribedFilter,
              textUnsubscribed: !textUnsubscribedFilter || textUnsubscribedFilter === "any" ? null : textUnsubscribedFilter,
              connected: !connectedFilter || connectedFilter === "any" ? null : connectedFilter,
              startDate: startDateFilter,
              endDate: endDateFilter,
              cameInStartDate: cameInStartDateFilter,
              cameInEndDate: cameInEndDateFilter,
              searchStart: searchParams.get("start") ? new Date(parseInt(searchParams.get("start")!, 10)) : null,
              searchEnd: searchParams.get("end") ? new Date(parseInt(searchParams.get("end")!, 10)) : null,
              user: searchParams.get("user") ? searchParams.get("user") : null,
              tags,
            },
            registrants: selectedRegistrants,
            comments: true,
          },
        });
      } else {
        await getMailRegistrants({
          variables: {
            project: projectid,
            mailId: id,
            query: normalToCamel(query!),
            page: pageNumber,
            perPage: 100000,
            search: "",
            registrants: selectedRegistrants,
            download: true,
          },
        });
      }
    }
  };

  const purchaserDownload = async (type: string) => {
    const { data } = await getPurchasers({
      variables: { projects: [projectid], page: pageNumber, perPage: 50000, search: "", download: true },
    });
    if (data.getPurchasers) {
      storeDispatch(showSuccessSnackbar("An email will be sent to you shortly"));
    }
  };

  const handleModalView = (type: string) => {
    if (type === "mail" || type === "text" || type === "reassign" || type === "terminate" || type === "tags") {
      if (!selectedRegistrants.length) return storeDispatch(showErrorSnackbar("No Registrants Selected"));
    }
    setModalType(type);
    storeDispatch(handleModal(true));
  };

  return (
    <div>
      <GlobalModal>
        {modalType === "text" ? (
          <MassText id={id!} selectedRegistrants={selectedRegistrants} project={project ? project._id : null} />
        ) : null}
        {modalType === "mail" ? <MassMail id={id!} selectedRegistrants={selectedRegistrants} project={project!} /> : null}
        {modalType === "registrant" ? <CreateRegistrant project={project} questions={questions} /> : null}
        {modalType === "reassign" ? (
          <ReassignProcesses selectedRegistrants={selectedRegistrants} project={project ? project._id : null} setModalType={setModalType} />
        ) : null}
        {modalType === "tags" ? (
          <TagRegistrants selectedRegistrants={selectedRegistrants} project={project ? project._id : null} setModalType={setModalType} />
        ) : null}
        {modalType === "terminate" ? (
          <Box>
            <Typography variant="h2">
              <strong>Terminate Registrants</strong>
            </Typography>
            <Box sx={{ my: 2 }}>
              Are you sure you would like to terminate {selectedRegistrants.length} registrants? All Registrants will have the processes
              removed and their ratings be set to spam.
            </Box>
            <FlexBetween>
              <Button variant="contained" color="info">
                Cancel
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={() => deleteRegistrant({ variables: { ids: selectedRegistrants, delete: false, rating: "Spam" } })}
              >
                Terminate
              </Button>
            </FlexBetween>
          </Box>
        ) : null}
      </GlobalModal>
      <Typography variant="h2" component="div" gutterBottom>
        <FlexBetween
          sx={{
            "@media (max-width: 600px)": {
              flexDirection: "column",
              mb: 2,
            },
          }}
        >
          <Box
            sx={{
              mb: 2,
              display: "flex",
            }}
          >
            <ArrowBackIcon
              onClick={() => navigate(`/dashboard/project/${project?._id}`)}
              sx={{ mr: 1, cursor: "pointer", color: "primary.main" }}
              fontSize="small"
            />
            <Box>
              <strong>{id === "purchasers" ? "Purchasers" : id && id.length === 24 ? name : dashToTitle(id!)}</strong>
            </Box>
          </Box>
          <Box>
            <Button
              sx={(theme) => ({
                [theme.breakpoints.down("sm")]: {
                  display: "inline-block",
                },
                display: "none",
                mr: 1,
              })}
              color="primary"
              variant="contained"
              onClick={() => setShowFilter(!showFilter)}
            >
              Show Filter
            </Button>
            {auth?.type !== "Developer" && selectedRegistrants.length ? (
              <Button sx={{ mr: 1 }} onClick={() => handleModalView("mail")} color="primary" variant="contained">
                Mass Mail
              </Button>
            ) : null}
            {auth?.type === "Manager" && selectedRegistrants.length ? (
              <Button sx={{ mr: 1 }} onClick={() => handleModalView("text")} color="primary" variant="contained">
                Mass Text
              </Button>
            ) : null}
            {auth?.type === "Manager" && selectedRegistrants.length ? (
              <Button sx={{ mr: 1 }} onClick={() => handleModalView("reassign")} color="primary" variant="contained">
                Reassign Processes
              </Button>
            ) : null}
            {auth?.type === "Manager" && selectedRegistrants.length ? (
              <Button sx={{ mr: 1 }} onClick={() => handleModalView("tags")} color="primary" variant="contained">
                Mass Tag
              </Button>
            ) : null}
            {id !== "purchasers" ? (
              <>
                <Button onClick={() => handleModalView("registrant")} sx={{ mr: 1 }} color="primary" variant="contained">
                  Create
                </Button>
              </>
            ) : null}
            {auth?.type === "Manager" && selectedRegistrants.length ? (
              <Button sx={{ mr: 1 }} onClick={() => handleModalView("terminate")} color="error" variant="contained">
                Terminate Registrants
              </Button>
            ) : null}
          </Box>
        </FlexBetween>
      </Typography>
      {showFilter && id !== "purchasers" && realtorTypeFilter ? <RegistrantFilter /> : null}
      {selectedRegistrants.length ? (
        <Typography variant="h3" component="div" gutterBottom>
          <strong>
            Selected: <Box sx={{ display: "inline", color: "success.main" }}>{selectedRegistrants.length}</Box>
          </strong>
        </Typography>
      ) : null}
      <StandardTable
        data={id !== "purchasers" ? registrants : purchasers}
        columns={columns}
        loading={loading || downloadLoading || mailDownloadLoading || deleteLoading}
        handleGlobalFilterValue={handleGlobalFilterValue}
        count={purchaserCount ? purchaserCount : count}
        download={id === "purchasers" ? purchaserDownload : download}
        user={auth}
      />
      <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
        <TablePagination
          component="div"
          count={purchaserCount ? purchaserCount : count}
          page={pageNumber - 1}
          onPageChange={handleQueryParams}
          rowsPerPage={perPage!}
          rowsPerPageOptions={[15, 25, 50, 100]}
          onRowsPerPageChange={handlePerRows}
        />
      </Box>
    </div>
  );
};

interface ChildProps {
  handleGlobalFilterValue: any;
  selectedRegistrants: any;
  columns: any;
  loading: any;
  id: string | undefined;
  name: string;
  pageNumber: number;
  purchasers: any;
  setPageNumber: Dispatch<SetStateAction<number>>;
  getPurchasers?: any;
  purchaserCount?: number;
  query?: any;
  handleQueryParams?: any;
  handlePerRows?: any;
  perPage?: number | undefined;
  realtorTypeFilter?: string;
  setRealtorTypeFilter?: Dispatch<SetStateAction<string>>;
  statusFilter?: string;
  setStatusFilter?: Dispatch<SetStateAction<string>>;
  ratingFilter?: string;
  setRatingFilter?: Dispatch<SetStateAction<string>>;
  salesRepFilter?: IUser | string;
  setSalesRepFilter?: Dispatch<SetStateAction<IUser | string>>;
  onlineSalesRepFilter?: IUser | string;
  setOnlineSalesRepFilter?: Dispatch<SetStateAction<IUser | string>>;
  sourceFilter?: string;
  setSourceFilter?: Dispatch<SetStateAction<string>>;
  emailUnsubscribedFilter?: boolean | string;
  setEmailUnsubscribedFilter?: Dispatch<SetStateAction<boolean | string>>;
  textUnsubscribedFilter?: boolean | string;
  setTextUnsubscribedFilter?: Dispatch<SetStateAction<boolean | string>>;
  connectedFilter?: boolean | string;
  setConnectedFilter?: Dispatch<SetStateAction<boolean | string>>;
  startDateFilter?: Date | null;
  endDateFilter?: Date | null;
  cameInStartDateFilter?: Date | null;
  cameInEndDateFilter?: Date | null;
  tags?: string[];
}

const GETREGISTRANTSFROMMAIL = gql`
  query getRegistrantsFromMail(
    $project: MongoID
    $mailId: MongoID!
    $query: String!
    $page: Float!
    $perPage: Float!
    $search: String
    $registrants: [MongoID]
    $download: Boolean
  ) {
    getRegistrantsFromMail(
      project: $project
      mailId: $mailId
      query: $query
      page: $page
      perPage: $perPage
      search: $search
      registrants: $registrants
      download: $download
    ) {
      count
      registrants {
        _id
        firstName
        lastName
        email
        primaryPhone
        rating
        source
        realtorType
        emailUnsubscribed {
          type
          date
        }
        textUnsubscribed {
          type
          date
        }
        createdAt
      }
    }
  }
`;

const GETREGISTRANTSFROMLIST = gql`
  query getRegistrantsFromList(
    $project: MongoID
    $listId: MongoID!
    $page: Float!
    $perPage: Float!
    $search: String
    $filter: RegistrantsFromListFilter
    $registrants: [MongoID]
    $comments: Boolean
  ) {
    getRegistrantsFromList(
      project: $project
      listId: $listId
      page: $page
      perPage: $perPage
      search: $search
      filter: $filter
      registrants: $registrants
      comments: $comments
    ) {
      count
      registrants {
        _id
        firstName
        lastName
        email
        primaryPhone
        postalCode
        rating
        ethnicity
        statuses {
          name
          createdAt
        }
        process {
          name
          currentProcess
          steps {
            completed
            dueDate
            name
          }
        }
        source
        salesRep {
          _id
          firstName
          lastName
          fullName
        }
        realtorType
        emailUnsubscribed {
          type
          date
        }
        textUnsubscribed {
          type
          date
        }
        connections {
          date
          user {
            _id
            fullName
          }
        }
        createdAt
        count {
          createdAt
        }
        comments {
          comment
          user {
            fullName
          }
          createdAt
        }
        questions {
          questionId {
            _id
            question
          }
          answer
        }
        salesQuestions {
          questionId {
            _id
            question
          }
          answer
        }
      }
    }
  }
`;

const REGISTRANTSUBSCRIPTION = gql`
  subscription updatedRegistrants {
    updatedRegistrants {
      _id
      rating
      salesRep {
        _id
        firstName
        lastName
        fullName
      }
    }
  }
`;

const DELETEREGISTRANT = gql`
  mutation deleteRegistrant($ids: [MongoID!], $delete: Boolean!, $rating: String) {
    deleteRegistrant(ids: $ids, delete: $delete, rating: $rating)
  }
`;

export default RegistrantTable;
