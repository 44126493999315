/* Dependency Imports */
import { useState, useContext } from "react";
import { useLazyQuery, gql, useMutation } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";
import {
  Grid,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  SelectChangeEvent,
  MenuItem,
  Typography,
  TextField,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../app/hooks";
import { useRegistrantQuery, useRegistrantsQuery } from "../../features/registrants/registrantHooks";
import { selectUser, setUser } from "../../features/auth/authSlice";
import PersonalInformation from "./PersonalInformation";
import Processes from "./Processes";
import Notes from "./Notes";
import Questions from "./Questions";
import Mails from "./Mails";
import {
  selectRegistrant,
  selectRegistrants,
  addRegistrant,
  setRegistrant,
  setProcess,
  setActiveProcesses,
} from "../../features/registrants/registrantsSlice";
import Messages from "./Messages";
import { IRegistrant } from "../../types/registrant";
import { FlexBetween } from "../../commonStyles";
import { selectProjects, setProjects } from "../../features/project/projectSlice";
import { showErrorSnackbar, showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";
import { IProject } from "../../types/project";
import Deals from "./Deals";
import { convertAllDates } from "../../utils/function";
import BasicMenu from "../common/BasicMenu";
import { useAddUserFavouritesMutation, useRemoveUserFavouritesMutation } from "../../features/user/userHooks";
import { RegistrantNotificationContext } from "../../context/RegistrantNotificationContext";
import Appointments from "./Appointments";

/* Project Imports */

const Registrant = () => {
  const { registrantid, projectid } = useParams();
  const { registrantsDispatch } = useContext(RegistrantNotificationContext);
  const user = useSelector(selectUser);
  const selectedRegistrant = useSelector(selectRegistrant);
  const registrants = useSelector(selectRegistrants);
  const projects = useSelector(selectProjects);
  const navigate = useNavigate();
  const { loading } = useRegistrantQuery(registrantid ? registrantid : "");
  const { loading: registrantsLoading } = useRegistrantsQuery(
    selectedRegistrant?.email || "",
    selectedRegistrant?.primaryPhone || "",
    projectid!,
    user
  );
  const storeDispatch = useAppDispatch();

  const [open, setOpen] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<string>("");
  const [dialogType, setDialogType] = useState<string>("");
  // Bebacks
  const [guests, setGuests] = useState<string>("");
  const [reason, setReason] = useState<string>("");

  const [getProjects] = useLazyQuery(GETPROJECTS, {
    onCompleted: (data) => {
      storeDispatch(setProjects(data.projectMany));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [copyRegistrant] = useMutation(CREATEREGISTRANT, {
    onCompleted: (data) => {
      storeDispatch(addRegistrant(data.createRegistrant));
      setOpen(false);
      storeDispatch(showSuccessSnackbar("Copied Registrant!"));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [addBeBack] = useMutation(ADDBEBACK, {
    onCompleted: (data) => {
      storeDispatch(setRegistrant(data.registrantUpdateById.record));
      setOpen(false);
      setGuests("");
      setReason("");
      storeDispatch(showSuccessSnackbar("Be Back Added!"));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [addFavourite] = useAddUserFavouritesMutation((data: any) => {
    const newFavourites = data.userAddFavourites.favourites;
    storeDispatch(setUser({ ...user, favourites: newFavourites }));
    storeDispatch(showSuccessSnackbar("Successfully favourited."));
  });
  const [removeFavourite] = useRemoveUserFavouritesMutation((data: any) => {
    const newFavourites = data.userRemoveFavourites.favourites;
    storeDispatch(setUser({ ...user, favourites: newFavourites }));
    storeDispatch(showSuccessSnackbar("Successfully unfavourited."));
  });

  const [deleteRegistrant] = useMutation(DELETEREGISTRANT, {
    onCompleted: (data) => {
      if (data.deleteRegistrant) {
        storeDispatch(showSuccessSnackbar("Registrant Deleted"));
        navigate(`/dashboard/project/${projectid}`);
      } else {
        storeDispatch(
          setRegistrant({
            ...selectedRegistrant,
            process: [],
            rating: "Spam",
          })
        );
        registrantsDispatch({
          type: "DELETE",
          payload: {
            _id: selectedRegistrant?._id,
          },
        });
        storeDispatch(setActiveProcesses([]));
        storeDispatch(setProcess([]));
        setDialogType("");
        setOpen(false);
        storeDispatch(showSuccessSnackbar("Registrant Terminated"));
      }
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  /* Functions */

  const handleRegistrant = (id: string, projectid: string) => {
    if (id === selectedRegistrant?._id) return;
    navigate(`/dashboard/project/${projectid}/registrant/${id}`);
  };

  const handleDialog = (type: string) => {
    if (type === "copy") {
      if (!projects.length) {
        getProjects({ variables: { filter: { projects: user?.projectAccess.map((access: any) => access.project._id) } } });
      }
    }
    setDialogType(type);
    setOpen(!open);
  };

  const submitCopy = () => {
    copyRegistrant({
      variables: {
        record: {
          email: selectedRegistrant?.email,
          firstName: selectedRegistrant?.firstName,
          lastName: selectedRegistrant?.lastName,
          primaryPhone: selectedRegistrant?.primaryPhone,
          project: selectedProject,
          realtorName: selectedRegistrant?.realtorName,
          realtorType: selectedRegistrant?.realtorType,
          realtorEmail: selectedRegistrant?.realtorEmail,
          source: selectedRegistrant?.source,
          questions: [],
        },
      },
    });
  };

  const handleBeBack = (e: any) => {
    e.preventDefault();
    let count;
    if (selectedRegistrant?.count.length) {
      count = [
        ...selectedRegistrant?.count,
        {
          guests: parseInt(guests, 10),
          reason,
        },
      ];
    } else {
      count = [
        {
          guests: parseInt(guests, 10),
          reason,
        },
      ];
    }
    addBeBack({
      variables: {
        _id: selectedRegistrant?._id,
        record: {
          count,
        },
      },
    });
  };

  const checkBeBack = () => {
    if (selectedRegistrant?.count.length) {
      let todayBeBack = selectedRegistrant.count.some(
        (beback: any) => new Date(beback.createdAt).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
      );
      return todayBeBack;
    } else return false;
  };

  const dialogContent = () => {
    if (dialogType === "copy") {
      return (
        <>
          <DialogTitle>Copying Registrant</DialogTitle>
          <DialogContent>
            <p>Please select a project to copy to</p>
            <Select
              sx={{ width: "100%" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedProject}
              label="Question Type"
              required
              onChange={(e: SelectChangeEvent<string>) => setSelectedProject(e.target.value)}
            >
              {projects
                .filter((project: IProject) => !registrants.map((registrant: IRegistrant) => registrant.project._id).includes(project._id))
                .map((project: IProject, index: number) => {
                  return (
                    <MenuItem key={index} value={project._id}>
                      {project.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <FlexBetween sx={{ width: "100%" }}>
              <Button variant="contained" color="success" onClick={() => submitCopy()}>
                Copy
              </Button>
              <Button variant="contained" color="info" onClick={() => setOpen(false)}>
                Cancel
              </Button>
            </FlexBetween>
          </DialogActions>
        </>
      );
    } else if (dialogType === "beback") {
      return (
        <>
          <DialogTitle>
            <strong>Be Backs</strong>
          </DialogTitle>
          <form onSubmit={handleBeBack}>
            <DialogContent>
              {selectedRegistrant?.count.length ? (
                <Box>
                  {selectedRegistrant.count.map((beback: any) => {
                    return (
                      <Box>
                        <Box>
                          {convertAllDates(beback.createdAt, "PPpp")} - ({beback.guests}) - {beback.reason ? beback.reason : "No Reason"}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              ) : (
                <Box>
                  <em>There are currently no past walk ins for this registrant</em>
                </Box>
              )}
              <Typography sx={{ my: 2 }} variant={"h3"}>
                <strong>Add a Be Back</strong>
              </Typography>
              <Box>
                <TextField
                  fullWidth
                  required
                  type={"number"}
                  label="Number of Additional Guests"
                  variant="outlined"
                  value={guests}
                  onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                  onChange={(e) => setGuests(e.target.value)}
                />
              </Box>
              <Box sx={{ mt: 1 }}>
                <TextField
                  aria-label="minimum height"
                  multiline
                  minRows={3}
                  placeholder="Reason for Be Back"
                  onChange={(e) => setReason(e.target.value)}
                  value={reason}
                  sx={{ width: "100%" }}
                />
              </Box>
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
              <FlexBetween sx={{ width: "100%" }}>
                <Button type="submit" variant="contained" color="success">
                  Add Be Back
                </Button>
                <Button variant="contained" color="info" onClick={() => setOpen(false)}>
                  Cancel
                </Button>
              </FlexBetween>
            </DialogActions>
          </form>
        </>
      );
    } else if (dialogType === "delete") {
      return (
        <>
          <DialogTitle>
            <strong>Delete</strong>
          </DialogTitle>
          <DialogContent>Are you sure you would like to delete this registrant?</DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <FlexBetween sx={{ width: "100%" }}>
              <Button
                onClick={() => deleteRegistrant({ variables: { ids: [selectedRegistrant?._id], delete: true } })}
                variant="contained"
                color="error"
              >
                Delete
              </Button>
              <Button variant="contained" color="info" onClick={() => setOpen(false)}>
                Cancel
              </Button>
            </FlexBetween>
          </DialogActions>
        </>
      );
    } else if (dialogType === "terminate") {
      return (
        <>
          <DialogTitle>
            <strong>Terminate All Processes</strong>
          </DialogTitle>
          <DialogContent>Are you sure you would like to terminate all processes and unsubscribe registrant?</DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <FlexBetween sx={{ width: "100%" }}>
              <Box sx={{}}>
                <Button
                  onClick={() => deleteRegistrant({ variables: { ids: [selectedRegistrant?._id], delete: false, rating: "Spam" } })}
                  variant="contained"
                  color="error"
                >
                  Delete as Spam
                </Button>
                <Button
                  onClick={() => deleteRegistrant({ variables: { ids: [selectedRegistrant?._id], delete: false, rating: "NQ" } })}
                  variant="contained"
                  color="error"
                  sx={{ ml: 1 }}
                >
                  Delete as NQ
                </Button>
                <Button
                  onClick={() =>
                    deleteRegistrant({ variables: { ids: [selectedRegistrant?._id], delete: false, rating: selectedRegistrant?.rating } })
                  }
                  variant="contained"
                  color="error"
                  sx={{ ml: 1 }}
                >
                  Delete
                </Button>
              </Box>
              <Button variant="contained" color="info" onClick={() => setOpen(false)}>
                Cancel
              </Button>
            </FlexBetween>
          </DialogActions>
        </>
      );
    }
  };

  return selectedRegistrant && !loading && !registrantsLoading ? (
    <>
      <div key={selectedRegistrant._id}>
        <Dialog open={open} onClose={() => setOpen(false)}>
          {dialogContent()}
        </Dialog>
        <FlexBetween
          sx={(theme) => ({
            [theme.breakpoints.down("lg")]: {
              display: "flex",
            },
            [theme.breakpoints.up("lg")]: {
              display: "none",
            },
            alignSelf: "center",
            mb: 2,
          })}
        >
          {registrants.length > 1 ? (
            <BasicMenu
              sx={{}}
              title="Projects"
              elements={registrants.map((registrant) => {
                return {
                  label: registrant.project.name,
                  onClick: () => {
                    handleRegistrant(registrant._id, registrant.project._id);
                  },
                };
              })}
            />
          ) : null}
          <BasicMenu
            sx={{}}
            title="functions"
            elements={[
              {
                label: "Copy Registrant",
                onClick: () => {
                  handleDialog("copy");
                },
              },
            ]}
          />
        </FlexBetween>
        <FlexBetween
          sx={(theme) => ({
            [theme.breakpoints.down("lg")]: {
              display: "none",
            },
            [theme.breakpoints.up("lg")]: {
              display: "flex",
            },
            alignSelf: "center",
            mb: 2,
          })}
        >
          {registrants.length < 4 ? (
            <Box sx={{ display: "flex", mr: { xs: 2, sm: 0 } }}>
              {registrants.map((registrant: IRegistrant, index: number) => {
                return (
                  <Button
                    key={index}
                    onClick={() => handleRegistrant(registrant._id, registrant.project._id)}
                    sx={{
                      mr: 2,
                      backgroundColor:
                        registrant._id === selectedRegistrant._id
                          ? registrant.project.portal
                            ? registrant.project.portal.primaryColor
                            : "primary.main"
                          : "primary.main",
                      "&.MuiButton-root:hover": {
                        backgroundColor:
                          registrant._id === selectedRegistrant._id
                            ? registrant.project.portal
                              ? registrant.project.portal.primaryColor
                              : "primary.main"
                            : "primary.main",
                      },
                      width: { xs: "100%", md: "max-content" },
                      mb: { xs: 2, md: 0 },
                    }}
                    variant="contained"
                  >
                    {registrant.project.name}
                  </Button>
                );
              })}
            </Box>
          ) : (
            <BasicMenu
              sx={{}}
              title="Projects"
              elements={registrants.map((registrant) => {
                return {
                  label: registrant.project.name,
                  onClick: () => {
                    handleRegistrant(registrant._id, registrant.project._id);
                  },
                };
              })}
            />
          )}
          <Box sx={{ display: "flex" }}>
            {selectedRegistrant.process.length ? (
              <Button
                sx={{ width: { xs: "100%", md: "max-content" }, mr: 2 }}
                variant="contained"
                color="error"
                onClick={() => handleDialog("terminate")}
              >
                Terminate Process
              </Button>
            ) : null}
            {user?._id === "60d4cc484233c244509a13fc" ||
            user?._id === "6122809b33d0c70d5c7dceed" ||
            user?._id === "651ef89e38235b37200d2761" ? (
              <Button
                sx={{ width: { xs: "100%", md: "max-content" }, mr: 2 }}
                variant="contained"
                color="error"
                onClick={() => handleDialog("delete")}
              >
                Delete Registrant
              </Button>
            ) : null}
            <Button
              disabled={checkBeBack()}
              sx={{ width: { xs: "100%", md: "max-content" } }}
              variant="contained"
              onClick={() => handleDialog("beback")}
            >
              Be Back
            </Button>
            <Button
              sx={{ ml: { xs: 0, md: 2 }, width: { xs: "100%", md: "max-content" } }}
              variant="contained"
              onClick={() => handleDialog("copy")}
            >
              Copy Registrant
            </Button>
            {user?.favourites.find((fav) => fav._id === selectedRegistrant._id) && (
              <Button
                sx={{ ml: { xs: 0, md: 2 }, width: { xs: "100%", md: "max-content" } }}
                variant="contained"
                onClick={() => removeFavourite({ variables: { userId: user?._id, registrantIds: [selectedRegistrant._id] } })}
              >
                <StarIcon sx={{ color: "orange" }}></StarIcon>
              </Button>
            )}
            {!user?.favourites.find((fav) => fav._id === selectedRegistrant._id) && (
              <Button
                sx={{ ml: { xs: 0, md: 2 }, width: { xs: "100%", md: "max-content" } }}
                variant="contained"
                onClick={() => addFavourite({ variables: { userId: user?._id, registrantIds: [selectedRegistrant._id] } })}
              >
                <StarBorderIcon></StarBorderIcon>
              </Button>
            )}
          </Box>
        </FlexBetween>
        <Grid container spacing={2}>
          <Grid item xl={2} lg={3.5} md={3.5} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PersonalInformation registrant={selectedRegistrant} />
              </Grid>
              <Grid item xs={12}>
                <Deals registrant={selectedRegistrant} />
              </Grid>
              <Grid item xs={12}>
                <Mails registrant={selectedRegistrant} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={10} lg={8.5} md={8.5} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Processes registrant={selectedRegistrant} />
              </Grid>
              <Grid item xl={6} xs={12}>
                <Notes registrant={selectedRegistrant} />
              </Grid>
              <Grid item xl={6} xs={12}>
                <Appointments registrant={selectedRegistrant} />
              </Grid>
              {user?.type !== "Developer" ? (
                <Grid item xs={12}>
                  <Messages registrant={selectedRegistrant} />
                </Grid>
              ) : null}
              <Grid item xl={6} xs={12}>
                <Questions registrant={selectedRegistrant} questions={selectedRegistrant.questions} type={"registrant"} />
              </Grid>
              <Grid item xl={6} xs={12}>
                <Questions registrant={selectedRegistrant} questions={selectedRegistrant.salesQuestions} type={"sales"} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  ) : null;
};

/* Types */

/* GQL */

const GETPROJECTS = gql`
  query projectMany($filter: FilterFindManyProjectInput) {
    projectMany(filter: $filter, limit: 100000) {
      _id
      name
    }
  }
`;

const CREATEREGISTRANT = gql`
  mutation createRegistrant($record: CreateOneRegistrantInput!) {
    createRegistrant(record: $record) {
      _id
      project {
        _id
        name
        portal {
          primaryColor
        }
      }
    }
  }
`;

const DELETEREGISTRANT = gql`
  mutation deleteRegistrant($ids: [MongoID!], $delete: Boolean!, $rating: String) {
    deleteRegistrant(ids: $ids, delete: $delete, rating: $rating)
  }
`;

const ADDBEBACK = gql`
  mutation registrantUpdateById($_id: MongoID!, $record: UpdateByIdRegistrantInput!) {
    registrantUpdateById(_id: $_id, record: $record) {
      record {
        _id
        firstName
        lastName
        fullName
        email
        primaryPhone
        postalCode
        realtorName
        realtorEmail
        brokerage
        realtorType
        project {
          _id
          name
        }
        rating
        ethnicity
        statuses {
          name
          createdAt
        }
        salesRep {
          _id
          fullName
        }
        questions {
          answer
          questionId {
            _id
            name
            question
            type
          }
        }
        salesQuestions {
          answer
          questionId {
            _id
            name
            question
            type
          }
        }
        mail {
          _id
        }
        process {
          _id
          currentProcess
          default
          steps {
            _id
            completed
            default
            dueDate
            type
            emailTemplate {
              name
              _id
            }
            user {
              _id
              fullName
            }
            name
          }
          users {
            _id
            fullName
          }
          timeBetweenSteps {
            days
            hours
            minutes
          }
          processType
          name
        }
        appointments {
          _id
          project {
            _id
            salesOffice
            name
          }
          user {
            fullName
            email
            realtor {
              _id
              fullName
              directPhone
            }
          }
          date
          location
          purchaserInfo {
            firstName
            lastName
            email
            primaryPhone
            numberOfGuests
          }
          questions {
            questionId {
              _id
              name
              question
              type
              choices {
                choice
                followUp {
                  _id
                }
              }
            }
            answer
          }
          status
          type
          notes
          confirmed
          cancelled
          cameIn
          noResponse
          salesNotes
          salesRep {
            _id
            fullName
          }
          registrant {
            _id
            tags
            source
          }
          createdAt
        }
        emailUnsubscribed {
          type
          date
          schedule {
            emailTemplate {
              name
            }
          }
        }
        textUnsubscribed {
          type
          date
        }
        connections {
          date
          user {
            _id
            fullName
          }
        }
        count {
          createdAt
          reason
          guests
          scheduleWithRealtor
          meetingWithRealtor
        }
        tags
        createdAt
        source
      }
    }
  }
`;

export default Registrant;
