import { useState } from "react";
import { useLazyQuery, gql } from "@apollo/client";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  Box,
  TextField,
  Grid,
  Button,
  Autocomplete,
} from "@mui/material";

import { IProject } from "../../../../types/project";
import { SettingContainer } from "../../../../commonStyles";
import { sources } from "../../../../utils/constant";
import { useAppDispatch } from "../../../../app/hooks";
import { showErrorSnackbar } from "../../../../features/snackbar/snackbarSlice";
import { camelToNormal } from "../../../../utils/function";
import LoadingLogo from "../../../common/LoadingLogo";
import DataCard from "../../../common/DataCard";

const SmsReport = ({ project }: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const [dateStart, setDateStart] = useState<any>(new Date(new Date().setHours(0, 0, 0, 0)));
  const [dateEnd, setDateEnd] = useState<any>(new Date(new Date().setHours(23, 59, 59, 59)));
  const [selectedSources, setSelectedSources] = useState<string[]>([]);
  const [smsData, setSmsData] = useState<ISmsData[]>([]);

  const [smsStats, { loading }] = useLazyQuery(SMSSTATS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      let sms = Object.keys(data.smsRegistrants).map((key) => ({ title: camelToNormal(key), value: data.smsRegistrants[key] }));

      setSmsData(sms);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Error getting stats"));
    },
  });

  const generateSmsReport = () => {
    smsStats({ variables: { project: project?._id, sources: selectedSources, dateStart, dateEnd } });
  };

  return (
    <SettingContainer>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            multiple
            id="source"
            options={sources}
            freeSolo={false}
            getOptionLabel={(option: any) => option}
            isOptionEqualToValue={(option, value) => option === value}
            value={selectedSources}
            onChange={(e, value) => setSelectedSources(value)}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Sources" />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label={"Start Date (YYYY/MM/DD)"}
              value={dateStart}
              onChange={(newValue) => {
                setDateStart(newValue);
              }}
              sx={{ width: '100%'}}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label={"End Date (YYYY/MM/DD)"}
              value={dateEnd}
              onChange={(newValue) => {
                setDateEnd(newValue);
              }}
              sx={{ width: '100%'}}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button variant="contained" color="primary" onClick={() => generateSmsReport()}>
            Search
          </Button>
        </Grid>
      </Grid>
      {loading ? (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <LoadingLogo />
        </Box>
      ) : (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {smsData.map((smsData: ISmsData) => {
            return (
              <Grid item xs={12} sm={6} md={4} xl={3}>
                <DataCard data={smsData} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </SettingContainer>
  );
};

interface ChildProps {
  project?: IProject;
}

interface ISmsData {
  title: string;
  value: number;
}

const SMSSTATS = gql`
  query smsRegistrants($project: MongoID!, $sources: [String], $dateStart: Date, $dateEnd: Date) {
    smsRegistrants(project: $project, sources: $sources, dateStart: $dateStart, dateEnd: $dateEnd) {
      uniqueRegistrants
      uniqueAutomatedMessagesSent
      uniqueAutomatedSmsReplies
      totalAutomatedSmsReplies
      overallTextMessageReplies
      overallTextMessagesSent
      appointmentsFromAutomatedSms
    }
  }
`;

export default SmsReport;
