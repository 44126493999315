import { useState, useReducer, SetStateAction, Dispatch } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import {
  Typography,
  Box,
  Grid,
  TextField,
  Switch,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Button,
} from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ArrowBack, DeleteForever } from "@mui/icons-material";
import { ICampaign, IEmail } from "../../../types/campaign";
import { FlexBetween, FlexEnd } from "../../../commonStyles";
import { IEmailTemplate } from "../../../types/process";
import { useSelector } from "react-redux";
import { selectProject } from "../../../features/project/projectSlice";
import { useAppDispatch } from "../../../app/hooks";
import { showErrorSnackbar, showSuccessSnackbar } from "../../../features/snackbar/snackbarSlice";
import { normalToCamel, camelToNormal } from "../../../utils/function";

export const emailsReducer = (state: any, action: any) => {
  switch (action.type) {
    case "ADD":
      return [...state, action.payload];
    case "COPY":
      return [...state, action.payload];
    case "UPDATE":
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return {
            ...state,
            [action.payload.field]: action.payload.value,
          };
        } else return state;
      });
    case "UPDATEALL":
      return action.payload;
    case "DELETE":
      return state.filter((state: any, index: number) => index !== action.payload.index);
    default:
      throw new Error();
  }
};

const EditCampaign = (props: ChildProps) => {
  const project = useSelector(selectProject);
  const storeDispatch = useAppDispatch();
  const { campaign, campaigns, handleCampaignView, setCampaigns } = props;

  const [name, setName] = useState<string>(campaign?.name ? campaign.name : "");
  const [active, setActive] = useState<boolean>(campaign?.active ? campaign.active : false);
  const [sourceTypes, setSourceTypes] = useState<string[]>(
    campaign?.sourceTypes ? campaign.sourceTypes.map((userType: string) => camelToNormal(userType)) : []
  );
  const [userTypes, setUserTypes] = useState<string[]>(
    campaign?.userTypes ? campaign.userTypes.map((userType: string) => camelToNormal(userType)) : []
  );
  const [emailTemplates, setEmailTemplates] = useState<IEmailTemplate[]>([]);
  const [emailsState, emailsDispatch] = useReducer(
    emailsReducer,
    campaign?.emails
      ? campaign.emails.map((email: IEmail) => {
          return { ...email, emailTemplate: email.emailTemplate._id };
        })
      : []
  );

  useQuery(GETEMAILTEMPLATES, {
    variables: {
      filter: {
        project: project?._id,
      },
    },
    onCompleted: (data) => {
      setEmailTemplates(data.emailTemplateMany);
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [createCampaign] = useMutation(CREATECAMPAIGN, {
    onCompleted: (data) => {
      setCampaigns([...campaigns, data.campaignCreateOne]);
      storeDispatch(showSuccessSnackbar("Campaign Saved!"));
      handleCampaignView("main");
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [editCampaign] = useMutation(UPDATECAMPAIGN, {
    onCompleted: (data) => {
      let filteredCampaigns = campaigns.map((campaign: ICampaign) => {
        if (campaign._id === data.campaignUpdateById.record._id) {
          return data.campaignUpdateById.record;
        } else return campaign;
      });
      setCampaigns(filteredCampaigns);
      storeDispatch(showSuccessSnackbar("Campaign Updated!"));
      handleCampaignView("main");
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!emailsState.length) return storeDispatch(showErrorSnackbar("Please add an email template"));
    if (!sourceTypes.length) return storeDispatch(showErrorSnackbar("Please add Online or Onsite"));
    if (!userTypes.length) return storeDispatch(showErrorSnackbar("Please add Registrant or Realtor"));

    if (campaign) {
      editCampaign({
        variables: {
          _id: campaign._id,
          record: {
            project: project?._id,
            name: name,
            active: active,
            sourceTypes: sourceTypes.map((userType: string) => normalToCamel(userType)),
            userTypes: userTypes.map((userType: string) => normalToCamel(userType)),
            emails: emailsState.map((email: IEmail) => {
              return {
                ...email,
                mailSchedule: email.mailSchedule._id,
              };
            }),
          },
        },
      });
    } else {
      createCampaign({
        variables: {
          record: {
            project: project?._id,
            name: name,
            active: active,
            sourceTypes: sourceTypes.map((userType: string) => normalToCamel(userType)),
            userTypes: userTypes.map((userType: string) => normalToCamel(userType)),
            emails: emailsState,
          },
        },
      });
    }
  };

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <ArrowBack sx={{ alignSelf: "center", mr: 1, cursor: "pointer" }} onClick={() => handleCampaignView("main")} />
        <Typography sx={{ mb: 0 }} variant="h2" component="div" gutterBottom>
          <strong>{campaign ? "Edit Campaign" : "Create Campaign"}</strong>
        </Typography>
      </Box>
      <Box component="form" sx={{ mt: 2 }} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              title={"Campaign Title"}
              name={"name"}
              fullWidth
              value={name}
              label={"Campaign Title"}
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ alignSelf: "center" }}>
            <FormControlLabel
              checked={active}
              control={<Switch onChange={(e: React.ChangeEvent<HTMLInputElement>) => setActive(!active)} color="secondary" />}
              label="Active"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              multiple
              id="source-type"
              options={["Online", "Onsite"]}
              freeSolo={false}
              getOptionLabel={(option: any) => option}
              isOptionEqualToValue={(option, value) => option === value}
              value={sourceTypes}
              onChange={(e, value) => setSourceTypes(value)}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Online or Onsite" />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              multiple
              id="user-type"
              options={["Is Realtor", "Has Realtor", "No Realtor"]}
              freeSolo={false}
              getOptionLabel={(option: any) => option}
              isOptionEqualToValue={(option, value) => option === value}
              value={userTypes}
              onChange={(e, value) => setUserTypes(value)}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Registrant or Realtor" />}
            />
          </Grid>
        </Grid>
        {emailsState.map((email: IEmail, index: number) => {
          return (
            <Box sx={{ mt: 2 }}>
              <FlexBetween sx={{ mb: 1 }}>
                <Typography variant="h2" component="div" gutterBottom sx={{ mt: 1 }}>
                  <strong>Email {index + 1}</strong>
                </Typography>
                <DeleteForever
                  sx={{ color: "red", alignSelf: "center", cursor: "pointer" }}
                  onClick={() =>
                    emailsDispatch({
                      type: "DELETE",
                      payload: {
                        index: index,
                      },
                    })
                  }
                />
              </FlexBetween>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth required>
                    <InputLabel id="select-source-type">Condition</InputLabel>
                    <Select
                      labelId="select-condition"
                      id="select-condition"
                      name={"condition"}
                      label={"Condition"}
                      required
                      value={email.condition}
                      onChange={(e) =>
                        emailsDispatch({
                          type: "UPDATE",
                          payload: {
                            index: index,
                            field: "condition",
                            value: e.target.value,
                          },
                        })
                      }
                    >
                      <MenuItem value={"registeredDate"}>Registered Date</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    title={"Number of Days Until Sent"}
                    type="number"
                    name={"numberOfDays"}
                    fullWidth
                    value={email.numberOfDays}
                    onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                    label={"Number of Days Until Sent"}
                    required
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      emailsDispatch({
                        type: "UPDATE",
                        payload: {
                          index: index,
                          field: "numberOfDays",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Date Start"
                      value={email.dateStart ? new Date(email.dateStart) : null}
                      onChange={(newValue) => {
                        emailsDispatch({
                          type: "UPDATE",
                          payload: {
                            index: index,
                            field: "dateStart",
                            value: newValue,
                          },
                        });
                      }}
                      sx={{ width: '100%'}}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth required>
                    <InputLabel id="select-source-type">Email Template</InputLabel>
                    <Select
                      labelId="select-condition"
                      id="select-condition"
                      name={"emailTemplate"}
                      label={"Email Template"}
                      value={email.emailTemplate}
                      onChange={(e) =>
                        emailsDispatch({
                          type: "UPDATE",
                          payload: {
                            index: index,
                            field: "emailTemplate",
                            value: e.target.value,
                          },
                        })
                      }
                    >
                      {emailTemplates.map((emailTemplate: IEmailTemplate) => {
                        return <MenuItem value={emailTemplate._id}>{emailTemplate.name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          );
        })}
        <FlexEnd sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              emailsDispatch({
                type: "ADD",
                payload: {
                  condition: "",
                  numberOfDays: 5,
                  emailTemplate: null,
                  dateStart: new Date(),
                },
              })
            }
            sx={{ mr: 1 }}
          >
            Add Email Template
          </Button>
          <Button variant="contained" color="success" type="submit">
            Save Campaign
          </Button>
        </FlexEnd>
      </Box>
    </div>
  );
};

interface ChildProps {
  campaign: ICampaign | null;
  campaigns: ICampaign[];
  handleCampaignView: any;
  setCampaigns: Dispatch<SetStateAction<ICampaign[]>>;
}

const GETEMAILTEMPLATES = gql`
  query emailTemplateMany($filter: FilterFindManyEmailTemplateInput) {
    emailTemplateMany(filter: $filter, limit: 10000) {
      _id
      name
    }
  }
`;

const CREATECAMPAIGN = gql`
  mutation campaignCreateOne($record: CreateOneCampaignInput!) {
    campaignCreateOne(record: $record) {
      _id
      project {
        _id
      }
      name
      sourceTypes
      active
      emails {
        condition
        numberOfDays
        emailTemplate {
          _id
          name
        }
        dateStart
        mailSchedule {
          _id
        }
      }
      userTypes
    }
  }
`;

const UPDATECAMPAIGN = gql`
  mutation campaignUpdateById($_id: MongoID!, $record: UpdateByIdCampaignInput!) {
    campaignUpdateById(_id: $_id, record: $record) {
      record {
        _id
        project {
          _id
        }
        name
        sourceTypes
        active
        emails {
          condition
          numberOfDays
          emailTemplate {
            _id
            name
          }
          dateStart
          mailSchedule {
            _id
          }
        }
        userTypes
      }
    }
  }
`;

export default EditCampaign;
