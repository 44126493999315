import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { IAnswer, IRegistrant } from "../../types/registrant";
import { IProcess, IStep, ITimeBetweenSteps } from "../../types/process";
import { ICall, IText } from "../../types/callRail";

const initialState: IRegistrantState = {
  registrants: [],
  registrant: null,
  activeProcesses: [],
  process: null,
  questions: [],
  salesQuestions: [],
  calls: [],
  texts: [],
};

export const registrantsSlice = createSlice({
  name: "registrants",
  initialState,
  reducers: {
    setRegistrants: (state, action) => {
      state.registrants = action.payload;
    },
    addRegistrant: (state, action) => {
      state.registrants.push(action.payload);
    },
    setRegistrant: (state, action) => {
      state.registrant = action.payload;
    },
    terminateRegistrantProcess: (state) => {
      if (state.registrant) {
        state.registrant.process[state.registrant.process.length - 1].currentProcess = false;
      }
    },
    addtoRegistrantProcess: (state, action) => {
      state.registrant?.process.push(action.payload);
    },
    setProcess: (state, action) => {
      state.process = action.payload;
      state.activeProcesses = state.activeProcesses.map((process: IProcess) => {
        if (process._id === action.payload._id) {
          return action.payload
        } else return process
      })
    },
    setActiveProcesses: (state, action) => {
      state.activeProcesses = action.payload;
    },
    setQuestions: (state, action) => {
      state.questions = action.payload;
    },
    setSalesQuestions: (state, action) => {
      state.salesQuestions = action.payload;
    },
    setTexts: (state, action) => {
      state.texts = action.payload;
    },
    setCalls: (state, action) => {
      state.calls = action.payload;
    },
    updateQuestions: (state, action) => {
      let question = state.questions.findIndex((question: IAnswer) => question.questionId._id === action.payload.questionId._id);
      if (question >= 0) {
        state.questions[question] = action.payload;
      } else {
        state.questions.push(action.payload);
      }
    },
    updateSalesQuestions: (state, action) => {
      let question = state.salesQuestions.findIndex((question: IAnswer) => question.questionId._id === action.payload.questionId._id);
      if (question >= 0) {
        state.salesQuestions[question] = action.payload;
      } else {
        state.salesQuestions.push(action.payload);
      }
    },
    updateStep: (state, action) => {
      if (state.process) {
        let steps = state.process.steps.findIndex((step: IStep) => step._id === action.payload._id);
        if (steps >= 0) {
          state.process.steps[steps] = action.payload;
        }
      }
    },
    addStep: (state, action) => {
      if (state.process) {
        state.process.steps.push(action.payload);
      }
    },
    deleteStep: (state, action) => {
      if (state.process) {
        state.process.timeBetweenSteps = state.process.timeBetweenSteps.filter(
          (time: ITimeBetweenSteps, index: number) => index !== action.payload.index
        );
        state.process.steps = state.process?.steps.filter((step: IStep) => step._id !== action.payload.id);
      }
    },
  },
});

export const {
  setRegistrants,
  setRegistrant,
  setProcess,
  setActiveProcesses,
  setQuestions,
  setSalesQuestions,
  updateQuestions,
  updateSalesQuestions,
  addtoRegistrantProcess,
  updateStep,
  addStep,
  deleteStep,
  terminateRegistrantProcess,
  addRegistrant,
  setCalls,
  setTexts,
} = registrantsSlice.actions;

export const selectRegistrant = (state: RootState) => state.registrants.registrant;
export const selectRegistrants = (state: RootState) => state.registrants.registrants;
export const selectProcess = (state: RootState) => state.registrants.process;
export const selectActiveProcesses = (state: RootState) => state.registrants.activeProcesses;
export const selectQuestions = (state: RootState) => state.registrants.questions;
export const selectSalesQuestions = (state: RootState) => state.registrants.salesQuestions;
export const selectCalls = (state: RootState) => state.registrants.calls;
export const selectTexts = (state: RootState) => state.registrants.texts;

interface IRegistrantState {
  registrants: IRegistrant[];
  registrant: IRegistrant | null;
  activeProcesses: IProcess[];
  process: IProcess | null;
  questions: IAnswer[];
  salesQuestions: IAnswer[];
  calls: ICall[];
  texts: IText[];
}

export default registrantsSlice.reducer;
