import { useState, useMemo, useEffect } from "react";
import { useLazyQuery, useMutation, gql } from "@apollo/client";
import { Button, Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import StandardTable from "../tables/StandardTable";
import { SettingContainer } from "../../commonStyles";
import { handleModal } from "../../features/modal/modalSlice";
import { useAppDispatch } from "../../app/hooks";
import { useProcessQuery } from "../../features/process/processHooks";
import { selectProcesses, selectProcessCount, setProcesses } from "../../features/process/processSlice";
import { showErrorSnackbar, showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";
import { FlexBetween } from "../../commonStyles";
import { IProject } from "../../types/project";
import { selectProjectProcesses, addRemoveProcesses } from "../../features/projectSetting/projectSettingSlice";
import { GlobalModal } from "../../features/modal/Modal";
import { camelToNormal, capitalizeFirstLetter, convertAllDates } from "../../utils/function";
import BasicTabs from "../common/BasicTabs";
import CreateProcess from "../settings/process/CreateProcess";
import { IStepTemplate, IProcessTemplate, IStepTemplateTime, ITimeBetweenSteps } from "../../types/process";

const Processes = (props: ChildProps) => {
  const { project } = props;
  const storeDispatch = useAppDispatch();
  const { loading } = useProcessQuery();
  const processes = useSelector(selectProcesses);
  const projectProcesses = useSelector(selectProjectProcesses);
  const count = useSelector(selectProcessCount);
  const [value, setValue] = useState(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [processId, setProcessId] = useState<IProcessTemplate | null>(null);
  const [processType, setProcessType] = useState<string>("");
  const [processName, setProcessName] = useState<string>("");
  const [userType, setUserType] = useState<string>("");
  const [sourceType, setSourceType] = useState<string>("");
  const [viewType, setViewType] = useState<string>("");
  const [stepTemplates, setStepTemplates] = useState<IStepTemplateTime[]>([]);
  const [defaultProcess, setDefaultProcess] = useState<boolean>(false);
  const [timeBetweenSteps, setTimeBetweenSteps] = useState<ITimeBetweenSteps[]>([]);

  // Queries

  const [getProcesses] = useLazyQuery(GETPROCESSES, {
    onCompleted: (data) => {
      storeDispatch(setProcesses(data.processTemplatePagination));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [addRemoveProjectProcess] = useMutation(ADDREMOVEPROCESS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setProcessId(null);
      setProcessType("");
      setProcessName("");
      setUserType("");
      setViewType("");
      setStepTemplates([]);
      setDefaultProcess(false);
      setTimeBetweenSteps([]);
      storeDispatch(addRemoveProcesses(data.crmProjectSettingUpdateOne.record));
      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar(!value ? "Removed From Project" : "Added To Project"));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  useEffect(() => {
    getProcesses({ variables: { page: pageNumber, perPage: 15, sort: "UPDATEDAT" } });
    // eslint-disable-next-line
  }, [pageNumber]);

  const addRemoveProcess = (process: IProcessTemplate) => {
    if (value) {
      let selectedProcesses: string[] = [];
      if (projectProcesses.length) {
        selectedProcesses = projectProcesses.map((process: IProcessTemplate) => process._id);
      }

      if (process.default) {
        for (const projectProcess of projectProcesses) {
          if (
            projectProcess.sourceType === process.sourceType &&
            projectProcess.userType === process.userType &&
            projectProcess.type === process.type &&
            projectProcess.viewType === process.viewType
          ) {
            return storeDispatch(showErrorSnackbar("Cannot add duplicate default step"));
          }
        }
      }

      selectedProcesses.push(process._id);

      addRemoveProjectProcess({ variables: { record: { processTemplates: selectedProcesses }, filter: { project: project?._id } } });
    } else {
      let removedProcesses = projectProcesses
        .filter((projectProcess: IProcessTemplate) => projectProcess._id !== process._id)
        .map((removedProcess: any) => removedProcess._id);
      addRemoveProjectProcess({ variables: { record: { processTemplates: removedProcesses }, filter: { project: project?._id } } });
    }
  };

  const handleGlobalFilterValue = (value: string) => {
    setPageNumber(1);
    getProcesses({ variables: { filter: { search: value }, page: 1, perPage: 15, sort: "_ID_DESC" } });
  };

  // Columns

  const columns = useMemo(() => {
    const getData = (rowData: IProcessTemplate) => {
      let processRowData: any = rowData;
      setProcessName(processRowData.name);
      setDefaultProcess(processRowData.default);
      setProcessType(processRowData.type);
      setUserType(processRowData.userType);
      setSourceType(processRowData.sourceType);
      setViewType(processRowData.viewType);
      setStepTemplates(processRowData.stepTemplates);
      setTimeBetweenSteps(processRowData.timeBetweenSteps);
      setProcessId(processRowData);
      storeDispatch(handleModal(true));
    };

    return [
      {
        Header: "Name",
        accessor: (rowData: IProcessTemplate) => {
          let disable = value && projectProcesses.map((projectProcess: IProcessTemplate) => projectProcess._id).includes(rowData._id);
          return (
            <Box
              sx={{
                opacity: disable ? 0.5 : 1,
              }}
              onClick={() => (disable ? null : getData(rowData))}
            >
              <strong style={{ cursor: disable ? "auto" : "pointer" }}>{rowData.name}</strong>
            </Box>
          );
        },
      },
      {
        Header: "Process Type",
        accessor: (rowData: IProcessTemplate) => {
          return camelToNormal(rowData.type);
        },
      },
      {
        Header: "Source Type",
        accessor: (rowData: IProcessTemplate) => {
          return capitalizeFirstLetter(rowData.sourceType);
        },
      },
      {
        Header: "User Type",
        accessor: (rowData: IProcessTemplate) => {
          return capitalizeFirstLetter(rowData.userType);
        },
      },
      {
        Header: "Viewable By",
        accessor: (rowData: IProcessTemplate) => rowData.viewType,
      },
      {
        Header: "Default",
        accessor: (rowData: IProcessTemplate) => {
          return rowData.default ? <span style={{ color: "green" }}>Yes</span> : <span style={{ color: "red" }}>No</span>;
        },
      },
      {
        Header: "Date Updated",
        accessor: (rowData: IProcessTemplate) => {
          return convertAllDates(rowData.updatedAt, "PPpp");
        },
      },
    ];
  }, [storeDispatch, value, projectProcesses]);

  const tabs = [
    {
      label: "Project Processes",
      component: <StandardTable data={projectProcesses} columns={columns} loading={loading} />,
    },
    {
      label: "All Processes",
      component: (
        <>
          <StandardTable
            data={processes}
            columns={columns}
            loading={loading}
            handleGlobalFilterValue={handleGlobalFilterValue}
            count={count}
          />
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
              {"<"}
            </Button>
            <span>{pageNumber}</span>
            <Button disabled={pageNumber === Math.ceil(count / 15)} onClick={() => setPageNumber(pageNumber + 1)}>
              {">"}
            </Button>
          </Box>
        </>
      ),
    },
  ];

  return (
    <SettingContainer>
      <GlobalModal>
        <div>
          <Typography variant="h2" component="div" gutterBottom>
            <strong>{processName}</strong>
          </Typography>
          <p>
            <strong>If you would like to make changes, please head to Global Settings</strong>
          </p>
          <CreateProcess
            processType={processType}
            processName={processName}
            userType={userType}
            viewType={viewType}
            sourceType={sourceType}
            defaultProcess={defaultProcess}
            stepTemplates={stepTemplates}
            timeBetweenSteps={timeBetweenSteps}
            disabled={true}
          />
          <FlexBetween>
            <Button color="success" variant="contained" onClick={() => addRemoveProcess(processId!)}>
              {value ? "Add To Project" : "Remove From Project"}
            </Button>
            <Button onClick={() => storeDispatch(handleModal(false))} color="info" variant="contained">
              Cancel
            </Button>
          </FlexBetween>
        </div>
      </GlobalModal>
      <Typography variant="h2" component="div" gutterBottom>
        <strong>Processes</strong>
      </Typography>
      <BasicTabs tabs={tabs} sub={true} value={value} setValue={setValue} />
    </SettingContainer>
  );
};

interface ChildProps {
  project: IProject | null;
}

const GETPROCESSES = gql`
  query processTemplatePagination(
    $filter: FilterFindManyProcessTemplateInput
    $page: Int!
    $perPage: Int!
    $sort: SortFindManyProcessTemplateInput
  ) {
    processTemplatePagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      items {
        _id
        type
        name
        default
        userType
        sourceType
        viewType
        stepTemplates {
          _id
          name
          type
        }
        timeBetweenSteps {
          days
          hours
          minutes
        }
        createdAt
        updatedAt
      }
      count
    }
  }
`;

const ADDREMOVEPROCESS = gql`
  mutation crmProjectSettingUpdateOne($record: UpdateOneCrmProjectSettingInput!, $filter: FilterUpdateOneCrmProjectSettingInput) {
    crmProjectSettingUpdateOne(record: $record, filter: $filter) {
      record {
        _id
        processTemplates {
          _id
          type
          name
          default
          userType
          sourceType
          viewType
          stepTemplates {
            _id
            name
            type
          }
          timeBetweenSteps {
            days
            hours
            minutes
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export default Processes;
