import React, { useState, useEffect, useContext } from "react";
import { Box, IconButton, Badge, Popper, Backdrop, Grid, Tooltip } from "@mui/material";
import { gql, useSubscription } from "@apollo/client";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { IProjectAccess } from "../../types/user";
import { IRegistrant } from "../../types/registrant";
import "../notification/NotificationBell.css";
import { useNavigate } from "react-router";
import { RegistrantNotificationContext } from "../../context/RegistrantNotificationContext";

const RegistrantNotification = () => {
  const user = useSelector(selectUser);
  const { registrantsState, registrantsDispatch } = useContext(RegistrantNotificationContext);
  const navigate = useNavigate();
  const [bellAnimating, setBellAnimating] = useState<boolean>(false);
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const [notificationsAnchor, setNotificationsAnchor] = useState<null | HTMLElement>(null);
  const [registrantLength, setRegistrantLength] = useState<number>(0);
  const [intervalId, setIntervalId] = useState<any>(null);

  useSubscription(REGISTRANTSUBSCRIPTION, {
    variables: { projects: user?.projectAccess.map((project: IProjectAccess) => project.project._id), type: user?.type, userId: user?._id },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (!registrantsState.some((registrant: IRegistrantTimer) => registrant.registrantId === data.logRegistrant.registrantId)) {
        let registrantTimer = {
          ...data.logRegistrant,
          timer: "00:00:00",
          milliseconds: 0,
        };
        setBellAnimating(true);
        setRegistrantLength(registrantsState.length + 1);
        registrantsDispatch({
          type: "ADD",
          payload: registrantTimer,
        });
      }
    },
  });

  useEffect(() => {
    if (showNotifications) {
      setIntervalId(
        setInterval(() => {
          registrantsState.forEach((registrant: any, index: number) => {
            let seconds = Math.floor(((new Date().valueOf() - new Date(registrant.updatedAt!).valueOf()) % 60000) / 1000);
            let minutes = Math.floor(((new Date().valueOf() - new Date(registrant.updatedAt!).valueOf()) % 3600000) / 60000);
            let hours = Math.floor((new Date().valueOf() - new Date(registrant.updatedAt!).valueOf()) / 3600000);
            registrantsDispatch({
              type: "UPDATE",
              payload: {
                index: index,
                field: "timer",
                value: `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`,
              },
            });
            registrantsDispatch({
              type: "UPDATE",
              payload: {
                index: index,
                field: "milliseconds",
                value: new Date().valueOf() - new Date(registrant.updatedAt!).valueOf(),
              },
            });
          });
        }, 1000)
      );
    }
  }, [showNotifications]);

  const handleColor = (timer: number) => {
    let minutes = Math.floor((timer % 3600000) / 60000);
    let hours = Math.floor(timer / 3600000);
    if (hours > 0) {
      return "red";
    } else if (minutes > 4) {
      return "#b6b603";
    } else {
      return "green";
    }
  };

  return (
    <>
      <Tooltip title="Registrant Notifications">
        <IconButton
          onClick={(e) => {
            setBellAnimating(false);
            setShowNotifications(!showNotifications);
            setNotificationsAnchor(notificationsAnchor ? null : e.currentTarget);
            setRegistrantLength(0);
          }}
          sx={{
            display: "inline-flex",
            color: "yellow",
            alignSelf: "center",
            cursor: "pointer",
          }}
        >
          <Badge badgeContent={registrantLength} color="warning">
            <NotificationsIcon
              className={bellAnimating ? "bell-animate" : ""}
              sx={{ color: registrantsState.length ? "yellow" : "white" }}
            />
          </Badge>
        </IconButton>
      </Tooltip>
      <Backdrop
        sx={{ backgroundColor: "rgb(100, 100, 100, 0)", zIndex: 1 }}
        open={showNotifications}
        onClick={(e) => {
          if (intervalId) {
            setIntervalId(null);
            clearInterval(intervalId);
          }
          setShowNotifications((oldNotifications) => !oldNotifications);
          setNotificationsAnchor(notificationsAnchor ? null : e.currentTarget);
        }}
      >
        <Popper open={showNotifications} anchorEl={notificationsAnchor} sx={{ zIndex: 2 }} onClick={(e) => e.stopPropagation()}>
          <Box
            sx={{
              display: "flex",
              backgroundColor: "#dae8ff",
              borderRadius: "5px",
              border: "1px solid black",
              maxHeight: "370px",
              overflow: "auto",
              p: 2,
            }}
          >
            {registrantsState.length ? (
              <Grid container spacing={2} sx={{ width: { xs: "500px", md: "750px" }, fontSize: "11px" }}>
                {registrantsState.map((registrant: IRegistrantTimer, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        sx={{ alignSelf: "center", textAlign: "center", cursor: "pointer" }}
                        onClick={() => {
                          navigate(`/dashboard/project/${registrant.projectId}/registrant/${registrant.registrantId}`);
                        }}
                      >
                        <strong>
                          {registrant.firstName} {registrant.lastName}
                        </strong>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} sx={{ alignSelf: "center", textAlign: "center" }}>
                        {registrant.email}
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} sx={{ alignSelf: "center", textAlign: "center" }}>
                        {registrant.projectName}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        sx={{ alignSelf: "center", textAlign: "center", color: handleColor(registrant.milliseconds) }}
                      >
                        <strong>{registrant.timer}</strong>
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
            ) : (
              <Box>No New Registrants</Box>
            )}
          </Box>
        </Popper>
      </Backdrop>
    </>
  );
};

interface IRegistrantTimer extends IRegistrant {
  timer: string;
  milliseconds: number;
  projectName: string;
  projectId: string;
  registrantId: string;
}

const REGISTRANTSUBSCRIPTION = gql`
  subscription logRegistrant($projects: [String!], $type: String!, $userId: String) {
    logRegistrant(projects: $projects, type: $type, userId: $userId) {
      email
      firstName
      lastName
      projectName
      projectId
      registrantId
      createdAt
      updatedAt
    }
  }
`;

export default RegistrantNotification;
