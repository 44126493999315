import { Outlet, Navigate } from "react-router-dom";
import { selectUser } from "../../features/auth/authSlice";
import { useSelector } from 'react-redux';

const DeveloperRoute = ({
  redirectPath = '/dashboard',
  children,
}: any) => {
  const user = useSelector(selectUser);
  if (user && (user.type === "Marketing" || user.type === "Developer")) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default DeveloperRoute;