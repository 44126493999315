import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { IUser } from "../../../types/user";
import StandardTable from "../../tables/StandardTable";
import { IProjectTotal } from "./Reporting";
import { IProject } from "../../../types/project";
import { Box, Grid } from "@mui/material";
import { downloadExcel, downloadPdf, convertAllDates, camelToNormal } from "../../../utils/function";

const ReportProjectTables = (props: ChildProps) => {
  const { loading, user, projectTotals, dateStart, title, dateEnd, project, users } = props;

  const projectColumns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: (rowData: any) => {
          return (
            <Link style={{ color: "#000", textDecoration: "none" }} to={`/dashboard/project/${rowData.id}`}>
              <strong style={{ cursor: "pointer" }}>{rowData.name}</strong>
            </Link>
          );
        },
      },
      {
        Header: "Processes Assigned",
        accessor: (rowData: any) => rowData.processesAssigned,
      },
      {
        Header: "Steps Completed",
        accessor: (rowData: any) => rowData.stepsCompleted,
      },
      {
        Header: "Steps Overdue",
        accessor: (rowData: any) => rowData.stepsOverDue,
      },
      {
        Header: "Appointments Created",
        accessor: (rowData: any) => rowData.appointmentsCreated,
      },
      {
        Header: "Scheduled Appointments",
        accessor: (rowData: any) => rowData.appointmentsBooked,
      },
      {
        Header: "Appointments Showed Up",
        accessor: (rowData: any) => rowData.appointmentsShowedUp,
      },
      {
        Header: "Appointments To Sales",
        accessor: (rowData: any) => rowData.appointmentsSales,
      },
      {
        Header: "Emails Sent",
        accessor: (rowData: any) => rowData.emailsSent,
      },
      {
        Header: "CES Interactions",
        accessor: (rowData: any) => {
          if (
            rowData.name !== "Unassigned" &&
            rowData.name !== "Total" &&
            rowData.cesInteractions > 0 &&
            ((project !== null && users && users.length) || title === rowData.user)
          ) {
            return (
              <Link
                style={{ color: "#000", textDecoration: "none" }}
                to={`/dashboard/project/${rowData.id}/list/ces-interactions?user=${
                  title === rowData.user && title !== "Process" ? rowData.user : rowData.name
                }&start=${typeof dateStart === "number" ? dateStart : dateStart.getTime()}&end=${
                  typeof dateEnd === "number" ? dateEnd : dateEnd.getTime()
                }&row=15&page=1`}
              >
                <strong style={{ cursor: "pointer" }}>{rowData.cesInteractions}</strong>
              </Link>
            );
          } else return rowData.cesInteractions;
        },
      },
      {
        Header: "Handover Connections",
        accessor: (rowData: any) => {
          if (
            rowData.name !== "Unassigned" &&
            rowData.name !== "Total" &&
            rowData.handoverConnections > 0 &&
            ((project !== null && users && users.length) || title === rowData.user)
          ) {
            return (
              <Link
                style={{ color: "#000", textDecoration: "none" }}
                to={`/dashboard/project/${rowData.id}/list/handover-connections?user=${
                  title === rowData.user && title !== "Process" ? rowData.user : rowData.name
                }&start=${typeof dateStart === "number" ? dateStart : dateStart.getTime()}&end=${
                  typeof dateEnd === "number" ? dateEnd : dateEnd.getTime()
                }&row=15&page=1`}
              >
                <strong style={{ cursor: "pointer" }}>{rowData.handoverConnections}</strong>
              </Link>
            );
          } else return rowData.handoverConnections;
        },
      },
      {
        Header: "Notes Created",
        accessor: (rowData: any) => rowData.notes,
      },
    ];
  }, []);

  const download = (type: string, data: any) => {
    let headers: any[] = [];
    for (const [key] of Object.entries(projectTotals[0])) {
      if (key !== "id") {
        headers.push({
          label: camelToNormal(key),
          id: key,
        });
      }
    }

    let columnWidths = projectTotals.map(() => {
      if (type === "excel") {
        return {
          name: 15,
          processesAssigned: 15,
          stepsCompleted: 15,
          stepsOverDue: 15,
          appointmentsCreated: 15,
          appointmentsBooked: 15,
          appointmentsShowedUp: 15,
          appointmentsSales: 15,
          cesInteractions: 15,
          emailsSent: 15,
          handoverConnections: 15,
          notes: 15,
        };
      } else
        return {
          name: 200,
          processesAssigned: 200,
          stepsCompleted: 200,
          stepsOverDue: 200,
          appointmentsCreated: 200,
          appointmentsBooked: 200,
          appointmentsShowedUp: 200,
          appointmentsSales: 200,
          cesInteractions: 200,
          emailsSent: 200,
          handoverConnections: 200,
          notes: 200,
        };
    });

    let sheetTitle = [`${convertAllDates(`${new Date(dateStart)}`, "PP")} CRM Activity`];

    if (type === "excel") {
      downloadExcel([projectTotals], [headers], [], [columnWidths], sheetTitle, sheetTitle[0]);
    } else {
      downloadPdf([projectTotals], [headers], [], columnWidths, sheetTitle, sheetTitle[0]);
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xl={12} sm={12} sx={{ width: "100%" }}>
          <StandardTable title={title} loading={loading} download={download} user={user} columns={projectColumns} data={projectTotals} />
        </Grid>
      </Grid>
    </Box>
  );
};

interface ChildProps {
  user: IUser | null;
  title: string;
  projectTotals: IProjectTotal[];
  dateStart: Date;
  dateEnd: Date;
  loading: boolean;
  project?: IProject | null;
  users?: IUser[];
}

export default ReportProjectTables;
