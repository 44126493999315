import { gql, useMutation } from "@apollo/client";
import * as xlsx from "xlsx";
import { useState, useEffect, useMemo } from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { Typography, Box, FormControl, InputLabel, MenuItem, Select, TextField, Button } from "@mui/material";
import { FlexBetween } from "../../commonStyles";
import { showErrorSnackbar, showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";
import { useAppDispatch } from "../../app/hooks";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { handleModal } from "../../features/modal/modalSlice";
import { convertAllDates, numToCurrency } from "../../utils/function";
import { useNavigate } from "react-router";
import { useTextTemplatesQuery } from "../../features/textTemplate/textTemplateHooks";
import { selectTextTemplates } from "../../features/textTemplate/textTemplateSlice";
import { ITextTemplate } from "../../types/textTemplate";

const MassText = (props: ChildProps) => {
  const { id, selectedRegistrants, project } = props;
  const storeDispatch = useAppDispatch();
  const navigate = useNavigate();
  const auth = useSelector(selectUser);
  const textTemplates = useSelector(selectTextTemplates);
  const [name, setName] = useState<string>("");
  const [textTemplate, setTextTemplate] = useState<ITextTemplate | null>(null);
  const [textDate, setTextDate] = useState<Date | null>(null);
  const [customRegistrants, setCustomRegistrants] = useState<ICustomRegistrant[]>([]);

  const [getTexts] = useTextTemplatesQuery(1, 100, "_ID_DESC");

  const [saveTextSchedule] = useMutation(SAVETEXTSCHEDULE, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar("Scheduled Text Saved!"));
      storeDispatch(handleModal(false));
      navigate(`/dashboard`);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Access Forbidden"));
    },
  });

  useEffect(() => {
    getTexts();
    // eslint-disable-next-line
  }, []);

  const readUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const data = event.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        let customData = [...json].map((data: any) => {
          return {
            firstName: data.firstName ? data.firstName : "",
            phone: data.phone,
          };
        });
        setCustomRegistrants(customData);
      };
      reader.readAsArrayBuffer(event.target.files[0]);
    }
  };

  const handleTextTemplate = (value: string) => {
    let selectedTextTemplate = textTemplates.find((textTemplate: ITextTemplate) => textTemplate._id === value);
    if (selectedTextTemplate) {
      setTextTemplate(selectedTextTemplate);
    }
  };

  const handleSmsSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!textDate) return storeDispatch(showErrorSnackbar("Date for Mass Mail is required"));
    if (!textTemplate) return storeDispatch(showErrorSnackbar("Template is required"));
    if (id === "custom" && !customRegistrants.length) return storeDispatch(showErrorSnackbar("No Excel File Found"));

    if (id === "custom") {
      if (!customRegistrants.every((custom: any) => custom.phone))
        return storeDispatch(showErrorSnackbar("There are missing phones in the uploaded excel file or the columns are incorrect"));
    }

    let allRegistrants = selectedRegistrants;

    let template = textTemplates.find((template: ITextTemplate) => template._id === textTemplate._id);

    if (template) {
      saveTextSchedule({
        variables: {
          record: {
            project: project ? project : template?.project._id,
            name: name,
            textTemplate: textTemplate._id,
            user: auth?._id,
            textDate,
            count: id === "custom" ? customRegistrants.length : selectedRegistrants.length,
            type: id === "custom" ? "custom" : id === "purchasers" ? "purchaser" : "registrant",
            registrants: id === "custom" ? [] : id === "purchasers" ? [] : allRegistrants,
            purchasers: id === "purchasers" ? allRegistrants : [],
            custom: id === "custom" ? customRegistrants : [],
          },
        },
      });
    }
  };

  return (
    <div>
      <Typography variant="h2" component="div" gutterBottom>
        <strong>Send Mass Text</strong>
      </Typography>
      <Typography sx={{ mt: 2 }} variant="h3" component="div" gutterBottom>
        <strong>
          Selected: <Box sx={{ display: "inline", color: "success.main" }}>
            {selectedRegistrants && selectedRegistrants.length ? selectedRegistrants.length : customRegistrants.length}
          </Box>
        </strong>
      </Typography>
      {id === "custom" ? (
        <Box sx={{ mb: 2 }}>
          <Box sx={{ my: 1 }}>You can upload a custom excel. It will require two columns (firstName and phone). phone is mandatory.</Box>
          <input style={{ display: "none" }} id="upload-excel" name="upload-excel" onChange={(e) => readUploadFile(e)} type="file" />
          <label htmlFor="upload-excel">
            <Button color="primary" variant="contained" component="span">
              Upload Excel
            </Button>
          </label>
        </Box>
      ) : null}
      <Box>
        <strong>
          Approximate Cost:{" "}
          <Box sx={{ display: "inline", color: "error.main" }}>
            {textTemplate
              ? numToCurrency.format(Math.ceil(textTemplate?.text.length / 150) * 0.01348 * selectedRegistrants.length)
              : numToCurrency.format(selectedRegistrants.length * 0.01348)}
            USD
          </Box>
        </strong>
      </Box>
      <Box>
        <TextField
          title={"Name"}
          name={"name"}
          fullWidth
          value={name}
          label={"Name"}
          required
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
        />
      </Box>
      <form onSubmit={handleSmsSubmit}>
        <Box sx={{ mt: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="SMS Date"
              value={textDate}
              minDate={new Date()}
              disablePast
              onChange={(newValue) => {
                setTextDate(newValue);
              }}
              sx={{ width: "100%" }}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ mt: 2 }}>
          <FormControl fullWidth>
            <InputLabel>SMS Template</InputLabel>
            <Select
              required
              name={"SmsTemplate"}
              label={"SMS Template"}
              value={textTemplate ? textTemplate._id : ""}
              onChange={(e) => handleTextTemplate(e.target.value)}
            >
              {textTemplates.map((template, i) => (
                <MenuItem key={i} value={template._id}>
                  {template.name} ({convertAllDates(template.createdAt, "PP")})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <FlexBetween sx={{ mt: 2 }}>
          <Button type="submit" color="success" variant="contained">
            Schedule
          </Button>
          <Button onClick={() => storeDispatch(handleModal(false))} color="info" variant="contained">
            Cancel
          </Button>
        </FlexBetween>
      </form>
    </div>
  );
};

interface ChildProps {
  id: string;
  selectedRegistrants: string[];
  project?: string | null;
}

interface ICustomRegistrant {
  firstName?: string;
  phone: string;
}

const SAVETEXTSCHEDULE = gql`
  mutation textScheduleCreateOne($record: CreateOneTextScheduleInput!) {
    textScheduleCreateOne(record: $record) {
      record {
        _id
      }
    }
  }
`;

export default MassText;
