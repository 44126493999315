import { SetStateAction, Dispatch, useState, useEffect } from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { addSeconds, addHours } from "date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Box, Grid, TextField, Select, MenuItem, FormControl, InputLabel, Typography, Checkbox, FormControlLabel } from "@mui/material";
import { useAppDispatch } from "../../app/hooks";
import { showErrorSnackbar, showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";
import { IAppointment } from "../../types/project";
import { ISchedule, IScheduleSetting } from "../../types/schedule";
import CustomDialog from "../common/CustomDialog";
import { formatPhoneNumber, convertAllDates, isDST } from "../../utils/function";
import { IProjectAccess } from "../../types/user";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { IProject } from "../../types/project";

const AppointmentsDialog = (props: ChildProps) => {
  const {
    schedule,
    setSchedule,
    appointmentsDispatch,
    selectedAppointment,
    setSelectedAppointment,
    setDialogType,
    setDialogOpen,
    dialogType,
    dialogOpen,
    schedules,
  } = props;
  const storeDispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const [timeSlots, setTimeSlots] = useState<Date[]>([]);
  const [timeSlot, setTimeSlot] = useState<Date | null>(null);
  const [project, setProject] = useState<IProject | null>(null);
  const [tags, setTags] = useState<string[]>([]);
  
  const [createAppointment, { loading: createLoading }] = useMutation(CREATEAPPOINTMENT, {
    onCompleted: (data) => {
      setSchedule(null);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Error creating appointment"));
      console.log(err, "err");
    },
  });

  const [editCalendar] = useMutation(EDITCALENDAR, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [getTags] = useLazyQuery(GETTAGS, {
    onCompleted: (data) => {
      setTags(data.crmProjectSettingOne.tags);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Error updating Appointment"));
      console.log(err, "err");
    },
  });

  const [appointmentUpdateById] = useMutation(APPOINTMENTUPDATEBYID, {
    onCompleted: (data) => {},
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Error updating Appointment"));
      console.log(err, "err");
    },
  });

  const [registrantUpdateById] = useMutation(UPDATEREGISTRANT, {
    onCompleted: (data) => {},
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Error updating Appointment"));
      console.log(err, "err");
    },
  });

  const [updateBoolean] = useMutation<IUpdateBoolean>(UPDATEBOOLEAN, {
    onCompleted: (data) => {},
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Error updating appointments"));
    },
  });

  const [deleteAppointment] = useMutation(DELETEAPPOINTMENT, {
    onCompleted: (data) => {},
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Error updating appointments"));
    },
  });

  useEffect(() => {
    if (selectedAppointment) {
      getTags({ variables: { filter: { project: selectedAppointment.project._id } } });
    }
  }, [selectedAppointment, getTags]);

  const closeDialog = () => {
    setSelectedAppointment(null);
    setDialogType("");
    setDialogOpen(false);
  };

  const handleProject = (e: any) => {
    let selectedProject = user?.projectAccess.find((projectAccess: IProjectAccess) => projectAccess.project._id === e);
    if (selectedProject) {
      setSchedule(null);
      setProject(selectedProject.project);
      setSelectedAppointment({
        ...selectedAppointment,
        project: selectedProject.project._id,
        location: selectedProject.project.salesOffice,
        type: "regular",
      });
    }
  };

  const handleSchedule = (value: string) => {
    let selectedSchedule = schedules.find((schedule: ISchedule) => schedule._id === value);
    if (selectedSchedule) {
      let timeslots: any[] = selectedSchedule.schedules.map((schedule: IScheduleSetting) => {
        let allSlots = [];
        for (let i = schedule.timeStart; i <= schedule.timeEnd - schedule.length * 60; i += schedule.timeBetweenAppointments * 60) {
          let scheduleDate = new Date(new Date(schedule.date!).setHours(0, 0, 0, 0));
          allSlots.push(new Date(addSeconds(scheduleDate, i)));
        }
        return allSlots;
      });
      setTimeSlots(timeslots.flat());
      setSchedule(selectedSchedule);
    }
  };

  const handleDetailTextChange = (field: any, e: any) => {
    if (field === "firstName" || field === "lastName" || field === "email" || field === "primaryPhone" || field === "numberOfGuests") {
      let value = e.target.value;
      if (field === "primaryPhone") {
        value = formatPhoneNumber(e.target.value);
      }
      if (field === "numberOfGuests") {
        value = parseInt(e.target.value, 10);
      }
      let purchaserInfo = {
        ...selectedAppointment?.purchaserInfo,
        [field]: value,
      };
      setSelectedAppointment({
        ...selectedAppointment,
        purchaserInfo: purchaserInfo,
      });
    } else {
      setSelectedAppointment({
        ...selectedAppointment,
        [field]: e.target.value,
      });
    }
  };

  const handleDetailDateChange = (date: any) => {
    setSelectedAppointment({
      ...selectedAppointment,
      date: date,
    });
  };

  const handleTag = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedAppointment({
        ...selectedAppointment,
        registrant: {
          _id: selectedAppointment.registrant._id,
          tags: [...selectedAppointment.registrant.tags, event.target.value],
        },
      });
    } else {
      setSelectedAppointment({
        ...selectedAppointment,
        registrant: {
          _id: selectedAppointment.registrant._id,
          tags: selectedAppointment.registrant.tags.filter((tag: string) => tag !== event.target.value),
        },
      });
    }
  };

  const handleDialogView = () => {
    let successText = "Confirm Appointment";
    let titleText = "";
    let removeText = "Cancel";
    let secondOptionText = "";
    let handleCloseSuccess = () => {};
    let handleCloseSecond = () => {};

    if (dialogType === "confirmed") {
      if (selectedAppointment?.confirmed) {
        successText = "Unconfirm Appointment";
        titleText = successText;
        handleCloseSuccess = () => {
          appointmentUpdateById({ variables: { _id: selectedAppointment?._id, record: { confirmed: false, cancelled: false } } }).then(
            (res) => {
              if (res.data) {
                storeDispatch(showSuccessSnackbar("Appointment Unconfirmed"));
                appointmentsDispatch({
                  type: "UPDATE",
                  payload: { _id: selectedAppointment?._id, field: "confirmed", value: false },
                });
                appointmentsDispatch({
                  type: "UPDATE",
                  payload: { _id: selectedAppointment?._id, field: "cancelled", value: false },
                });
              }
              setDialogOpen(false);
              setDialogType("");
              setSelectedAppointment(null);
            }
          );
        };
      } else {
        successText = "Confirm Appointment";
        secondOptionText = "Confirm Appointment and Send Email";
        titleText = successText;
        handleCloseSuccess = () => {
          appointmentUpdateById({ variables: { _id: selectedAppointment?._id, record: { confirmed: true, cancelled: false } } }).then(
            (res) => {
              if (res.data) {
                storeDispatch(showSuccessSnackbar("Appointment Confirmed"));
                appointmentsDispatch({
                  type: "UPDATE",
                  payload: { _id: selectedAppointment?._id, field: "cancelled", value: false },
                });
                appointmentsDispatch({
                  type: "UPDATE",
                  payload: { _id: selectedAppointment?._id, field: "confirmed", value: true },
                });
              }
              setDialogOpen(false);
              setDialogType("");
              setSelectedAppointment(null);
            }
          );
        };
        handleCloseSecond = () => {
          updateBoolean({ variables: { id: selectedAppointment?._id, field: "confirmed", value: true, sendMail: true } }).then((res) => {
            if (res.data) {
              storeDispatch(showSuccessSnackbar("Appointment Confirmed and Email Sent"));
              appointmentsDispatch({
                type: "UPDATE",
                payload: { _id: selectedAppointment?._id, field: "cancelled", value: false },
              });
              appointmentsDispatch({
                type: "UPDATE",
                payload: { _id: selectedAppointment?._id, field: "confirmed", value: true },
              });
            }
            setDialogOpen(false);
            setDialogType("");
            setSelectedAppointment(null);
          });
        };
      }
    } else if (dialogType === "cancelled") {
      if (selectedAppointment?.cancelled) {
        successText = "Uncancel Appointment";
        titleText = successText;
        handleCloseSuccess = () => {
          appointmentUpdateById({ variables: { _id: selectedAppointment?._id, record: { confirmed: false, cancelled: false } } }).then(
            (res) => {
              if (res.data) {
                storeDispatch(showSuccessSnackbar("Appointment Uncancelled"));
                appointmentsDispatch({
                  type: "UPDATE",
                  payload: { _id: selectedAppointment?._id, field: "confirmed", value: false },
                });
                appointmentsDispatch({
                  type: "UPDATE",
                  payload: { _id: selectedAppointment?._id, field: "cancelled", value: false },
                });
              }
              setDialogOpen(false);
              setDialogType("");
              setSelectedAppointment(null);
            }
          );
        };
      } else {
        successText = "Cancel Appointment and Send Email";
        secondOptionText = "Cancel Appointment";
        titleText = "Cancel Appointment";
        handleCloseSuccess = () => {
          updateBoolean({ variables: { id: selectedAppointment?._id, field: "cancelled", value: true, sendMail: true } }).then((res) => {
            if (res.data) {
              storeDispatch(showSuccessSnackbar("Appointment Cancelled and Email Sent"));
              appointmentsDispatch({
                type: "UPDATE",
                payload: { _id: selectedAppointment?._id, field: "cancelled", value: true },
              });
              appointmentsDispatch({
                type: "UPDATE",
                payload: { _id: selectedAppointment?._id, field: "confirmed", value: false },
              });
            }
            setDialogOpen(false);
            setDialogType("");
            setSelectedAppointment(null);
          });
        };
        handleCloseSecond = () => {
          updateBoolean({ variables: { id: selectedAppointment?._id, field: "cancelled", value: true, sendMail: false } }).then((res) => {
            if (res.data) {
              storeDispatch(showSuccessSnackbar("Appointment Cancelled"));
              appointmentsDispatch({
                type: "UPDATE",
                payload: { _id: selectedAppointment?._id, field: "cancelled", value: true },
              });
              appointmentsDispatch({
                type: "UPDATE",
                payload: { _id: selectedAppointment?._id, field: "confirmed", value: false },
              });
            }
            setDialogOpen(false);
            setDialogType("");
            setSelectedAppointment(null);
          });
        };
      }
    } else if (dialogType === "noResponse") {
      if (selectedAppointment?.noResponse) {
        successText = "Uncheck No Response";
        handleCloseSuccess = () => {
          appointmentUpdateById({ variables: { _id: selectedAppointment?._id, record: { noResponse: false } } }).then((res) => {
            if (res.data) {
              appointmentsDispatch({
                type: "UPDATE",
                payload: { _id: selectedAppointment?._id, field: "noResponse", value: false },
              });
            }
            setDialogOpen(false);
            setDialogType("");
            setSelectedAppointment(null);
          });
        };
      } else {
        successText = "Check No Response and send email";
        secondOptionText = "Check No Response";
        titleText = successText;
        handleCloseSuccess = () => {
          updateBoolean({ variables: { id: selectedAppointment?._id, field: "noResponse", value: true, sendMail: true } }).then((res) => {
            if (res.data) {
              storeDispatch(showSuccessSnackbar("Sent No Response Email"));
              appointmentsDispatch({
                type: "UPDATE",
                payload: { _id: selectedAppointment?._id, field: "noResponse", value: true },
              });
            }
            setDialogOpen(false);
            setDialogType("");
            setSelectedAppointment(null);
          });
        };
        handleCloseSecond = () => {
          updateBoolean({ variables: { id: selectedAppointment?._id, field: "noResponse", value: true, sendMail: false } }).then((res) => {
            if (res.data) {
              storeDispatch(showSuccessSnackbar("Updated No Response"));
              appointmentsDispatch({
                type: "UPDATE",
                payload: { _id: selectedAppointment?._id, field: "noResponse", value: true },
              });
            }
            setDialogOpen(false);
            setDialogType("");
            setSelectedAppointment(null);
          });
        };
      }
    } else if (dialogType === "create") {
      successText = "Create Appointment";
      titleText = successText;
      handleCloseSuccess = () => {
        if (!selectedAppointment.project) return storeDispatch(showErrorSnackbar("No Project Selected"));
        if (!selectedAppointment.date) return storeDispatch(showErrorSnackbar("No Date Selected"));
        if (!selectedAppointment.purchaserInfo.firstName) return storeDispatch(showErrorSnackbar("Missing First Name"));
        if (!selectedAppointment.purchaserInfo.lastName) return storeDispatch(showErrorSnackbar("Missing Last Name"));
        if (!selectedAppointment.purchaserInfo.email) return storeDispatch(showErrorSnackbar("Missing Email"));
        if (!selectedAppointment.purchaserInfo.primaryPhone) return storeDispatch(showErrorSnackbar("Missing Phone Number"));
        createAppointment({
          variables: {
            record: {
              ...selectedAppointment,
              schedule: schedule ? schedule._id : null,
              user: user?._id,
            },
          },
        }).then((res) => {
          if (res.data) {
            storeDispatch(showSuccessSnackbar("Appointment Created"));
            appointmentsDispatch({
              type: "ADD",
              payload: { value: res.data.appointmentCreateOne.appointment },
            });
            if (res.data.appointmentCreateOne.appointment.salesRep && !res.data.appointmentCreateOne.appointment.schedule) {
              let date = res.data.appointmentCreateOne.appointment.date;
              let endDate = addHours(new Date(res.data.appointmentCreateOne.appointment.date), 1);
              editCalendar({
                variables: {
                  calendarId: res.data.appointmentCreateOne.appointment.salesRep.email,
                  eventId: null,
                  summary: `${project ? project.name : ""} Appointment`,
                  start: date,
                  end: endDate,
                  location: res.data.appointmentCreateOne.appointment.location,
                  attendees: [
                    {
                      email: res.data.appointmentCreateOne.appointment.purchaserInfo.email,
                      organizer: null,
                      responseStatus: "needsAction",
                      self: false,
                    },
                    {
                      email: res.data.appointmentCreateOne.appointment.salesRep.email,
                      organizer: null,
                      responseStatus: "needsAction",
                      self: res.data.appointmentCreateOne.appointment.salesRep.email === user?.email ? true : false,
                    },
                  ],
                  googleMeetsLink: false,
                  type: "edit",
                },
              });
            }
          }
          setDialogOpen(false);
          setDialogType("");
          setSelectedAppointment(null);
        });
      };
    } else if (dialogType === "details") {
      successText = "Update Appointment Details";
      secondOptionText = selectedAppointment?.cancelled ? "Delete Appointment" : "";
      titleText = successText;
      handleCloseSecond = () => {
        deleteAppointment({ variables: { _id: selectedAppointment?._id } }).then((res) => {
          if (res.data) {
            storeDispatch(showSuccessSnackbar("Appointment Deleted!"));
            appointmentsDispatch({ type: "DELETE", payload: { _id: res.data.appointmentRemoveById.record._id } });
          }
          setDialogOpen(false);
          setDialogType("");
          setSelectedAppointment(null);
        });
      };

      handleCloseSuccess = () => {
        if (!selectedAppointment.project) return storeDispatch(showErrorSnackbar("No Project Selected"));
        if (!selectedAppointment.date) return storeDispatch(showErrorSnackbar("No Date Selected"));
        if (!selectedAppointment.purchaserInfo.firstName) return storeDispatch(showErrorSnackbar("Missing First Name"));
        if (!selectedAppointment.purchaserInfo.lastName) return storeDispatch(showErrorSnackbar("Missing Last Name"));
        if (!selectedAppointment.purchaserInfo.email) return storeDispatch(showErrorSnackbar("Missing Email"));
        if (!selectedAppointment.purchaserInfo.primaryPhone) return storeDispatch(showErrorSnackbar("Missing Phone Number"));
        if (isNaN(selectedAppointment.purchaserInfo.numberOfGuests)) return storeDispatch(showErrorSnackbar("Missing Number of Guests"));
        if (selectedAppointment.registrant) {
          registrantUpdateById({
            variables: {
              _id: selectedAppointment?.registrant._id,
              record: {
                tags: selectedAppointment.registrant.tags,
              },
            },
          });
        }
        appointmentUpdateById({
          variables: {
            _id: selectedAppointment?._id,
            record: {
              notes: selectedAppointment?.notes,
              salesNotes: selectedAppointment?.salesNotes,
              date: selectedAppointment?.date,
              purchaserInfo: selectedAppointment?.purchaserInfo,
              location: selectedAppointment?.location,
            },
          },
        }).then((res) => {
          if (res.data) {
            storeDispatch(showSuccessSnackbar("Appointment Details Updated"));
            appointmentsDispatch({
              type: "UPDATE",
              payload: { _id: selectedAppointment?._id, field: "notes", value: selectedAppointment?.notes },
            });
            appointmentsDispatch({
              type: "UPDATE",
              payload: { _id: selectedAppointment?._id, field: "salesNotes", value: selectedAppointment?.salesNotes },
            });
            appointmentsDispatch({
              type: "UPDATE",
              payload: { _id: selectedAppointment?._id, field: "date", value: selectedAppointment?.date },
            });
            appointmentsDispatch({
              type: "UPDATE",
              payload: { _id: selectedAppointment?._id, field: "location", value: selectedAppointment?.location },
            });
            appointmentsDispatch({
              type: "UPDATE",
              payload: { _id: selectedAppointment?._id, field: "purchaserInfo", value: selectedAppointment?.purchaserInfo },
            });
            appointmentsDispatch({
              type: "UPDATE",
              payload: { _id: selectedAppointment?._id, field: "registrant", value: selectedAppointment.registrant },
            });
          }
          setDialogOpen(false);
          setDialogType("");
          setSelectedAppointment(null);
        });
      };
    }

    return (
      <CustomDialog
        open={dialogOpen}
        handleClose={closeDialog}
        handleCloseRemove={closeDialog}
        handleCloseSuccess={handleCloseSuccess}
        handleCloseSecond={handleCloseSecond}
        successButton={successText}
        removeButton={removeText}
        secondOption={secondOptionText}
        dialogTitle={titleText}
        loading={createLoading}
        loadingText={"Creating Appointment..."}
        dialogContent={dialogContent()}
        maxWidth={"xl"}
      />
    );
  };

  const dialogContent = () => {
    if (dialogType === "confirmed") {
      return (
        <div>
          <Box sx={{ mb: 1 }}>Are you sure you would like to confirm the following appointment?</Box>
          <div>
            <div>
              <strong>
                {selectedAppointment?.purchaserInfo.firstName} {selectedAppointment?.purchaserInfo.lastName}
              </strong>
            </div>
            <div>
              <strong>{selectedAppointment?.purchaserInfo.primaryPhone}</strong>
            </div>
            <div>
              <strong>{selectedAppointment?.purchaserInfo.email}</strong>
            </div>
            <div>
              <strong>{selectedAppointment?.purchaserInfo.numberOfGuests} Guest(s)</strong>
            </div>
            <div>
              <strong>{convertAllDates(selectedAppointment?.date, "PPpp")}</strong>
            </div>
          </div>
        </div>
      );
    } else if (dialogType === "cancelled") {
      return (
        <div>
          <Box sx={{ mb: 1 }}>Are you sure you would like to cancel the following appointment?</Box>
          <div>
            <div>
              <strong>
                {selectedAppointment?.purchaserInfo.firstName} {selectedAppointment?.purchaserInfo.lastName}
              </strong>
            </div>
            <div>
              <strong>{selectedAppointment?.purchaserInfo.primaryPhone}</strong>
            </div>
            <div>
              <strong>{selectedAppointment?.purchaserInfo.email}</strong>
            </div>
            <div>
              <strong>{selectedAppointment?.purchaserInfo.numberOfGuests} Guest(s)</strong>
            </div>
            <div>
              <strong>{convertAllDates(selectedAppointment?.date, "PPpp")}</strong>
            </div>
          </div>
        </div>
      );
    } else if (dialogType === "noResponse") {
      return (
        <div>
          <div>
            <div>
              <strong>
                {selectedAppointment?.purchaserInfo.firstName} {selectedAppointment?.purchaserInfo.lastName}
              </strong>
            </div>
            <div>
              <strong>{selectedAppointment?.purchaserInfo.primaryPhone}</strong>
            </div>
            <div>
              <strong>{selectedAppointment?.purchaserInfo.email}</strong>
            </div>
            <div>
              <strong>{selectedAppointment?.purchaserInfo.numberOfGuests} Guest(s)</strong>
            </div>
            <div>
              <strong>{convertAllDates(selectedAppointment?.date, "PPpp")}</strong>
            </div>
          </div>
        </div>
      );
    } else if (dialogType === "details") {
      return (
        <div>
          <Box sx={{ mt: 2 }}>
            <strong>Appointment Book By: {selectedAppointment.user ? selectedAppointment.user?.fullName : "Public"}</strong>
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              title={"Location/Address"}
              name={"location"}
              fullWidth
              value={selectedAppointment?.location}
              label={"Location/Address"}
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDetailTextChange("location", e)}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              title={"First Name"}
              name={"firstName"}
              fullWidth
              value={selectedAppointment?.purchaserInfo.firstName}
              label={"First Name"}
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDetailTextChange("firstName", e)}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              title={"Last Name"}
              name={"lastName"}
              fullWidth
              value={selectedAppointment?.purchaserInfo.lastName}
              label={"Last Name"}
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDetailTextChange("lastName", e)}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              title={"Email"}
              name={"email"}
              fullWidth
              value={selectedAppointment?.purchaserInfo.email}
              label={"Email"}
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDetailTextChange("email", e)}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              title={"Primary Phone"}
              name={"primaryPhone"}
              fullWidth
              value={selectedAppointment?.purchaserInfo.primaryPhone}
              label={"Primary Phone"}
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDetailTextChange("primaryPhone", e)}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              title={"Number of Additional Guests"}
              name={"numberOfGuests"}
              fullWidth
              type="number"
              value={selectedAppointment?.purchaserInfo.numberOfGuests}
              label={"Number of Additional Guests"}
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDetailTextChange("numberOfGuests", e)}
            />
          </Box>
          {selectedAppointment?.questions.length ? (
            <Box>
              {selectedAppointment?.questions.map((question: any, index: number) => (
                <p key={index}>
                  {question.questionId.question}: {question.answer}
                </p>
              ))}
            </Box>
          ) : null}
          <Box sx={{ mt: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label={"Appointment Date"}
                value={new Date(selectedAppointment?.date)}
                onChange={handleDetailDateChange}
                sx={{ width: '100%'}}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              title={"Sales Notes"}
              name={"salesNotes"}
              fullWidth
              multiline
              rows={3}
              value={selectedAppointment?.salesNotes}
              label={"Sales Notes"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDetailTextChange("salesNotes", e)}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              title={"Notes"}
              name={"notes"}
              fullWidth
              multiline
              rows={3}
              value={selectedAppointment?.notes}
              label={"Notes"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDetailTextChange("notes", e)}
            />
          </Box>
          {selectedAppointment.registrant && tags.length ? (
            <>
              <Typography sx={{ mt: 1 }} variant="h5">
                <strong>Tags</strong>
              </Typography>
              <Grid container spacing={2} sx={{ mb: 1 }}>
                {tags.map((tag: string, index: number) => {
                  return (
                    <Grid item sm={6}>
                      <FormControlLabel
                        onClick={(e) => e.stopPropagation()}
                        key={index}
                        id={tag}
                        control={
                          <Checkbox
                            value={tag}
                            name={tag}
                            checked={selectedAppointment.registrant.tags.includes(tag)}
                            onChange={handleTag}
                          />
                        }
                        label={tag}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </>
          ) : null}
        </div>
      );
    } else if (dialogType === "create") {
      return (
        <form>
          <Box sx={{ mt: 2 }}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-project">Project</InputLabel>
              <Select
                label="Project"
                sx={{ width: "100%" }}
                labelId="demo-simple-select-project"
                id="demo-simple-project"
                value={project ? project._id : ""}
                onChange={(e: any) => {
                  handleProject(e.target.value);
                }}
                required
              >
                {user?.projectAccess.map((projectAccess: IProjectAccess, index: number) => {
                  return (
                    <MenuItem key={index} value={projectAccess.project._id}>
                      {projectAccess.project.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          {schedules.length ? (
            <Box sx={{ mt: 2 }}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-label">Schedules</InputLabel>
                <Select
                  label="Schedule"
                  sx={{ width: "100%" }}
                  labelId="demo-simple-select-label"
                  required
                  id="demo-simple-select"
                  value={schedule ? schedule._id : ""}
                  onChange={(e: any) => {
                    handleSchedule(e.target.value);
                  }}
                >
                  {project
                    ? schedules
                        .filter((schedule: ISchedule) => schedule.project._id === project._id)
                        .map((schedule: ISchedule, index: number) => {
                          return (
                            <MenuItem key={index} value={schedule._id}>
                              {schedule.name}
                            </MenuItem>
                          );
                        })
                    : schedules.map((schedule: ISchedule, index: number) => {
                        return (
                          <MenuItem key={index} value={schedule._id}>
                            {schedule.name}
                          </MenuItem>
                        );
                      })}
                </Select>
              </FormControl>
            </Box>
          ) : null}
          {schedule ? (
            <Box sx={{ mt: 2 }}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="timeslot-label">Time Slot</InputLabel>
                <Select
                  labelId="timeslot-label"
                  value={timeSlot}
                  label="Time Slot"
                  onChange={(e) => {
                    setTimeSlot(new Date(e.target.value!));
                    setSelectedAppointment({
                      ...selectedAppointment,
                      date: new Date(e.target.value!),
                    });
                  }}
                >
                  {timeSlots.map((timeSlot: Date, index: number) => {
                    return (
                      <MenuItem key={index} value={timeSlot.toString()}>
                        {" "}
                        {convertAllDates(timeSlot, "PPpp")}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          ) : null}
          <Box sx={{ mt: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label={"Appointment Date"}
                disablePast
                value={new Date(selectedAppointment?.date)}
                onChange={handleDetailDateChange}
                sx={{ width: '100%'}}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              title={"Location/Address"}
              name={"location"}
              fullWidth
              required
              value={selectedAppointment?.location}
              label={"Location/Address"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDetailTextChange("location", e)}
            />
          </Box>
          <Typography sx={{ mt: 2 }} variant="h3">
            <strong>Purchaser Info</strong>
          </Typography>
          <Grid container spacing={2}>
            <Grid item sx={{ mt: 2 }} xs={12} sm={6}>
              <TextField
                title={"First Name"}
                name={"firstName"}
                fullWidth
                required
                value={selectedAppointment?.purchaserInfo.firstName}
                label={"First Name"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDetailTextChange("firstName", e)}
              />
            </Grid>
            <Grid item sx={{ mt: 2 }} xs={12} sm={6}>
              <TextField
                title={"Last Name"}
                name={"lastName"}
                fullWidth
                required
                value={selectedAppointment?.purchaserInfo.lastName}
                label={"Last Name"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDetailTextChange("lastName", e)}
              />
            </Grid>
            <Grid item sx={{ mt: 2 }} xs={12} sm={6}>
              <TextField
                title={"Email"}
                name={"email"}
                fullWidth
                required
                value={selectedAppointment?.purchaserInfo.email}
                label={"Email"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDetailTextChange("email", e)}
              />
            </Grid>
            <Grid item sx={{ mt: 2 }} xs={12} sm={6}>
              <TextField
                title={"Primary Phone"}
                name={"primaryPhone"}
                required
                fullWidth
                value={selectedAppointment?.purchaserInfo.primaryPhone}
                label={"Primary Phone"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDetailTextChange("primaryPhone", e)}
              />
            </Grid>
            <Grid item sx={{ mt: 2 }} xs={12} sm={6}>
              <TextField
                title={"Sales Notes"}
                name={"salesNotes"}
                fullWidth
                multiline
                rows={3}
                value={selectedAppointment?.salesNotes}
                label={"Sales Notes"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDetailTextChange("salesNotes", e)}
              />
            </Grid>
            <Grid item sx={{ mt: 2 }} xs={12} sm={6}>
              <TextField
                title={"Notes"}
                name={"notes"}
                fullWidth
                multiline
                rows={3}
                value={selectedAppointment?.notes}
                label={"Notes"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDetailTextChange("notes", e)}
              />
            </Grid>
          </Grid>
        </form>
      );
    }
  };

  return <Box>{handleDialogView()}</Box>;
};

interface ChildProps {
  schedule: ISchedule | null;
  setSchedule: any;
  appointmentsDispatch: any;
  selectedAppointment: any;
  setSelectedAppointment: any;
  setDialogType: Dispatch<SetStateAction<string>>;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
  dialogType: string;
  dialogOpen: boolean;
  schedules: ISchedule[];
}

interface IUpdateBoolean {
  updateBoolean: IAppointment;
}

const UPDATEBOOLEAN = gql`
  mutation updateBoolean($id: MongoID!, $field: String!, $value: Boolean!, $sendMail: Boolean!) {
    updateBoolean(id: $id, field: $field, value: $value, sendMail: $sendMail) {
      _id
      user {
        realtor {
          _id
          firstName
          lastName
          directPhone
        }
      }
      date
      location
      purchaserInfo {
        firstName
        lastName
        email
        primaryPhone
        numberOfGuests
      }
      questions {
        questionId {
          name
          question
          type
          choices {
            choice
            followUp {
              _id
            }
          }
        }
        answer
      }
      status
      type
      notes
      confirmed
      cancelled
      salesNotes
      salesRep {
        _id
        fullName
      }
      createdAt
    }
  }
`;

const APPOINTMENTUPDATEBYID = gql`
  mutation appointmentUpdateById($_id: MongoID!, $record: UpdateByIdAppointmentInput!) {
    appointmentUpdateById(_id: $_id, record: $record) {
      recordId
      record {
        _id
        user {
          realtor {
            _id
            fullName
            directPhone
          }
        }
        date
        location
        purchaserInfo {
          firstName
          lastName
          email
          primaryPhone
          numberOfGuests
        }
        questions {
          questionId {
            name
            question
            type
            choices {
              choice
              followUp {
                _id
              }
            }
          }
          answer
        }
        status
        type
        notes
        confirmed
        cancelled
        salesNotes
        salesRep {
          _id
          fullName
        }
        createdAt
      }
    }
  }
`;

const CREATEAPPOINTMENT = gql`
  mutation appointmentCreateOne($record: CreateOneAppointmentInput) {
    appointmentCreateOne(record: $record) {
      appointment {
        _id
        schedule {
          _id
          schedules {
            date
            length
          }
        }
        user {
          realtor {
            _id
            firstName
            lastName
            fullName
            directPhone
          }
        }
        date
        location
        purchaserInfo {
          firstName
          lastName
          email
          primaryPhone
          numberOfGuests
        }
        questions {
          questionId {
            name
            question
            type
            choices {
              choice
              followUp {
                _id
              }
            }
          }
          answer
        }
        status
        type
        notes
        confirmed
        cancelled
        salesNotes
        salesRep {
          _id
          fullName
          email
        }
        createdAt
      }
    }
  }
`;

const DELETEAPPOINTMENT = gql`
  mutation appointmentRemoveById($_id: MongoID!) {
    appointmentRemoveById(_id: $_id) {
      record {
        _id
      }
    }
  }
`;

const GETTAGS = gql`
  query crmProjectSettingOne($filter: FilterFindOneCrmProjectSettingInput) {
    crmProjectSettingOne(filter: $filter) {
      tags
    }
  }
`;

const UPDATEREGISTRANT = gql`
  mutation registrantUpdateById($_id: MongoID!, $record: UpdateByIdRegistrantInput!) {
    registrantUpdateById(_id: $_id, record: $record) {
      record {
        tags
      }
    }
  }
`;

const EDITCALENDAR = gql`
  mutation editCalendar(
    $calendarId: String
    $eventId: String
    $summary: String!
    $location: String
    $start: Date!
    $end: Date!
    $attendees: [NewAttendeesInput]
    $googleMeetsLink: Boolean!
    $type: String!
  ) {
    editCalendar(
      calendarId: $calendarId
      eventId: $eventId
      summary: $summary
      location: $location
      start: $start
      end: $end
      attendees: $attendees
      googleMeetsLink: $googleMeetsLink
      type: $type
    ) {
      id
    }
  }
`;

export default AppointmentsDialog;
