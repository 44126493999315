import React, { useState, useReducer } from "react";
import { gql, useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Button,
  Grid,
  FormControl,
  FormControlLabel,
  Select,
  InputLabel,
  TextField,
  FormLabel,
  Radio,
  RadioGroup,
  MenuItem,
  FormGroup,
  Checkbox,
} from "@mui/material";

import { IProject } from "../../types/project";
import { useAppDispatch } from "../../app/hooks";
import { showSuccessSnackbar, showErrorSnackbar } from "../../features/snackbar/snackbarSlice";
import RegistrantInfo from "../common/RegistrantInfo";
import { addRegistrant } from "../../features/projectSetting/projectSettingSlice";
import { IQuestion } from "../../features/question/questionSlice";
import { IChoice } from "../../types/question";
import { handleModal } from "../../features/modal/modalSlice";
import { useSelector } from "react-redux";
import { selectProjectTags } from "../../features/projectSetting/projectSettingSlice";

const CreateRegistrant = (props: ChildProps) => {
  const { questions } = props;
  const storeDispatch = useAppDispatch();
  const { projectid } = useParams();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [workingWithRealtor, setWorkingWithRealtor] = useState("");
  const [realtorName, setRealtorName] = useState("");
  const [realtorEmail, setRealtorEmail] = useState("");
  const [brokerage, setBrokerage] = useState("");
  const [source, setSource] = useState("walk in");
  const [notes, setNotes] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const [leaseType, setLeaseType] = useState("");
  const [miscInfo, dispatch] = useReducer((state: IAnswer[], action: { payload: IAnswer; index: number }) => {
    const newState = [...state];
    newState[action.index] = action.payload;
    return newState;
  }, Array(questions.length).fill({ answer: "", questionId: null }));

  const projectTags = useSelector(selectProjectTags);

  const [createRegistrant] = useMutation(CREATEREGISTRANT, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setEmail("");
      setFirstName("");
      setLastName("");
      setPhoneNumber("");
      setWorkingWithRealtor("");
      setRealtorName("");
      setRealtorEmail("");
      setBrokerage("");
      storeDispatch(showSuccessSnackbar("Registrant Created!"));
      storeDispatch(handleModal(false));
      storeDispatch(addRegistrant(data.createRegistrant));
      navigate(`/dashboard/project/${projectid}/registrant/${data.createRegistrant._id}`);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Registrant has already been created!"));
      console.log(err, "err");
    },
  });

  const handleCreate = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let miscQuestions = miscInfo
      .filter((question: any) => question.questionId !== null)
      .map((misc: IAnswer) => {
        return {
          ...misc,
          answer: typeof misc.answer === "string" ? [misc.answer] : misc.answer,
        };
      });

    createRegistrant({
      variables: {
        notes: notes,
        record: {
          email: email.toLowerCase(),
          firstName: firstName,
          lastName: lastName,
          primaryPhone: phoneNumber,
          project: projectid,
          realtorName,
          realtorType: workingWithRealtor,
          realtorEmail,
          brokerage,
          source: source,
          tags,
          leaseType,
          questions: miscQuestions,
        },
      },
    });
  };

  const handleChange = (e: any, i: number, id: string) => {
    dispatch({ payload: { answer: e.target.value, questionId: id }, index: i });
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>, i: number, j: number) => {
    const newCheck: any = miscInfo[i];
    let checkBoxAnswers = [...miscInfo[i].answer];

    if (checkBoxAnswers.includes(e.target.value)) {
      checkBoxAnswers.splice(checkBoxAnswers.indexOf(e.target.value), 1);
    } else {
      checkBoxAnswers = [...checkBoxAnswers, e.target.value];
    }

    newCheck[j] = { answer: checkBoxAnswers, questionId: e.target.name };
    dispatch({ payload: newCheck[j], index: i });
  };

  const handleQuestions = (question: IQuestion, i: number) => {
    switch (question.type) {
      case "text":
        return (
          <TextField
            fullWidth
            name={question.name}
            id={question._id}
            label={question.question}
            variant="outlined"
            value={miscInfo[i].answer}
            onChange={(e) => handleChange(e, i, question._id)}
          />
        );
      case "dropdown":
        return (
          <FormControl fullWidth>
            <InputLabel>{`${question.question}`}</InputLabel>
            <Select
              name={question.name}
              label={`${question.question}`}
              value={miscInfo[i].answer}
              onChange={(e) => handleChange(e, i, question._id)}
            >
              {question.choices.map((choice, j) => (
                <MenuItem key={j} value={choice.choice}>
                  {choice.choice}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "radio":
        return (
          <FormControl fullWidth>
            <FormLabel>{`${question.question}`}</FormLabel>
            <RadioGroup row name={question.name} id={question._id} value={miscInfo[i]} onChange={(e) => handleChange(e, i, question._id)}>
              {question.choices.map((choice, j) => (
                <FormControlLabel key={j} value={choice.choice} control={<Radio />} label={choice.choice} />
              ))}
            </RadioGroup>
          </FormControl>
        );
      case "checkbox":
        return (
          <FormControl fullWidth>
            <FormLabel>{question.question}</FormLabel>
            <FormGroup row>
              {question.choices.map((choice, j) => (
                <FormControlLabel
                  key={j}
                  id={question._id}
                  control={
                    <Checkbox
                      value={choice.choice}
                      name={question._id}
                      checked={miscInfo[i].answer.includes(choice.choice)}
                      onChange={(e) => handleCheck(e, i, j)}
                    />
                  }
                  label={choice.choice}
                />
              ))}
            </FormGroup>
          </FormControl>
        );
      default:
        return <></>;
    }
  };

  const handleSubQuestions = (question: IQuestion, i: number) => {
    let subQuestion = question.choices.find((choice: IChoice) => choice.choice === miscInfo[i].answer && choice.followUp.length > 0);

    if (subQuestion) {
      return subQuestion.followUp.map((question, i) => {
        return (
          <Grid item xs={12} key={i}>
            {handleQuestions(question, i)}
          </Grid>
        );
      });
    }
  };

  return (
    <div>
      <Typography variant="h2" component="div" gutterBottom>
        <strong>Create a Registrant</strong>
      </Typography>
      <form onSubmit={handleCreate}>
        <RegistrantInfo
          email={email}
          setEmail={setEmail}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          workingWithRealtor={workingWithRealtor}
          setWorkingWithRealtor={setWorkingWithRealtor}
          realtorName={realtorName}
          setRealtorName={setRealtorName}
          realtorEmail={realtorEmail}
          setRealtorEmail={setRealtorEmail}
          brokerage={brokerage}
          setBrokerage={setBrokerage}
          source={source}
          setSource={setSource}
          submitted={false}
          notes={notes}
          setNotes={setNotes}
          project={projectid}
          leaseType={leaseType}
          setLeaseType={setLeaseType}
          tags={tags}
          setTags={setTags}
          projectTags={projectTags}
        />
        {questions.length && miscInfo.length ? (
          <>
            <Grid sx={{ mt: 1 }} container spacing={2}>
              {questions
                .filter((question: IQuestion) => {
                  if (question.display !== "authenticated") {
                    if (workingWithRealtor === "isRealtor") {
                      return question.registrantType === "realtor";
                    } else return question.registrantType === "purchaser";
                  } else return question.display !== "authenticated";
                })
                .map((question, i) => {
                  if (!question.subQuestion) {
                    return (
                      <React.Fragment key={i}>
                        <Grid item xs={12} key={i}>
                          {handleQuestions(question, i)}
                        </Grid>
                        {question.choices.find((choice: IChoice) => choice.choice === miscInfo[i].answer && choice.followUp.length > 0)
                          ? handleSubQuestions(question, i)
                          : null}
                      </React.Fragment>
                    );
                  } else return null;
                })}
            </Grid>
          </>
        ) : null}
        <Button sx={{ mt: 2 }} variant="contained" type="submit" color="success">
          Submit
        </Button>
      </form>
    </div>
  );
};

interface ChildProps {
  project: IProject | null;
  questions: IQuestion[];
}

interface IAnswer {
  answer: string | string[];
  questionId: string;
}

const CREATEREGISTRANT = gql`
  mutation createRegistrant($record: CreateOneRegistrantInput!, $notes: String) {
    createRegistrant(record: $record, notes: $notes) {
      _id
      firstName
      lastName
      email
      primaryPhone
      rating
      source
      realtorType
      createdAt
    }
  }
`;

export default CreateRegistrant;
