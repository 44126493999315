import { useState } from "react";
import { gql, useMutation, useSubscription, useLazyQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Typography, Box, IconButton, FormControlLabel, Switch, Divider, Button, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

import RegistrantInfo from "../common/RegistrantInfo";
import { useAppDispatch } from "../../app/hooks";
import { FlexBetween, FlexEnd, Container } from "../../commonStyles";
import { IAnswer, IConnection, IRegistrant, IRegistrantStatus, IRelationship } from "../../types/registrant";
import { camelToNormal, convertAllDates, capitalizeFirstLetter, properCase } from "../../utils/function";
import { showErrorSnackbar, showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";
import { setProcess, setRegistrant } from "../../features/registrants/registrantsSlice";
import { IUser } from "../../types/user";
import { selectUsers } from "../../features/user/userSlice";
import { selectProjectTags, selectSales, selectOnline } from "../../features/projectSetting/projectSettingSlice";
import { handleModal } from "../../features/modal/modalSlice";
import { GlobalModal } from "../../features/modal/Modal";
import { selectUser } from "../../features/auth/authSlice";

const PersonalInformation = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const navigate = useNavigate();
  const [edit, setEdit] = useState<boolean>(false);
  const { registrant } = props;
  const user = useSelector(selectUser);
  const users = useSelector(selectUsers);
  const projectTags = useSelector(selectProjectTags);
  const projectSales = useSelector(selectSales);
  const projectOnline = useSelector(selectOnline);

  const [email, setEmail] = useState(registrant.email);
  const [firstName, setFirstName] = useState(registrant.firstName);
  const [lastName, setLastName] = useState(registrant.lastName);
  const [phoneNumber, setPhoneNumber] = useState(registrant.primaryPhone);
  const [postalCode, setPostalCode] = useState(registrant.postalCode);
  const [rating, setRating] = useState(registrant.rating);
  const [ethnicity, setEthnicity] = useState(registrant.ethnicity);
  const [source, setSource] = useState(registrant.source);
  const [salesRep, setSalesRep] = useState<IUser | null>(registrant.salesRep);
  const [onlineSalesRep, setOnlineSalesRep] = useState<IUser | null>(registrant.onlineSalesRep);
  const [workingWithRealtor, setWorkingWithRealtor] = useState(registrant.realtorType);
  const [realtorName, setRealtorName] = useState(registrant.realtorName);
  const [realtorEmail, setRealtorEmail] = useState(registrant.realtorEmail);
  const [brokerage, setBrokerage] = useState(registrant.brokerage);
  const [emailUnsubscribed, setEmailUnsubscribed] = useState(registrant.emailUnsubscribed);
  const [textUnsubscribed, setTextUnsubscribed] = useState(registrant.textUnsubscribed);
  const [tags, setTags] = useState(registrant.tags);
  const [show, setShow] = useState(false);
  const [relationships, setRelationships] = useState(registrant.relationships);
  const [leaseType, setLeaseType] = useState<string>(registrant.leaseType ? registrant.leaseType : "");
  const [projectRegistrants, setProjectRegistrants] = useState<IRegistrant[]>([]);

  const [updateRegistrantProcess] = useMutation(UPDATEREGISTRANTPROCESS, {
    onCompleted: (data) => {
      if (data.updateRegistrantProcess.process) {
        storeDispatch(setProcess(data.updateRegistrantProcess.process));
      }
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [updateRegistrant] = useMutation(UPDATEREGISTRANT, {
    onCompleted: async (data) => {
      if (salesRep && data.registrantUpdateById.record.salesRep) {
        await updateRegistrantProcess({
          variables: {
            user: salesRep._id,
            registrant: registrant._id,
            type: "salesRep",
          },
        });
      } else if (onlineSalesRep && data.registrantUpdateById.record.onlineSalesRep) {
        await updateRegistrantProcess({
          variables: {
            user: onlineSalesRep._id,
            registrant: registrant._id,
            type: "onlineSalesRep",
          },
        });
      }
      storeDispatch(setRegistrant(data.registrantUpdateById.record));
      storeDispatch(showSuccessSnackbar("Registrant Updated!"));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [getRegistrants, { loading }] = useLazyQuery(GETREGISTRANTS, {
    onCompleted: (data) => {
      setProjectRegistrants(data.registrantMany);
    },
    onError: (e) => {
      console.log(e);
    },
  });

  useSubscription(UPDATEDREGISTRANT, {
    onSubscriptionData: (data: any) => {
      const newRegDataArr = data.subscriptionData.data.updatedRegistrants;
      for (let newRegData of newRegDataArr) {
        if (newRegData._id !== registrant._id) continue;
        if (newRegData.email !== null && email !== newRegData.email) setEmail(newRegData.email);
        if (newRegData.firstName !== null && firstName !== newRegData.firstName) setFirstName(newRegData.firstName);
        if (newRegData.lastName !== null && lastName !== newRegData.lastName) setLastName(newRegData.lastName);
        if (newRegData.primaryPhone !== null && phoneNumber !== newRegData.primaryPhone) setPhoneNumber(newRegData.primaryPhone);
        if (newRegData.rating !== null && rating !== newRegData.rating) setRating(newRegData.rating);
        if (newRegData.ethnicity !== null && ethnicity !== newRegData.ethnicity) setEthnicity(newRegData.ethnicity);
        if (newRegData.postalCode !== null && postalCode !== newRegData.postalCode) setPostalCode(newRegData.postalCode);
        if (newRegData.salesRep && newRegData.salesRep._id !== null && salesRep?._id !== newRegData.salesRep._id)
          setSalesRep(newRegData.salesRep);
        if (newRegData.onlineSalesRep && newRegData.onlineSalesRep._id !== null && onlineSalesRep?._id !== newRegData.onlineSalesRep._id)
          setOnlineSalesRep(newRegData.onlineSalesRep);
        if (newRegData.realtorType !== null && workingWithRealtor !== newRegData.realtorType) setWorkingWithRealtor(newRegData.realtorType);
        if (newRegData.realtorName !== null && realtorName !== newRegData.realtorName) setRealtorName(newRegData.realtorName);
        if (newRegData.realtorEmail !== null && realtorEmail !== newRegData.realtorEmail) setRealtorEmail(newRegData.realtorEmail);
        if (newRegData.brokerage !== null && brokerage !== newRegData.brokerage) setBrokerage(newRegData.brokerage);
        if (newRegData.emailUnsubscribed !== null && emailUnsubscribed !== newRegData.emailUnsubscribed)
          setEmailUnsubscribed(newRegData.emailUnsubscribed);
        if (newRegData.textUnsubscribed !== null && textUnsubscribed !== newRegData.textUnsubscribed)
          setTextUnsubscribed(newRegData.textUnsubscribed);
        if (newRegData.connected !== null && relationships !== newRegData.relationships) setRelationships(newRegData.relationships);
      }
    },
  });

  const handleUpdate = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let filteredRelationship = relationships.map((relationship: IRelationship) => {
      return { ...relationship, registrant: relationship.registrant };
    });

    for (const relationship of filteredRelationship) {
      let duplicateRelationships = relationship.registrant?.relationships.map((relationshipRegistrant) => {
        if (relationshipRegistrant.registrant?._id === registrant._id) {
          return {
            registrant: registrant._id,
            type: relationship.type,
          };
        } else {
          return {
            registrant: relationshipRegistrant.registrant?._id,
            type: relationshipRegistrant.type,
          };
        }
      });

      updateRegistrant({
        variables: {
          _id: relationship.registrant?._id,
          record: {
            relationships:
              duplicateRelationships && duplicateRelationships.length
                ? [...duplicateRelationships]
                : [{ registrant: registrant._id, type: relationship.type }],
          },
        },
      });
    }

    updateRegistrant({
      variables: {
        _id: registrant._id,
        record: {
          email: email.toLowerCase(),
          firstName: firstName,
          lastName: lastName,
          primaryPhone: phoneNumber,
          rating: rating,
          ethnicity: ethnicity,
          postalCode: postalCode,
          source: source,
          salesRep: salesRep ? salesRep._id : null,
          onlineSalesRep: onlineSalesRep ? onlineSalesRep._id : null,
          realtorName,
          realtorType: workingWithRealtor,
          realtorEmail,
          brokerage,
          leaseType,
          relationships: filteredRelationship.length
            ? filteredRelationship.map((relationship: IRelationship) => {
                return {
                  ...relationship,
                  registrant: relationship.registrant?._id,
                };
              })
            : [],
          tags,
          emailUnsubscribed: emailUnsubscribed
            ? { ...emailUnsubscribed, schedule: emailUnsubscribed.schedule ? emailUnsubscribed.schedule._id : null }
            : null,
          textUnsubscribed: textUnsubscribed
            ? { ...textUnsubscribed, schedule: textUnsubscribed.schedule ? textUnsubscribed.schedule._id : null }
            : null,
        },
      },
    });
    setEdit(!edit);
  };

  const handleSalesRep = (id: string, type: string) => {
    let selectedUser: any;
    if (!id) {
      selectedUser = null;
    } else {
      if (type === "sales") {
        selectedUser = projectSales.find((user: IUser) => id === user._id);
      } else if (type === "online") {
        selectedUser = projectOnline.find((user: IUser) => id === user._id);
      }
    }
    if (type === "online") {
      setOnlineSalesRep(selectedUser!);
    } else if (type === "sales") {
      setSalesRep(selectedUser!);
    }
  };

  const handleAddRelationship = () => {
    setRelationships([
      ...relationships,
      {
        type: "",
        registrant: null,
      },
    ]);
  };

  const handleRelationship = (e: any, numIndex: number, value: any = null, type: string) => {
    if (e) {
      if (type === "search") {
        getRegistrants({
          variables: {
            filter: {
              project: registrant.project._id,
              search: value,
            },
          },
        });
      } else {
        if (e.target.name) {
          let updatedRelationships = relationships?.map((relationship: IRelationship, index: number) => {
            if (index === numIndex) {
              return {
                ...relationship,
                [e.target.name]: e.target.value,
              };
            } else return relationship;
          });
          setRelationships(updatedRelationships!);
        } else {
          let selectedRegistrant = projectRegistrants.find((registrant: IRegistrant) => registrant._id === value._id);
          let updatedRelationships = relationships?.map((relationship: IRelationship, index: number) => {
            if (index === numIndex && selectedRegistrant) {
              return {
                ...relationship,
                registrant: selectedRegistrant,
              };
            } else return relationship;
          });
          setRelationships(updatedRelationships!);
        }
      }
    }
  };

  const handleSubscribed = (type: string) => {
    if (type === "text") {
      if (textUnsubscribed) {
        setTextUnsubscribed(null);
      } else {
        setTextUnsubscribed({
          date: new Date(),
          type: "profile",
          schedule: null,
        });
      }
    } else if (type === "email") {
      if (emailUnsubscribed) {
        setEmailUnsubscribed(null);
      } else {
        setEmailUnsubscribed({
          date: new Date(),
          type: "profile",
          schedule: null,
        });
      }
    }
  };

  const connectRegistrant = () => {
    let allConnections = registrant.connections.map((connection: IConnection) => {
      return {
        ...connection,
        user: connection.user ? connection.user._id : null,
      };
    });
    updateRegistrant({
      variables: {
        _id: registrant._id,
        record: {
          connections: [
            ...allConnections,
            {
              date: new Date(),
              user: user?._id,
            },
          ],
        },
      },
    });
  };

  return (
    <Container>
      {show ? (
        <GlobalModal>
          {registrant.textUnsubscribed || registrant.emailUnsubscribed ? (
            <Box sx={{ mb: 2 }}>
              <Typography variant="h2" component="div" gutterBottom>
                Unsubscribes
              </Typography>
              <Box>
                {registrant.emailUnsubscribed ? (
                  <Box>
                    Unsubscribed from emails{" "}
                    {registrant.emailUnsubscribed.date ? convertAllDates(new Date(registrant.emailUnsubscribed?.date), "PPpp") : ""}{" "}
                    {registrant.emailUnsubscribed?.schedule
                      ? `from ${registrant.emailUnsubscribed?.schedule.emailTemplate.name} eBlast`
                      : null}
                  </Box>
                ) : null}
              </Box>
              <Box>
                {registrant.textUnsubscribed ? (
                  <Box>
                    Unsubscribed from SMS{" "}
                    {registrant.textUnsubscribed.date ? convertAllDates(new Date(registrant.textUnsubscribed?.date), "PPpp") : ""}{" "}
                    {registrant.textUnsubscribed?.schedule ? `from ${registrant.textUnsubscribed?.schedule.name} SMS Blast` : null}
                  </Box>
                ) : null}
              </Box>
            </Box>
          ) : null}
          <Typography variant="h2" component="div" gutterBottom>
            Statuses
          </Typography>
          {registrant.statuses.length ? (
            <Box>
              {registrant.statuses.map((status: IRegistrantStatus, index: number) => {
                return (
                  <Box sx={{ mb: 2 }}>
                    <Box>
                      {status.name} {status.source ? `(${properCase(status.source)})` : ""} - {convertAllDates(status.createdAt, "PPpp")}
                    </Box>
                    {status.questions.length ? (
                      <Box>
                        {status.questions.map((question: IAnswer) => {
                          return (
                            <Box>
                              <strong>{question.questionId.question}</strong> - {question.answer.join(", ")}
                            </Box>
                          );
                        })}
                      </Box>
                    ) : null}
                  </Box>
                );
              })}
            </Box>
          ) : (
            <Box sx={{ mt: 2 }}>None</Box>
          )}
          <Divider sx={{ mt: 2 }} />
          <Typography sx={{ mt: 2 }} variant="h2" component="div" gutterBottom>
            History
          </Typography>
          {registrant.count.length ? (
            <Box>
              {registrant.count.map((count: any, index: number) => {
                return (
                  <Box sx={{ mt: 2 }} key={index}>
                    <Box>Walk in - {convertAllDates(count.createdAt, "PPpp")}</Box>
                    {count.meetingWithRealtor !== null ? (
                      <Box>
                        <Box>{count.meetingWithRealtor ? "Meeting with Realtor at Office" : "Not Meeting with Realtor at Office"}</Box>
                        <Box>
                          {count.scheduleWithRealtor ? "Scheduled appointment with Realtor" : "Did not schedule appointment with Realtor"}
                        </Box>
                      </Box>
                    ) : null}
                  </Box>
                );
              })}
            </Box>
          ) : (
            <Box sx={{ mt: 2 }}>None</Box>
          )}
          <Divider sx={{ mt: 2 }} />
          <Typography sx={{ mt: 2 }} variant="h2" component="div" gutterBottom>
            Connections
          </Typography>
          {registrant.connections.length ? (
            <Box>
              {registrant.connections.map((connection: IConnection, index: number) => {
                return (
                  <Box sx={{ mt: 2 }} key={index}>
                    <Box>
                      {connection.user ? connection.user.fullName : "Unknown User"} -{" "}
                      {connection.date ? convertAllDates(connection.date, "PPpp") : "Unknown Date"}{" "}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          ) : (
            <Box sx={{ mt: 2 }}>None</Box>
          )}
        </GlobalModal>
      ) : null}
      {!edit ? (
        <div>
          <Typography variant="h2" component="div" gutterBottom>
            <FlexBetween sx={{ flexWrap: "wrap" }}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box>
                  <strong style={{ overflowWrap: "break-word", width: "calc(100% - 24px)" }}>
                    {firstName} {lastName}{" "}
                    {registrant.connections.length ? <CheckIcon sx={{ color: "success.main", alignSelf: "center" }} /> : null}
                  </strong>
                </Box>
                <Box sx={{ display: "inline-block", fontSize: "10px" }}>{registrant.alias ? <span>{registrant.alias}</span> : null}</Box>
              </Box>
              <Box sx={{ mt: 1 }}>
                <ContentPasteIcon
                  sx={{ cursor: "pointer" }}
                  fontSize="small"
                  onClick={() => {
                    setShow(true);
                    storeDispatch(handleModal(true));
                  }}
                />
                <EditIcon onClick={() => setEdit(!edit)} sx={{ cursor: "pointer", ml: 1 }} fontSize="small" />
              </Box>
            </FlexBetween>
          </Typography>
          {email ? <div>{email}</div> : null}
          {phoneNumber ? <div>{phoneNumber}</div> : null}
          <Box sx={{ mt: 2 }}>
            <div>
              Register Date: <strong>{convertAllDates(new Date(registrant?.createdAt!), "PP")}</strong>
            </div>
            {relationships.length
              ? relationships.map((relationship: IRelationship) => {
                  return (
                    <div>
                      {relationship.type}:{" "}
                      <strong
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(`/dashboard/project/${relationship.registrant?.project._id}/registrant/${relationship.registrant?._id}`)
                        }
                      >
                        {relationship.registrant?.firstName} {relationship.registrant?.lastName}
                      </strong>
                    </div>
                  );
                })
              : null}
            <div>
              Postal Code: <strong>{postalCode ? postalCode : "None"}</strong>
            </div>
            <div>
              Suspected Ethnicity: <strong>{ethnicity ? ethnicity : "Unidentified"}</strong>
            </div>
            <div>
              Rating: <strong>{rating}</strong>
            </div>
            <Box>
              {`Status${registrant.statuses.length > 1 ? `(${registrant.statuses.length})` : ""}`}:{" "}
              <strong>{registrant.statuses[registrant.statuses.length - 1].name}</strong>
            </Box>
            <div>
              Source: <strong>{capitalizeFirstLetter(registrant.source)}</strong>
            </div>
            {registrant.leaseType ? (
              <div>
                Lease Type: <strong>{camelToNormal(registrant.leaseType!)}</strong>
              </div>
            ) : null}
            <div>
              Sales:{" "}
              <strong>
                {salesRep
                  ? capitalizeFirstLetter(
                      salesRep.firstName && salesRep.lastName ? `${salesRep.firstName} ${salesRep.lastName}` : salesRep.fullName
                    )
                  : "None"}
              </strong>
            </div>
            <div>
              Online Rep:{" "}
              <strong>
                {onlineSalesRep
                  ? capitalizeFirstLetter(
                      onlineSalesRep.firstName && onlineSalesRep.lastName
                        ? `${onlineSalesRep.firstName} ${onlineSalesRep.lastName}`
                        : onlineSalesRep.fullName
                    )
                  : "None"}
              </strong>
            </div>
            <div>
              Realtor:{" "}
              <strong>
                <span style={{ color: workingWithRealtor === "isRealtor" ? "red" : "#000" }}>{camelToNormal(workingWithRealtor)}</span>
              </strong>
            </div>
            {workingWithRealtor === "hasRealtor" ? (
              <div>
                <div>
                  Realtor Name: <strong>{realtorName ? realtorName : "Doesn't Know"}</strong>
                </div>
                <div>
                  Realtor Email: <strong>{realtorEmail ? realtorEmail : "Doesn't Know"}</strong>
                </div>
              </div>
            ) : null}
            {workingWithRealtor === "hasRealtor" || workingWithRealtor === "isRealtor" ? (
              <div>
                Brokerage: <strong>{brokerage ? brokerage : "Doesn't Know"}</strong>
              </div>
            ) : null}
            <Box>
              Tags: <strong>{tags.length ? tags.map((tag: string) => tag).join(", ") : "No Tags"}</strong>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ mr: 0.5 }}>Email Subscriber:</Box>
                {!emailUnsubscribed ? (
                  <CheckIcon sx={{ color: "success.main" }} />
                ) : (
                  <CloseIcon sx={{ color: "error.main", alignSelf: "center" }} />
                )}
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ mr: 0.5 }}>SMS Subscriber:</Box>
                {!textUnsubscribed ? (
                  <CheckIcon sx={{ color: "success.main" }} />
                ) : (
                  <CloseIcon sx={{ color: "error.main", alignSelf: "center" }} />
                )}
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              Last Connected Date:{" "}
              {registrant.connections.length ? (
                <Box>
                  <strong>
                    {registrant.connections[registrant.connections.length - 1].date
                      ? convertAllDates(registrant.connections[registrant.connections.length - 1].date, "PPpp")
                      : "Unknown"}
                  </strong>
                </Box>
              ) : (
                <CloseIcon sx={{ color: "error.main", alignSelf: "center" }} />
              )}
            </Box>
            {registrant.connections.length &&
            registrant.connections.some(
              (connection: IConnection) =>
                connection.user &&
                connection.user._id === user?._id &&
                connection.date &&
                new Date(connection.date).toDateString() === new Date().toDateString()
            ) ? (
              <Box sx={{ textAlign: "center", mt: 2 }}>
                <strong>Connected with Registrant Today!</strong>
              </Box>
            ) : (
              <Box sx={{ mt: 2 }}>
                <Button color="success" onClick={() => connectRegistrant()} variant="contained">
                  Connect
                </Button>
              </Box>
            )}
          </Box>
        </div>
      ) : (
        <div>
          <form onSubmit={handleUpdate}>
            <FlexEnd sx={{ mb: 2 }}>
              <ArrowBackIcon onClick={() => setEdit(!edit)} sx={{ mr: 1, cursor: "pointer", color: "primary.main" }} fontSize="small" />
              <Tooltip title="Add Relationship">
                <IconButton sx={{ p: 0, mr: 1 }} onClick={() => handleAddRelationship()} color="primary" aria-label="upload picture">
                  <GroupAddIcon fontSize="small" sx={{ cursor: "pointer", color: "secondary.main" }} />
                </IconButton>
              </Tooltip>
              <IconButton sx={{ p: 0 }} type="submit" color="primary" aria-label="upload picture">
                <SaveIcon fontSize="small" sx={{ cursor: "pointer", color: "success.main" }} />
              </IconButton>
            </FlexEnd>
            <RegistrantInfo
              email={email}
              setEmail={setEmail}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              rating={rating}
              setRating={setRating}
              postalCode={postalCode}
              setPostalCode={setPostalCode}
              ethnicity={ethnicity}
              setEthnicity={setEthnicity}
              salesRep={salesRep}
              onlineSalesRep={onlineSalesRep}
              source={source}
              setSource={setSource}
              handleSalesRep={handleSalesRep}
              workingWithRealtor={workingWithRealtor}
              setWorkingWithRealtor={setWorkingWithRealtor}
              realtorName={realtorName}
              setRealtorName={setRealtorName}
              realtorEmail={realtorEmail}
              setRealtorEmail={setRealtorEmail}
              brokerage={brokerage}
              setBrokerage={setBrokerage}
              statuses={registrant.statuses}
              projectTags={projectTags}
              project={registrant.project._id}
              tags={tags}
              setTags={setTags}
              submitted={false}
              relationships={relationships}
              handleRelationship={handleRelationship}
              registrants={projectRegistrants}
              users={users}
              loading={loading}
              leaseType={leaseType}
              setLeaseType={setLeaseType}
            />
            <FormControlLabel
              checked={emailUnsubscribed ? false : true}
              control={<Switch onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSubscribed("email")} color="secondary" />}
              label="Email Subscribed"
            />
            <FormControlLabel
              checked={textUnsubscribed ? false : true}
              control={<Switch onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSubscribed("text")} color="secondary" />}
              label="Text Subscribed"
            />
          </form>
        </div>
      )}
    </Container>
  );
};

interface ChildProps {
  registrant: IRegistrant;
}

/* GQL */
const UPDATEREGISTRANT = gql`
  mutation registrantUpdateById($_id: MongoID!, $record: UpdateByIdRegistrantInput!) {
    registrantUpdateById(_id: $_id, record: $record) {
      record {
        _id
        firstName
        lastName
        email
        primaryPhone
        postalCode
        realtorName
        realtorEmail
        brokerage
        realtorType
        leaseType
        relationships {
          type
          registrant {
            _id
            firstName
            lastName
            email
            relationships {
              type
              registrant {
                _id
              }
            }
            project {
              _id
            }
          }
        }
        project {
          _id
          name
        }
        rating
        ethnicity
        statuses {
          name
          questions {
            questionId {
              _id
              question
            }
            answer
          }
          createdAt
        }
        salesRep {
          _id
          firstName
          lastName
          fullName
        }
        onlineSalesRep {
          _id
          firstName
          lastName
          fullName
        }
        questions {
          answer
          questionId {
            _id
            name
            question
          }
        }
        salesQuestions {
          answer
          questionId {
            _id
            name
            question
          }
        }
        mail {
          _id
        }
        process {
          _id
        }
        textUnsubscribed {
          date
          type
        }
        emailUnsubscribed {
          date
          type
        }
        connections {
          date
          user {
            _id
            fullName
          }
        }
        count {
          createdAt
          reason
          guests
          scheduleWithRealtor
          meetingWithRealtor
        }
        appointments {
          _id
          project {
            _id
            salesOffice
            name
          }
          user {
            fullName
            email
            realtor {
              _id
              fullName
              directPhone
            }
          }
          date
          location
          purchaserInfo {
            firstName
            lastName
            email
            primaryPhone
            numberOfGuests
          }
          questions {
            questionId {
              _id
              name
              question
              type
              choices {
                choice
                followUp {
                  _id
                }
              }
            }
            answer
          }
          status
          type
          notes
          confirmed
          cancelled
          cameIn
          noResponse
          salesNotes
          salesRep {
            _id
            fullName
          }
          registrant {
            _id
            tags
            source
          }
          createdAt
        }
        tags
        createdAt
        source
      }
    }
  }
`;

const UPDATEDREGISTRANT = gql`
  subscription updatedRegistrants {
    updatedRegistrants {
      _id
      email
      firstName
      lastName
      primaryPhone
      rating
      ethnicity
      onlineSalesRep {
        _id
        firstName
        lastName
        email
      }
      salesRep {
        _id
        firstName
        lastName
        email
      }
      relationships {
        type
        registrant {
          _id
          firstName
          lastName
          email
          relationships {
            type
            registrant {
              _id
            }
          }
          project {
            _id
          }
        }
      }
      realtorName
      realtorEmail
      brokerage
      realtorType
      emailUnsubscribed {
        date
        type
      }
      textUnsubscribed {
        date
        type
      }
      connections {
        date
        user {
          _id
          fullName
        }
      }
    }
  }
`;

const UPDATEREGISTRANTPROCESS = gql`
  mutation updateRegistrantProcess($user: MongoID!, $registrant: MongoID!, $type: String!) {
    updateRegistrantProcess(user: $user, registrant: $registrant, type: $type) {
      registrant {
        _id
        rating
        salesRep {
          _id
          firstName
          lastName
          fullName
        }
        onlineSalesRep {
          _id
          firstName
          lastName
          fullName
        }
      }
      process {
        _id
        currentProcess
        default
        steps {
          _id
          completed
          default
          dueDate
          type
          emailTemplate {
            name
            _id
          }
          user {
            _id
            fullName
          }
          name
        }
        users {
          _id
          fullName
        }
        timeBetweenSteps {
          days
          hours
          minutes
        }
        processType
        name
      }
    }
  }
`;

const GETREGISTRANTS = gql`
  query registrantMany($filter: FilterFindManyRegistrantInput) {
    registrantMany(filter: $filter, limit: 20) {
      _id
      firstName
      lastName
      email
      relationships {
        type
        registrant {
          _id
        }
      }
      project {
        _id
      }
    }
  }
`;

export default PersonalInformation;
