import { useState } from "react";
import { gql } from "@apollo/client";
import { IRegistrant } from "../../types/registrant";
import { useQuery } from "@apollo/client";
import { Container } from "../../commonStyles";
import { numToCurrency } from "../../utils/function";
import { Accordion, AccordionSummary, AccordionDetails, Box, Link, Stack, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Deals = ({ registrant }: DealsProps) => {
  const [deals, setDeals] = useState<any[]>([]);
  const [expansions, setExpansions] = useState<boolean[]>([]);

  useQuery(GETDEALS, {
    variables: { project: registrant.project._id, registrantEmail: registrant.email },
    onCompleted: (data) => {
      const deals = data.getRegistrantDeals.slice();
      deals.sort((deal1: any, deal2: any) => {
        let deal1Count = 0;
        let deal2Count = 0;
        if (deal1.cancelled.dateCancelled) ++deal1Count;
        if (deal1.rescission.dateRescinded) ++deal1Count;
        if (deal2.cancelled.dateCancelled) ++deal2Count;
        if (deal2.rescission.dateRescinded) ++deal2Count;

        return deal1Count - deal2Count;
      });

      setDeals(deals.filter((deal: any) => deal.cancelled.type !== "Admin"));
      let expansions = deals.map((deal: any) => !deal.cancelled.dateCancelled && !deal.rescission.dateRescinded);
      setExpansions(expansions);
    },
    onError: (e) => {
      console.log(e);
    },
  });

  return (
    <>
      {deals.length ? (
        <Container>
          <Typography variant="h2" component="div" gutterBottom>
            <strong>Deals</strong>
          </Typography>
          {deals.map((deal, key) => {
            const active = !deal.cancelled.dateCancelled && !deal.rescission.dateRescinded;
            const cancelled = deal.cancelled.dateCancelled;

            const linkToDeal =
              process.env.REACT_APP_ENV === "local"
                ? `http://localhost:3000/${deal.project._id}/dashboard/${deal.unit._id}`
                : `https://app.rdsre.ca/${deal.project._id}/dashboard/${deal.unit._id}`;

            return (
              <Accordion
                key={key}
                sx={{ mb: 1, border: "1px solid black", borderRadius: 1 }}
                expanded={expansions[key]}
                elevation={0}
                onChange={() => {
                  setExpansions((expansions) => {
                    let expansionsCopy = expansions.slice();
                    expansionsCopy[key] = !expansionsCopy[key];
                    return expansionsCopy;
                  });
                }}
              >
                <AccordionSummary
                  sx={{ borderBottom: expansions[key] ? "1px solid black" : "" }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  {active ? (
                    <Link style={{ color: "#000", textDecoration: "none" }} href={`${linkToDeal}`} target="_blank" rel="noreferrer">
                      <Typography>
                        <strong>Suite {deal.unit.suite}</strong>
                      </Typography>
                    </Link>
                  ) : (
                    <Typography>Suite {deal.unit.suite}</Typography>
                  )}
                  <Typography sx={active ? { color: "green" } : { color: "red" }}>
                    : {active ? "Active" : cancelled ? "Cancelled" : "Rescinded"}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={1}>
                    <Typography>
                      {deal.realtor.length && registrant.email === deal.realtor[0].email ? (
                        <Box>
                          <strong>Purchasers: </strong>
                          {deal.purchasers
                            ? deal.purchasers.map((purchaser: any) => `${purchaser.firstName} ${purchaser.lastName}`).join(", ")
                            : "None"}
                        </Box>
                      ) : (
                        <Box>
                          <strong>Realtor: </strong>
                          {deal.realtor && deal.realtor.length
                            ? deal.realtor.map((realtor: any) => `${realtor.firstName} ${realtor.lastName}`).join(", ")
                            : "None"}
                        </Box>
                      )}
                    </Typography>
                    <Typography>
                      <strong>Unit Type: </strong>
                      {deal.unit.unitType}
                    </Typography>
                    {active && (
                      <Typography>
                        <strong>Unit Status: </strong>
                        {deal.unit.status}
                      </Typography>
                    )}
                    <Typography>
                      <strong>Model Type: </strong>
                      {deal.unit.modelType}
                    </Typography>
                    <Box>
                      <Typography>
                        <strong>Options: </strong>
                        {!deal.options.length && "None"}
                      </Typography>
                      {deal.options.length
                        ? deal.options.map((option: any, key: number) => (
                            <Box key={key}>
                              <Typography sx={{ ml: 2 }} key={key}>
                                {key + 1}. {option.name}
                              </Typography>
                              <ul style={{ margin: 0 }}>
                                <li>
                                  <Typography> Purchase Amount: {option.purchaseAmount}</Typography>
                                </li>
                                <li>
                                  <Typography>Amount: {numToCurrency.format(option.amount)}</Typography>
                                </li>
                              </ul>
                            </Box>
                          ))
                        : null}
                    </Box>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Container>
      ) : null}
    </>
  );
};

type DealsProps = {
  registrant: IRegistrant;
};

const GETDEALS = gql`
  query getDeals($project: MongoID, $registrantEmail: String) {
    getRegistrantDeals(project: $project, email: $registrantEmail) {
      _id
      project {
        _id
        name
      }
      purchasers {
        firstName
        lastName
        email
      }
      rescission {
        dateRescinded
        reason
      }
      cancelled {
        dateCancelled
        type
        reason
      }
      unit {
        _id
        status
        suite
        unit
        level
        modelType
        unitType
        type
        outdoorType
        basePrice
        originalPrice
        bathroom
        exposure
        size
        outdoorSize
      }
      options {
        name
        purchaseAmount
        amount
      }
      realtor {
        firstName
        lastName
        email
        brokerage
        streetAddress
        city
        province
        country
        postalCode
      }
      signDate
      executeDate
      firmDate
      basePrice
      salesRep {
        firstName
        lastName
        email
      }
      tags
    }
  }
`;

export default Deals;
