/* Dependency Imports */
import { useState } from "react";
import { useTable, useSortBy, defaultColumn } from "react-table";
import {
  Box,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import TableViewIcon from "@mui/icons-material/TableView";
import Skeleton from "@mui/material/Skeleton";
import { FlexBetween } from "../../commonStyles";
import { IUser } from "../../types/user";

/* Project Imports */

const StandardTable = (props: ChildProps) => {
  const { columns, data, loading, handleGlobalFilterValue, count, download, width, maxHeight, user, title, buttons } = props;
  /* Hooks */
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
    },
    useSortBy
  );

  /* States */

  const [searchValue, setSearchValue] = useState<string>("");

  /* Queries/Mutations */

  /* Functions */

  const removeFilter = () => {
    setSearchValue("");
    handleGlobalFilterValue("");
  };

  return (
    <TableContainer
      component={Paper}
      elevation={8}
      sx={maxHeight ? { maxHeight, transform: "rotateX(180deg)" } : { transform: "rotateX(180deg)" }}
    >
      <Box sx={{ transform: "rotateX(180deg)" }}>
        <Toolbar sx={{ width: "100%", px: "16px !important" }}>
          <FlexBetween sx={{ alignItems: "center", display: "flex", width: "100% " }}>
            {handleGlobalFilterValue ? (
              <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
                <InputBase
                  sx={{ borderBottom: "1px solid #000" }}
                  value={searchValue}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleGlobalFilterValue(searchValue);
                      setSearchValue("");
                    }
                  }}
                  onChange={(e) => {
                    setSearchValue(e.target.value); // Set undefined to remove the filter entirely
                  }}
                  placeholder={`${count} records...`}
                  inputProps={{ "aria-label": "search" }}
                />
                <Box sx={{ mt: { xs: 1, sm: 0 }, display: "flex" }}>
                  <SearchIcon
                    onClick={() => handleGlobalFilterValue(searchValue)}
                    sx={{
                      height: "100%",
                      mx: { xs: 0, sm: 1.5 },
                      cursor: handleGlobalFilterValue ? "pointer" : "auto",
                    }}
                  />
                  <IconButton
                    className="std-table-remove-filter"
                    onClick={() => {
                      removeFilter();
                    }}
                    sx={{ cursor: handleGlobalFilterValue ? "pointer" : "auto" }}
                  >
                    <FilterAltOffIcon />
                  </IconButton>
                </Box>
              </Box>
            ) : title ? (
              <Typography variant="h2">
                <strong>{title}</strong>
              </Typography>
            ) : (
              <Box></Box>
            )}
            {buttons ? 
              <Box>{buttons}</Box>
            : null}
            {user && user.type !== "Sales" ? (
              <Box sx={{ ml: { xs: 3, sm: 0 } }}>
                <PictureAsPdfIcon sx={{ mr: 2, cursor: "pointer", color: "error.main" }} onClick={() => download("pdf")} />
                <TableViewIcon sx={{ cursor: "pointer", color: "success.main" }} onClick={() => download("excel")} />
              </Box>
            ) : null}
          </FlexBetween>
        </Toolbar>
        <Table {...getTableProps()} sx={{ minWidth: width ? "auto" : 650 }}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    sx={{ backgroundColor: "primary.main", color: "#fff" }}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    {column.id !== "selection" ? (
                      <TableSortLabel active={column.isSorted} direction={column.isSortedDesc ? "desc" : "asc"} />
                    ) : null}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {loading ? (
            <TableBody {...getTableBodyProps()}>
              <TableRow>
                <TableCell colSpan={columns.length} sx={{ textAlign: "center" }}>
                  <div>
                    <CircularProgress />
                  </div>
                  <div>
                    <em>Loading...</em>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody {...getTableBodyProps()}>
              {rows.length > 0 ? (
                <>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <TableRow {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return loading ? (
                            <TableCell {...cell.getCellProps()}>
                              <Skeleton animation="wave" variant="text" height={30} />
                            </TableCell>
                          ) : (
                            <TableCell {...cell.getCellProps()}>{cell.render("Cell")}</TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} sx={{ textAlign: "center" }}>
                    No Results Found. Try again!
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </Box>
    </TableContainer>
  );
};

/* Types */

interface ChildProps {
  columns: any;
  data: any;
  loading?: any;
  handleGlobalFilterValue?: any;
  count?: number;
  download?: any;
  width?: any;
  maxHeight?: string;
  user?: IUser | null;
  title?: string;
  buttons?: any;
}

/* GQL */

export default StandardTable;
