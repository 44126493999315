import React, { useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import { useMutation, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useAppDispatch } from "../../app/hooks";
import { FlexBetween, FlexEnd, Container } from "../../commonStyles";
import { IRegistrant, IAnswer } from "../../types/registrant";
import { showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";
import { selectProjectQuestions } from "../../features/projectSetting/projectSettingSlice";
import { IQuestion, IChoice } from "../../types/question";
import {
  selectQuestions,
  updateQuestions,
  updateSalesQuestions,
  setRegistrant,
  setQuestions,
  setSalesQuestions,
  selectSalesQuestions,
} from "../../features/registrants/registrantsSlice";
import { convertAllDates } from "../../utils/function";

const Questions = (props: ChildProps) => {
  const { registrant, type, questions } = props;
  const { projectid } = useParams();
  const storeDispatch = useAppDispatch();
  const projectQuestions = useSelector(selectProjectQuestions);
  const registrantQuestions = useSelector(selectQuestions);
  const salesQuestions = useSelector(selectSalesQuestions);

  const [edit, setEdit] = useState<boolean>(false);

  // Queries/Mutations

  const [updateRegistrantQuestions] = useMutation(UPDATEQUESTIONS, {
    onCompleted: (data) => {
      storeDispatch(setRegistrant(data.registrantUpdateById.record));
      storeDispatch(setQuestions(data.registrantUpdateById.record.questions));
      storeDispatch(showSuccessSnackbar("Registrant Questions Updated!"));
      setEdit(!edit);
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [updateAllSalesQuestions] = useMutation(UPDATEQUESTIONS, {
    onCompleted: (data) => {
      storeDispatch(setRegistrant(data.registrantUpdateById.record));
      storeDispatch(setSalesQuestions(data.registrantUpdateById.record.salesQuestions));
      storeDispatch(showSuccessSnackbar("Sales Questions Updated!"));
      setEdit(!edit);
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  // Functions

  const handleDisplayQuestion = (question: IQuestion) => {
    const registrantQuestion = questions.find(
      (registrantQuestion) => registrantQuestion.questionId && registrantQuestion.questionId._id === question._id
    );

    let answer = "";
    if (registrantQuestion && registrantQuestion.questionId.type === "date") {
      answer = convertAllDates(registrantQuestion.answer[0], "PP");
    } else if (registrantQuestion) {
      answer = registrantQuestion.answer.join(", ");
    }

    if (registrantQuestion && !question.subQuestion) {
      let subQuestion = question.choices.find(
        (choice: IChoice) => choice.choice === (registrantQuestion ? registrantQuestion.answer.join("") : "") && choice.followUp.length > 0
      );
      return (
        <div>
          <Box sx={{ mt: 1 }}>
            <div>
              <strong>{question.name}</strong>
            </div>
            <div>{registrantQuestion ? answer : "No Answer"}</div>
          </Box>
          <Box>
            {subQuestion?.followUp.map((question, i) => {
              let subQuestionAnswer = questions.find((registrantQuestion) => registrantQuestion.questionId._id === question._id);
              return (
                <Box key={i}>
                  <strong>{question.name}</strong>
                  <div>{subQuestionAnswer ? subQuestionAnswer.answer.join(", ") : "No Answer"}</div>
                </Box>
              );
            })}
          </Box>
        </div>
      );
    }
  };

  const handleChange = (e: any, i: number, question: IQuestion) => {
    if (type === "registrant") {
      storeDispatch(updateQuestions({ answer: [e], questionId: { _id: question._id, name: question.name, question: question.question } }));
    } else {
      storeDispatch(
        updateSalesQuestions({
          answer: [e],
          questionId: { _id: question._id, name: question.name, question: question.question },
        })
      );
    }
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>, i: number, registrantQuestion: IAnswer, question: IQuestion) => {
    let checkBoxAnswers = registrantQuestion ? [...registrantQuestion.answer] : [];

    if (checkBoxAnswers.includes(e.target.value)) {
      checkBoxAnswers.splice(checkBoxAnswers.indexOf(e.target.value), 1);
    } else {
      checkBoxAnswers = [...checkBoxAnswers, e.target.value];
    }

    if (type === "registrant") {
      storeDispatch(
        updateQuestions({ answer: checkBoxAnswers, questionId: { _id: question._id, name: question.name, question: question.question } })
      );
    } else {
      storeDispatch(
        updateSalesQuestions({
          answer: checkBoxAnswers,
          questionId: { _id: question._id, name: question.name, question: question.question },
        })
      );
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let questions = type === "registrant" ? registrantQuestions : salesQuestions;
    let registrantQuestionIds = questions.map((registrantQuestion) => {
      return {
        ...registrantQuestion,
        questionId: registrantQuestion.questionId._id,
      };
    });
    if (type === "registrant") {
      updateRegistrantQuestions({ variables: { _id: registrant._id, record: { questions: registrantQuestionIds } } });
    } else {
      updateAllSalesQuestions({ variables: { _id: registrant._id, record: { salesQuestions: registrantQuestionIds } } });
    }
  };

  const handleQuestions = (question: IQuestion, i: number, registrantQuestion: IAnswer) => {
    switch (question.type) {
      case "date":
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={registrantQuestion ? new Date(registrantQuestion.answer[0]) : null}
              onChange={(newValue) => handleChange(newValue, i, question)}
            />
          </LocalizationProvider>
        );
      case "text":
        return (
          <TextField
            fullWidth
            name={question.name}
            id={question._id}
            label={question.question}
            variant="outlined"
            value={registrantQuestion ? registrantQuestion.answer.join("") : ""}
            onChange={(e) => handleChange(e.target.value, i, question)}
          />
        );
      case "dropdown":
        return (
          <FormControl fullWidth>
            <InputLabel sx={{ fontWeight: question.question.includes("*") ? "700" : "100" }}>{`${question.question}`}</InputLabel>
            <Select
              name={question.name}
              label={`${question.question}`}
              value={registrantQuestion ? registrantQuestion.answer.join("") : ""}
              onChange={(e) => handleChange(e.target.value, i, question)}
            >
              {question.choices.map((choice, j) => (
                <MenuItem key={j} value={choice.choice}>
                  {choice.choice}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "radio":
        return (
          <FormControl fullWidth>
            <FormLabel>{`${question.question}`}</FormLabel>
            <RadioGroup
              row
              name={question.name}
              id={question._id}
              value={registrantQuestion ? registrantQuestion.answer.join("") : ""}
              onChange={(e) => handleChange(e.target.value, i, question)}
            >
              {question.choices.map((choice, j) => (
                <FormControlLabel key={j} value={choice.choice} control={<Radio />} label={choice.choice} />
              ))}
            </RadioGroup>
          </FormControl>
        );
      case "checkbox":
        return (
          <FormControl fullWidth>
            <FormLabel sx={{ fontWeight: question.question.includes("*") ? "700" : "100" }}>{question.question}</FormLabel>
            <FormGroup row>
              {question.choices.map((choice, j) => (
                <FormControlLabel
                  key={j}
                  name={question._id}
                  control={
                    <Checkbox
                      value={choice.choice}
                      name={question._id}
                      checked={registrantQuestion ? registrantQuestion.answer.includes(choice.choice) : false}
                      onChange={(e) => handleCheck(e, i, registrantQuestion, question)}
                    />
                  }
                  label={choice.choice}
                />
              ))}
            </FormGroup>
          </FormControl>
        );
      default:
        return <></>;
    }
  };

  const handleSubQuestions = (question: IQuestion, i: number, registrantQuestion: IAnswer) => {
    let subQuestion = question.choices.find(
      (choice: IChoice) => choice.choice === registrantQuestion.answer.join("") && choice.followUp.length > 0
    );

    let questions = type === "registrant" ? registrantQuestions : salesQuestions;

    if (subQuestion) {
      return subQuestion.followUp.map((question, i) => {
        let registrantQuestion = questions.find((registrantQuestion: IAnswer) => registrantQuestion.questionId._id === question._id);
        return <div key={i}>{handleQuestions(question, i, registrantQuestion!)}</div>;
      });
    }
  };

  return (
    <Container>
      {!edit ? (
        <div>
          <div>
            <FlexBetween>
              <Typography variant="h2" component="div" gutterBottom>
                <strong>{type === "registrant" ? "Registrant" : "Sales"} Questions</strong>
              </Typography>
              <EditIcon onClick={() => setEdit(!edit)} sx={{ cursor: "pointer" }} fontSize="small" />
            </FlexBetween>
          </div>
          <div>
            {questions.length > 0 ? (
              <div>
                {projectQuestions
                  .filter((question: IQuestion) => {
                    if (question.registrantType === "both") {
                      return true;
                    } else if (registrant.realtorType === "isRealtor" && projectid !== "6439c0326f66e01088cc59a0") {
                      return question.registrantType === "realtor" || question.registrantType === "purchaser";
                    } else return question.registrantType === "purchaser";
                  })
                  .map((question: any, i: number) => {
                    return <div key={i}>{handleDisplayQuestion(question)}</div>;
                  })}
              </div>
            ) : (
              <div>No Questions Answered</div>
            )}
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <FlexEnd sx={{ mb: 2 }}>
            <ArrowBackIcon onClick={() => setEdit(!edit)} sx={{ mr: 1, cursor: "pointer", color: "primary.main" }} fontSize="small" />
            <IconButton sx={{ p: 0 }} type="submit" color="primary" aria-label="save">
              <SaveIcon fontSize="small" sx={{ cursor: "pointer", color: "success.main" }} />
            </IconButton>
          </FlexEnd>
          {projectQuestions
            .filter((question: IQuestion) => {
              if (registrant.realtorType === "isRealtor" && projectid !== "6439c0326f66e01088cc59a0") {
                return question.registrantType === "realtor" || question.registrantType === "purchaser";
              } else return question.registrantType === "purchaser";
            })
            .sort((a: any, b: any) => {
              if (a.question.includes("*")) {
                return -1;
              } else return 0;
            })
            .map((question: IQuestion, i: number) => {
              let questions = type === "registrant" ? registrantQuestions : salesQuestions;
              if (!question.subQuestion) {
                let registrantQuestion = questions.find(
                  (registrantQuestion: IAnswer) => registrantQuestion.questionId._id === question._id
                );
                return (
                  <Box sx={{ mt: 2 }} key={i}>
                    <Box sx={{ mt: 2 }}>{handleQuestions(question, i, registrantQuestion!)}</Box>
                    {question.choices.find(
                      (choice: IChoice) =>
                        choice.choice === (registrantQuestion ? registrantQuestion.answer.join("") : "") && choice.followUp.length > 0
                    ) ? (
                      <Box sx={{ mt: 2 }}>{handleSubQuestions(question, i, registrantQuestion!)}</Box>
                    ) : null}
                  </Box>
                );
              } else return null;
            })}
        </form>
      )}
    </Container>
  );
};

interface ChildProps {
  registrant: IRegistrant;
  questions: IAnswer[];
  type: string;
}

const UPDATEQUESTIONS = gql`
  mutation registrantUpdateById($_id: MongoID!, $record: UpdateByIdRegistrantInput!) {
    registrantUpdateById(_id: $_id, record: $record) {
      record {
        _id
        firstName
        lastName
        email
        primaryPhone
        postalCode
        realtorName
        realtorEmail
        brokerage
        realtorType
        project {
          _id
          name
        }
        rating
        ethnicity
        statuses {
          name
          createdAt
        }
        salesRep {
          _id
          fullName
        }
        questions {
          answer
          questionId {
            _id
            name
            question
            type
          }
        }
        salesQuestions {
          answer
          questionId {
            _id
            name
            question
          }
        }
        mail {
          _id
        }

        process {
          _id
          currentProcess
          default
          steps {
            _id
            completed
            default
            dueDate
            type
            emailTemplate {
              _id
            }
            user {
              _id
              fullName
            }
            name
          }
          timeBetweenSteps {
            days
            hours
            minutes
          }
          processType
          name
        }
        emailUnsubscribed {
          date
          type
        }
        textUnsubscribed {
          date
          type
        }
        connections {
          date
          user {
            _id
            fullName
          }
        }
        count {
          createdAt
          reason
          guests
        }
        createdAt
        source
        appointments {
          _id
          project {
            _id
            salesOffice
            name
          }
          user {
            fullName
            email
            realtor {
              _id
              fullName
              directPhone
            }
          }
          date
          location
          purchaserInfo {
            firstName
            lastName
            email
            primaryPhone
            numberOfGuests
          }
          questions {
            questionId {
              _id
              name
              question
              type
              choices {
                choice
                followUp {
                  _id
                }
              }
            }
            answer
          }
          status
          type
          notes
          confirmed
          cancelled
          cameIn
          noResponse
          salesNotes
          salesRep {
            _id
            fullName
          }
          registrant {
            _id
            tags
            source
          }
          createdAt
        }
      }
    }
  }
`;

export default Questions;
