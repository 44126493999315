import { Dispatch, SetStateAction, SyntheticEvent, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers";
import {
  Autocomplete,
  TextField,
  Switch,
  FormControl,
  FormControlLabel,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";

import { selectSteps } from "../../../features/process/processSlice";
import { IStepTemplateTime, ITimeBetweenSteps } from "../../../types/process";
import { leaseTypes, stepSpecialDates } from "../../../utils/constant";
import { camelToNormal } from "../../../utils/function";

const CreateProcess = (props: ChildProps) => {
  const steps = useSelector(selectSteps);
  const {
    processName,
    setProcessName,
    processType,
    setProcessType,
    userType,
    setUserType,
    sourceType,
    setSourceType,
    leaseType,
    setLeaseType,
    viewType,
    setViewType,
    defaultProcess,
    setDefaultProcess,
    stepTemplates,
    timeBetweenSteps,
    handleStepsChange,
    deleteStep,
    handleTime,
    disabled,
    handleMove,
    setStepTemplates,
  } = props;

  const handleSpecialDate = (value: any, numIndex: number, name: string) => {
    if (setStepTemplates) {
      setStepTemplates(
        stepTemplates.map((stepTemplate: IStepTemplateTime, index: number) => {
          if (index === numIndex) {
            return {
              ...stepTemplate,
              date: value,
            };
          } else return stepTemplate;
        })
      );
    }
    if (handleTime) {
      if (value === "Specific Time" && setStepTemplates) {
      } else {
        handleTime(value, numIndex, name);
      }
    }
  };

  return (
    <div>
      <FormControl fullWidth variant="outlined" sx={{ mb: 2, mt: 2 }}>
        <TextField
          required
          fullWidth
          name="name"
          label="Process Name"
          type="text"
          variant="outlined"
          value={processName}
          disabled={disabled}
          onChange={setProcessName ? (e: React.ChangeEvent<HTMLInputElement>) => setProcessName(e.target.value) : undefined}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="demo-simple-select-label">Process Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={processType}
          label="Process Type"
          disabled={disabled}
          onChange={setProcessType ? (e: SelectChangeEvent<string>) => setProcessType(e.target.value) : undefined}
          required
        >
          <MenuItem value={"shortTerm"}>Short Term</MenuItem>
          <MenuItem value={"event"}>Event</MenuItem>
          <MenuItem value={"handover"}>Handover</MenuItem>
          <MenuItem value={"sales"}>Sales</MenuItem>
          <MenuItem value={"longTerm"}>Long Term</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>User Type</InputLabel>
        <Select
          value={userType}
          label="User Type"
          disabled={disabled}
          onChange={setUserType ? (e: SelectChangeEvent<string>) => setUserType(e.target.value) : undefined}
          required
        >
          <MenuItem value={"registrant"}>Registrant</MenuItem>
          <MenuItem value={"realtor"}>Realtor</MenuItem>
          <MenuItem value={"lease"}>Lease</MenuItem>
        </Select>
      </FormControl>
      {userType === "lease" ? (
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Lease Type</InputLabel>
          <Select
            value={leaseType}
            label="Source Type"
            disabled={disabled}
            onChange={setLeaseType ? (e: SelectChangeEvent<string>) => setLeaseType(e.target.value) : undefined}
            required
          >
            {leaseTypes.map((leaseType: string) => {
              return <MenuItem value={leaseType}>{camelToNormal(leaseType)}</MenuItem>;
            })}
          </Select>
        </FormControl>
      ) : (
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Source Type</InputLabel>
          <Select
            value={sourceType}
            label="Source Type"
            disabled={disabled}
            onChange={setSourceType ? (e: SelectChangeEvent<string>) => setSourceType(e.target.value) : undefined}
            required
          >
            <MenuItem value={"online"}>Online</MenuItem>
            <MenuItem value={"onsite"}>On Site</MenuItem>
          </Select>
        </FormControl>
      )}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Viewable By</InputLabel>
        <Select
          value={viewType}
          label="Viewable By"
          disabled={disabled}
          onChange={setViewType ? (e: SelectChangeEvent<string>) => setViewType(e.target.value) : undefined}
          required
        >
          <MenuItem value={"Admin"}>Admin</MenuItem>
          <MenuItem value={"Sales"}>Sales</MenuItem>
          <MenuItem value={"All"}>All</MenuItem>
        </Select>
      </FormControl>
      <FormControlLabel
        checked={defaultProcess}
        disabled={disabled}
        control={
          <Switch
            onChange={setDefaultProcess ? (e: React.ChangeEvent<HTMLInputElement>) => setDefaultProcess(!defaultProcess) : undefined}
            color="secondary"
          />
        }
        label="Default"
      />
      <FormControl fullWidth sx={{ my: 2 }}>
        <Autocomplete
          id="process-steps"
          disabled={disabled}
          options={steps}
          freeSolo={false}
          getOptionLabel={(option: any) => option.name}
          onChange={handleStepsChange ? (e, value) => handleStepsChange(e, value) : undefined}
          renderInput={(params) => <TextField {...params} variant="outlined" label="Select a Step" />}
        />
      </FormControl>
      {stepTemplates.length ? (
        <div>
          <Typography variant="h3" component="div" gutterBottom>
            <strong>Steps</strong>
          </Typography>
          <List sx={{ listStyleType: "ordered", pl: 2 }}>
            {stepTemplates.map((step: IStepTemplateTime, index: number) => {
              return (
                <ListItem
                  secondaryAction={
                    !disabled && deleteStep ? (
                      <>
                        {index ? (
                          <IconButton onClick={() => (handleMove ? handleMove(index, "up") : null)} edge="end" aria-label="delete">
                            <KeyboardArrowUpIcon sx={{ color: "primary.main" }} />
                          </IconButton>
                        ) : null}
                        {!index || index !== stepTemplates.length - 1 ? (
                          <IconButton onClick={() => (handleMove ? handleMove(index, "down") : null)} edge="end" aria-label="delete">
                            <KeyboardArrowDownIcon sx={{ color: "primary.main" }} />
                          </IconButton>
                        ) : null}
                        <IconButton onClick={() => deleteStep(index)} edge="end" aria-label="delete">
                          <DeleteIcon sx={{ color: "error.main" }} />
                        </IconButton>
                      </>
                    ) : null
                  }
                  sx={{ display: "list-item" }}
                  key={index}
                >
                  <ListItemText>{step.name}</ListItemText>
                  <FormControl variant="outlined" sx={{ mb: 2, mt: 2, width: "200px" }}>
                    <TextField
                      sx={{ mr: 2 }}
                      disabled={disabled}
                      name="days"
                      label="Days After Previously Completed Step"
                      type="number"
                      value={timeBetweenSteps[index].days}
                      placeholder={"Days After Previously Completed Step"}
                      onChange={(e: any) => (!disabled && handleTime ? handleTime(e.target.value, index, e.target.name) : undefined)}
                    />
                  </FormControl>
                  {step.type === "time" ? (
                    <FormControl sx={{ mb: 2, mt: 2, mr: 2, width: "200px" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          label={"Specific Time"}
                          value={
                            timeBetweenSteps[index].hours ? new Date(new Date().setHours(timeBetweenSteps[index].hours!, 0, 0, 0)) : null
                          }
                          onChange={(time: any) => (!disabled && handleTime ? handleTime(time, index, "hours") : null)}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  ) : null}
                  <FormControl variant="outlined" sx={{ mb: 2, mt: 2, width: "200px" }}>
                    <InputLabel id="demo-simple-select-label">Special Date</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name={"specialDate"}
                      fullWidth
                      label={"Special Date"}
                      value={step.date}
                      onChange={(e: any) => (!disabled && handleTime ? handleSpecialDate(e.target.value, index, e.target.name) : undefined)}
                    >
                      {stepSpecialDates.map((stepSpecialDate) => {
                        return <MenuItem value={stepSpecialDate}>{stepSpecialDate}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </ListItem>
              );
            })}
          </List>
        </div>
      ) : null}
    </div>
  );
};

interface ChildProps {
  processName: string;
  setProcessName?: Dispatch<SetStateAction<string>>;
  processType: string;
  setProcessType?: Dispatch<SetStateAction<string>>;
  userType: string;
  setUserType?: Dispatch<SetStateAction<string>>;
  leaseType?: string;
  setLeaseType?: Dispatch<SetStateAction<string>>;
  sourceType: string;
  setSourceType?: Dispatch<SetStateAction<string>>;
  viewType: string;
  setViewType?: Dispatch<SetStateAction<string>>;
  defaultProcess: boolean;
  setDefaultProcess?: Dispatch<SetStateAction<boolean>>;
  stepTemplates: IStepTemplateTime[];
  setStepTemplates?: Dispatch<SetStateAction<IStepTemplateTime[]>>;
  timeBetweenSteps: ITimeBetweenSteps[];
  handleStepsChange?: (event: SyntheticEvent<Element, Event>, value: any) => void;
  deleteStep?: (index: number) => void;
  handleTime?: (e: any, index: number, name: string) => any;
  handleMove?: (index: number, type: string) => any;
  disabled?: boolean;
}

export default CreateProcess;
