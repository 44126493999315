import { useState, useRef, useEffect } from "react";
import { Grid, Autocomplete, TextField, Box, Button } from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

const ReportingFilters = ({
  download,
  dataArray,
  setDataArray,
  queryData,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: ReportingFiltersProps) => {
  const [dateQuickFilter, setDateQuickFilter] = useState<string>("Last 7 Days");
  const [showCustomDate, setShowCustomDate] = useState<boolean>(false);

  const searchRef = useRef<any>(null);

  const dateQuickFilterOptions = ["None", "Today", "Last 7 Days", "Last 30 Days", "Last 90 Days", "Last 365 Days", "Custom"];

  useEffect(() => {
    const tryToLoad = () => {
      if (!searchRef.current) return false;

      const keyWithOnClick = Object.keys(searchRef.current).find((key) => key.match(new RegExp("__reactProps")));
      if (keyWithOnClick) {
        searchRef.current[keyWithOnClick].onClick(null);
        return true;
      }
      return false;
    };

    const tryToLoadPolling = () => {
      setTimeout(() => {
        const didLoad = tryToLoad();
        if (!didLoad) {
          tryToLoadPolling();
        }
      }, 200);
    };

    tryToLoadPolling();
  }, []);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Autocomplete
            options={dateQuickFilterOptions}
            value={dateQuickFilter}
            renderInput={(params) => {
              return <TextField label="Date Filter" sx={{ minWidth: "200px" }} {...params}></TextField>;
            }}
            onChange={(e) => {
              const option = (e.target as HTMLInputElement).innerText;
              setDateQuickFilter(option);
              const today = new Date();
              let newStartDate, newEndDate;
              if (option === "Today") {
                setShowCustomDate(false);
                newStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                newEndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
              } else if (option === "Last 7 Days") {
                setShowCustomDate(false);
                newStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6);
                newEndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
              } else if (option === "Last 30 Days") {
                setShowCustomDate(false);
                newStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 29);
                newEndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
              } else if (option === "Last 90 Days") {
                setShowCustomDate(false);
                newStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 89);
                newEndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
              } else if (option === "Last 365 Days") {
                setShowCustomDate(false);
                newStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 364);
                newEndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
              } else if (option === "Custom") {
                setShowCustomDate(true);
                newStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                newEndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
              }
              setDataArray([]);
              setStartDate(newStartDate);
              setEndDate(newEndDate);
            }}
          />
        </Grid>
        {showCustomDate && (
          <>
            <Grid item xs={12} sm={2.5}>
              <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="Start Date"
                    value={startDate ? new Date(startDate) : null}
                    onChange={(newDate) => {
                      setDataArray([]);
                      setStartDate(newDate);
                    }}
                    sx={{ width: '100%'}}
                  />
                </LocalizationProvider>
                <FilterAltOffIcon
                  sx={{ ml: 2 }}
                  onClick={() => {
                    setDataArray([]);
                    setStartDate(undefined);
                    setDateQuickFilter("None");
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="End Date"
                    value={endDate ? new Date(endDate) : null}
                    onChange={(newDate) => {
                      setDataArray([]);
                      setEndDate(newDate);
                    }}
                    sx={{ width: '100%'}}
                  />
                </LocalizationProvider>
                <FilterAltOffIcon
                  sx={{ ml: 2 }}
                  onClick={() => {
                    setDataArray([]);
                    setEndDate(undefined);
                    setDateQuickFilter("None");
                  }}
                />
              </Box>
            </Grid>
          </>
        )}
      </Grid>
      <Box
        sx={{
          mt: 2,
          mb: 2,
          display: "flex",
          justifyContent: { xs: "center", sm: "flex-start" },
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Button sx={{ width: "max-content" }} ref={searchRef} onClick={() => queryData()} variant="contained" color="primary">
          Populate Data
        </Button>
        <Button
          sx={{ width: "max-content", ml: { xs: 0, sm: 2 }, mt: { xs: 2, sm: 0 } }}
          disabled={dataArray.length === 0}
          onClick={download}
          variant="contained"
          color="primary"
        >
          Download Data
        </Button>
      </Box>
    </>
  );
};

interface ReportingFiltersProps {
  download: () => void;
  dataArray: any[];
  setDataArray: (newArr?: any) => void;
  queryData: () => any;
  startDate: Date | null | undefined;
  setStartDate: (newDate?: Date | null | undefined) => void;
  endDate: Date | null | undefined;
  setEndDate: (newData?: Date | null | undefined) => void;
}

export default ReportingFilters;
