import { createSlice } from "@reduxjs/toolkit";
import { IQuestion } from "../question/questionSlice";
import type { RootState } from "../../app/store";
import { IProcessTemplate } from "../../types/process";
import { IProject } from "../../types/project";
import { IRegistrant } from "../../types/registrant";
import { IUser } from "../../types/user";

const initialState: IProjectSettingState = {
  _id: "",
  processTemplates: [],
  questions: [],
  lists: [],
  registrants: [],
  registrantCount: 0,
  shortTermUsers: [],
  shortTermRealtorWalkIn: [],
  shortTermHasRealtorWalkIn: [],
  shortTermNoRealtorWalkIn: [],
  users: [],
  sales: [],
  online: [],
  scrum: [],
  admins: [],
  smsAfterHours: [],
  smsDuringHours: [],
  smsBeforeHours: [],
  smsUnsubscribe: "",
  smsFirstMessage: "",
  smsSecondMessage: "",
  smsThirdMessage: "",
  tags: [],
};

export const projectSettingSlice = createSlice({
  name: "projectSetting",
  initialState,
  reducers: {
    setProjectSetting: (state, action) => {
      state._id = action.payload._id;
      state.processTemplates = action.payload.processTemplates;
      state.questions = action.payload.questions;
      state.shortTermUsers = action.payload.shortTermUsers;
      state.shortTermRealtorWalkIn = action.payload.shortTermRealtorWalkIn;
      state.shortTermNoRealtorWalkIn = action.payload.shortTermNoRealtorWalkIn;
      state.shortTermHasRealtorWalkIn = action.payload.shortTermHasRealtorWalkIn;
      state.sales = action.payload.sales;
      state.online = action.payload.online;
      state.scrum = action.payload.scrum;
      state.smsAfterHours = action.payload.smsAfterHours;
      state.smsDuringHours = action.payload.smsDuringHours;
      state.smsBeforeHours = action.payload.smsBeforeHours;
      state.admins = action.payload.admins;
      state.tags = action.payload.tags;
      state.smsUnsubscribe = action.payload.smsUnsubscribe;
      state.smsFirstMessage = action.payload.smsFirstMessage;
      state.smsSecondMessage = action.payload.smsSecondMessage;
      state.smsThirdMessage = action.payload.smsThirdMessage;
      // Remove Duplicate Users
      state.users = [...action.payload.sales, ...action.payload.online, ...action.payload.scrum, ...action.payload.admins].filter(
        (obj1, i, arr) => arr.findIndex((obj2) => obj2._id === obj1._id) === i
      );
    },
    addRemoveQuestions: (state, action) => {
      state.questions = action.payload.questions;
    },
    addRemoveProcesses: (state, action) => {
      state.processTemplates = action.payload.processTemplates;
    },
    setLists: (state, action) => {
      state.lists = action.payload;
    },
    addList: (state, action) => {
      state.lists.push(action.payload);
    },
    updateList: (state, action) => {
      let list = state.lists.findIndex((list: IList) => list._id === action.payload._id);
      if (list >= 0) {
        state.lists[list] = action.payload;
      }
    },
    setShortTermUsers: (state, action) => {
      state.shortTermUsers = action.payload;
    },
    setShortTermRealtorWalkIn: (state, action) => {
      state.shortTermRealtorWalkIn = action.payload;
    },
    setShortTermHasRealtorWalkIn: (state, action) => {
      state.shortTermHasRealtorWalkIn = action.payload;
    },
    setShortTermNoRealtorWalkIn: (state, action) => {
      state.shortTermNoRealtorWalkIn = action.payload;
    },
    setSales: (state, action) => {
      state.sales = action.payload;
    },
    setOnline: (state, action) => {
      state.online = action.payload;
    },
    setScrum: (state, action) => {
      state.scrum = action.payload;
    },
    setAdmins: (state, action) => {
      state.admins = action.payload;
    },
    setUsers: (state, action) => {
      state.users = [...action.payload.sales, ...action.payload.online, ...action.payload.scrum, ...action.payload.admins].filter(
        (obj1, i, arr) => arr.findIndex((obj2) => obj2._id === obj1._id) === i
      );
    },
    setAllUsers: (state, action) => {
      state.users = action.payload
    },
    setSmsBeforeHours: (state, action) => {
      state.smsBeforeHours = action.payload;
    },
    setSmsAfterHours: (state, action) => {
      state.smsAfterHours = action.payload;
    },
    setSmsDuringHours: (state, action) => {
      state.smsDuringHours = action.payload;
    },
    setSmsUnsubscribe: (state, action) => {
      state.smsUnsubscribe = action.payload;
    },
    setSmsFirstMessage: (state, action) => {
      state.smsFirstMessage = action.payload;
    },
    setSmsSecondMessage: (state, action) => {
      state.smsSecondMessage = action.payload;
    },
    setSmsThirdMessage: (state, action) => {
      state.smsThirdMessage = action.payload;
    },
    setRegistrants: (state, action) => {
      state.registrants = action.payload.registrants;
      state.registrantCount = action.payload.count;
    },
    setTags: (state, action) => {
      state.tags = action.payload;
    },
    addRegistrant: (state, action) => {
      state.registrants.unshift(action.payload);
      state.registrantCount = state.registrantCount + 1;
    },
    updateRegistrants: (state, action) => {
      let registrant = state.registrants.findIndex((registrant: IRegistrant) => registrant._id === action.payload.data._id);
      if (registrant >= 0) {
        state.registrants[registrant] = {
          ...action.payload.data,
          [action.payload.name]: action.payload.value,
        };
      }
    },
  },
});

export const {
  setProjectSetting,
  addRemoveQuestions,
  addRemoveProcesses,
  setLists,
  addList,
  updateList,
  setRegistrants,
  updateRegistrants,
  setShortTermUsers,
  setShortTermRealtorWalkIn,
  setShortTermNoRealtorWalkIn,
  setShortTermHasRealtorWalkIn,
  setSales,
  setOnline,
  setScrum,
  setAdmins,
  setUsers,
  setAllUsers,
  setSmsDuringHours,
  setSmsBeforeHours,
  setSmsAfterHours,
  setSmsUnsubscribe,
  setSmsFirstMessage,
  setSmsSecondMessage,
  setSmsThirdMessage,
  addRegistrant,
  setTags,
} = projectSettingSlice.actions;

export const selectProjectTags = (state: RootState) => state.projectSetting.tags;
export const selectProjectProcesses = (state: RootState) => state.projectSetting.processTemplates;
export const selectProjectQuestions = (state: RootState) => state.projectSetting.questions;
export const selectList = (state: RootState) => state.projectSetting.lists;
export const selectSales = (state: RootState) => state.projectSetting.sales;
export const selectOnline = (state: RootState) => state.projectSetting.online;
export const selectScrum = (state: RootState) => state.projectSetting.scrum;
export const selectAdmins = (state: RootState) => state.projectSetting.admins;
export const selectSmsDuringHours = (state: RootState) => state.projectSetting.smsDuringHours;
export const selectSmsBeforeHours = (state: RootState) => state.projectSetting.smsBeforeHours;
export const selectSmsAfterHours = (state: RootState) => state.projectSetting.smsAfterHours;
export const selectSmsUnsubscribe = (state: RootState) => state.projectSetting.smsUnsubscribe;
export const selectSmsFirstMessage = (state: RootState) => state.projectSetting.smsFirstMessage;
export const selectSmsSecondMessage = (state: RootState) => state.projectSetting.smsSecondMessage;
export const selectSmsThirdMessage = (state: RootState) => state.projectSetting.smsThirdMessage;
export const selectUsers = (state: RootState) => state.projectSetting.users;
export const selectRegistrantList = (state: RootState) => state.projectSetting.registrants;
export const selectRegistrantCount = (state: RootState) => state.projectSetting.registrantCount;
export const selectShortTermUsers = (state: RootState) => state.projectSetting.shortTermUsers;
export const selectShortTermRealtorWalkIn = (state: RootState) => state.projectSetting.shortTermRealtorWalkIn;
export const selectShortTermHasRealtorWalkIn = (state: RootState) => state.projectSetting.shortTermHasRealtorWalkIn;
export const selectShortTermNoRealtorWalkIn = (state: RootState) => state.projectSetting.shortTermNoRealtorWalkIn;
export const selectCrmProjectId = (state: RootState) => state.projectSetting._id;

export interface IProjectSettingState {
  _id: string;
  processTemplates: IProcessTemplate[];
  questions: IQuestion[];
  lists: IList[];
  registrants: IRegistrant[];
  registrantCount: number;
  shortTermUsers: IUser[];
  shortTermRealtorWalkIn: IUser[];
  shortTermHasRealtorWalkIn: IUser[];
  shortTermNoRealtorWalkIn: IUser[];
  sales: IUser[];
  online: IUser[];
  scrum: IUser[];
  admins: IUser[];
  tags: string[];
  smsDuringHours: IUser[];
  smsAfterHours: IUser[];
  smsBeforeHours: IUser[];
  smsUnsubscribe: string;
  smsFirstMessage: string;
  smsSecondMessage: string;
  smsThirdMessage: string;
  users: IUser[];
}

export interface IList {
  _id: string;
  name: string;
  registrantStart: Date | null;
  registrantEnd: Date | null;
  beBackStart: Date | null;
  beBackEnd: Date | null;
  email: string;
  postalCode: string;
  ratings: string[];
  ethnicities: string[];
  sources: string[];
  realtorTypes: string[];
  project: IProject;
  questions: IQuestion[];
  archived: boolean;
}

export default projectSettingSlice.reducer;
