import { Dispatch, SetStateAction, useEffect } from "react";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker, DatePicker } from "@mui/x-date-pickers";
import {
  Button,
  TextField,
  Switch,
  FormControl,
  FormControlLabel,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  Autocomplete,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";

import { FlexBetween } from "../../../commonStyles";
import { useAppDispatch } from "../../../app/hooks";
import { handleModal } from "../../../features/modal/modalSlice";
import { selectTemplates } from "../../../features/emailTemplate/emailTemplateSlice";
import { useTemplatesQuery } from "../../../features/emailTemplate/emailTemplateHooks";
import { IEmailTemplate, IStepTemplate } from "../../../types/process";
import { subDays } from "date-fns";

const CreateProcessSteps = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const templates = useSelector(selectTemplates);
  const {
    stepId,
    handleStepSubmit,
    name,
    setName,
    type,
    setType,
    emailTemplate,
    setEmailTemplate,
    defaultStep,
    setDefaultStep,
    days,
    setDays,
    date,
    setDate,
    time,
    setTime,
    create,
    subSteps,
    setSubSteps,
    steps,
  } = props;
  const [getEmails] = useTemplatesQuery(1, 100, "_ID_DESC");

  useEffect(() => {
    getEmails();
    // eslint-disable-next-line
  }, []);

  const handleTime = (value: any, name: string) => {
    if (name === "hours") {
      setTime(value);
    } else if (name === "days" && setDays) {
      setDays(value);
    }
  };

  return (
    <div>
      <Typography variant="h2" component="div" gutterBottom>
        <strong>{stepId ? "Edit Step" : "Add Step"}</strong>
      </Typography>
      {stepId ? (
        <p>
          <strong>
            Please note these changes will affect steps for all projects. If you would like to edit a step for a specific project. Please
            create a new one.
          </strong>
        </p>
      ) : null}
      {steps ? (
        <p>
          <strong>Please note that substeps are always due 1 day after the previous step.</strong>
        </p>
      ) : null}
      <div>
        <form onSubmit={handleStepSubmit}>
          <FormControl fullWidth variant="outlined" sx={{ mb: 2, mt: 2 }}>
            <TextField
              required
              fullWidth
              name="name"
              label="Step Name"
              type="text"
              variant="outlined"
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={type}
              label="Question Type"
              onChange={(e: SelectChangeEvent<string>) => setType(e.target.value)}
              required
            >
              <MenuItem value={"regular"}>Regular</MenuItem>
              <MenuItem value={"automated"}>Automated</MenuItem>
              <MenuItem value={"time"}>Specific Time</MenuItem>
            </Select>
          </FormControl>
          {steps ? (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Autocomplete
                id="sub-step"
                multiple
                options={steps}
                freeSolo={false}
                disabled={false}
                getOptionLabel={(option: any) => option.name}
                value={subSteps}
                onChange={(e: any, value: any) => setSubSteps(value)}
                renderInput={(params: any) => <TextField {...params} variant="outlined" label="Sub Steps" />}
              />
            </FormControl>
          ) : null}
          <Box>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Autocomplete
                id="tags-standard"
                options={templates.map((template: IEmailTemplate) => template.name)}
                freeSolo={false}
                disabled={false}
                getOptionLabel={(option: any) => option}
                value={emailTemplate ? emailTemplate.name : ""}
                onChange={(e: any, value: any) => {
                  setEmailTemplate(value)
                }}
                renderInput={(params: any) => <TextField {...params} variant="outlined" label="Email Template" />}
              />
            </FormControl>
            {!stepId && !create ? (
              <>
                {date && setDate ? (
                  <FormControl sx={{ mb: 2, mt: 2, mr: 2, width: "200px" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label={"Date Due"}
                        value={date ? new Date(date) : null}
                        onChange={(date: any) => setDate(date)}
                        minDate={new Date()}
                        sx={{ width: '100%'}}
                      />
                    </LocalizationProvider>
                  </FormControl>
                ) : (
                  <FormControl variant="outlined" sx={{ mb: 2, mt: 2 }}>
                    <TextField
                      sx={{ mr: 2 }}
                      required
                      name="days"
                      label="Days After Previously Completed Step"
                      type="number"
                      value={days}
                      placeholder={"Days After Previously Completed Step"}
                      onChange={(e: any) => handleTime(e.target.value, "days")}
                    />
                  </FormControl>
                )}
                {type === "time" ? (
                  <FormControl sx={{ mb: 2, mt: 2, mr: 2, width: "200px" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label={"Specific Time"}
                        value={time}
                        onChange={(time: any) => handleTime(time, "hours")}
                      />
                    </LocalizationProvider>
                  </FormControl>
                ) : null}
              </>
            ) : null}
            <FormControlLabel
              checked={defaultStep}
              control={<Switch onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDefaultStep(!defaultStep)} color="secondary" />}
              label="Default"
            />
          </Box>
          <FlexBetween sx={{ mt: 2 }}>
            <Button color="success" variant="contained" type="submit">
              {stepId ? "Update" : "Create"}
            </Button>
            <Button onClick={() => storeDispatch(handleModal(false))} color="info" variant="contained">
              Cancel
            </Button>
          </FlexBetween>
        </form>
      </div>
    </div>
  );
};

interface ChildProps {
  stepId?: string;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  type: string;
  setType: Dispatch<SetStateAction<string>>;
  emailTemplate: IEmailTemplate | null;
  setEmailTemplate: (emailTemplate: string) => void;
  handleStepSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  defaultStep: boolean;
  setDefaultStep: Dispatch<SetStateAction<boolean>>;
  date?: Date | null;
  setDate?: Dispatch<SetStateAction<Date | null>>;
  days?: string;
  setDays?: Dispatch<SetStateAction<string>>;
  time?: Date | null;
  setTime: Dispatch<SetStateAction<Date | null>>;
  create?: boolean;
  steps?: IStepTemplate[];
  subSteps: IStepTemplate[];
  setSubSteps: Dispatch<SetStateAction<IStepTemplate[]>>;
}

export default CreateProcessSteps;
