import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

import { IProcess, IStep } from "../../types/process";

const initialState: IProcessState = {
  processes: [],
  steps: [],
  processCount: 0,
  stepCount: 0,
};

export const processSlice = createSlice({
  name: "process",
  initialState,
  reducers: {
    setProcesses: (state, action) => {
      state.processes = action.payload.items;
      state.processCount = action.payload.count;
    },
    addProcess: (state, action) => {
      state.processes.unshift(action.payload);
      state.processCount = state.processCount + 1;
    },
    updateProcess: (state, action) => {
      let process = state.processes.findIndex((process: IProcess) => process._id === action.payload._id);
      if (process >= 0) {
        // ✅ CORRECT: This object is still wrapped in a Proxy, so we can "mutate" it
        state.processes[process] = action.payload;
      }
    },
    addStep: (state, action) => {
      state.steps.unshift(action.payload);
      state.stepCount = state.stepCount + 1;
    },
    setSteps: (state, action) => {
      state.steps = action.payload.items;
      state.stepCount = action.payload.count;
    },
    updateStep: (state, action) => {
      let step = state.steps.findIndex((step: IStep) => step._id === action.payload._id);
      if (step >= 0) {
        // ✅ CORRECT: This object is still wrapped in a Proxy, so we can "mutate" it
        state.steps[step] = action.payload;
      }
    },
  },
});

export const { addProcess, updateProcess, addStep, setSteps, setProcesses, updateStep } = processSlice.actions;

export const selectSteps = (state: RootState) => state.process.steps;
export const selectStepCount = (state: RootState) => state.process.stepCount;
export const selectProcesses = (state: RootState) => state.process.processes;
export const selectProcessCount = (state: RootState) => state.process.processCount;

interface IProcessState {
  processes: IProcess[];
  steps: IStep[];
  stepCount: number;
  processCount: number;
}

export default processSlice.reducer;
