/* Dependency Imports */
import { useState, MouseEvent } from "react";
import { useParams } from "react-router-dom";
import { Button, Grid, Box, Menu, MenuItem } from "@mui/material";
import { Settings as SettingsIcon } from "@mui/icons-material";
import SpatialTrackingIcon from "@mui/icons-material/SpatialTracking";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ViewListIcon from "@mui/icons-material/ViewList";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useSelector } from "react-redux";

/* Project Imports */
import Lists from "./lists/Lists";
import { FlexEnd } from "../../commonStyles";
import { useSelectUser } from "../../features/auth/authHooks";
import Mailing from "./mailing/Mailing";
import Prebuilt from "./lists/Prebuilt";
import Actions from "./actions/Actions";
import Scrum from "./scrum/Scrum";
import Appointments from "./appointments/Appointments";
import { GlobalModal } from "../../features/modal/Modal";
import CreateRegistrant from "../projectSettings/CreateRegistrant";
import { selectProjectQuestions } from "../../features/projectSetting/projectSettingSlice";
import { selectProject } from "../../features/project/projectSlice";
import { useAppDispatch } from "../../app/hooks";
import { handleModal } from "../../features/modal/modalSlice";
import ProjectReport from "./report/ProjectReport";
import Text from "./text/Text";

const Project = () => {
  const { projectid } = useParams();
  const user = useSelectUser();
  const project = useSelector(selectProject);
  const questions = useSelector(selectProjectQuestions);
  const storeDispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  /* Functions */

  return (
    <div>
      <GlobalModal>
        <CreateRegistrant project={project} questions={questions} />
      </GlobalModal>
      <Box>
        <Button
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              display: "block",
              mb: 2,
            },
            display: "none",
          })}
          variant="contained"
          color="primary"
          onClick={(e: MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
        >
          Menu
        </Button>
        <Menu
          id="project-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => storeDispatch(handleModal(true))}>Create Registrant</MenuItem>
          <MenuItem component={"a"} href={`/dashboard/project/${projectid}/tracking`}>
            Tracking
          </MenuItem>
          {user?.type !== "Developer" ? (
            <MenuItem component={"a"} href={`/dashboard/project/${projectid}/reporting`}>
              Reporting
            </MenuItem>
          ) : null}
          {user?.type !== "Developer" ? (
            <MenuItem component={"a"} href={`/dashboard/project/${projectid}/settings`}>
              Project Settings
            </MenuItem>
          ) : null}
        </Menu>
        <FlexEnd
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
            width: "100%",
            mb: 1.5,
            textAlign: "right",
          })}
        >
          <Button onClick={() => storeDispatch(handleModal(true))} color="success" variant="contained">
            <PersonAddIcon />
            &nbsp;Create Registrant
          </Button>
          <Button sx={{ ml: 2 }} href={`/dashboard/project/${projectid}/tracking`} color="primary" variant="contained">
            <SpatialTrackingIcon />
            &nbsp;Tracking
          </Button>
          {user?.type !== "Developer" ? (
            <Button sx={{ ml: 2 }} href={`/dashboard/project/${projectid}/upload-registrant`} color="primary" variant="contained">
              <ViewListIcon />
              &nbsp;Upload Registrants
            </Button>
          ) : null}
          {user?.type !== "Developer" ? (
            <Button sx={{ ml: 2 }} href={`/dashboard/project/${projectid}/reporting`} color="primary" variant="contained">
              <AssessmentIcon /> &nbsp;Reporting
            </Button>
          ) : null}
          {user?.type !== "Developer" ? (
            <Button sx={{ ml: 2 }} href={`/dashboard/project/${projectid}/settings`} color="primary" variant="contained">
              <SettingsIcon />
              &nbsp;Project Settings
            </Button>
          ) : null}
        </FlexEnd>
        <Grid
          container
          spacing={2}
          sx={{
            flexDirection: {
              xs: "column-reverse",
              md: "row",
            },
          }}
        >
          <Grid item xl={user?.type === "Developer" ? 6 : 4} lg={6} md={6} sm={12} xs={12}>
            <Grid
              container
              sx={{
                flexDirection: {
                  xs: "column-reverse",
                  md: "row",
                },
              }}
              spacing={2}
            >
              <Grid sx={{ alignContent: "start" }} item xs={12}>
                <Lists projectId={projectid} custom={true} />
              </Grid>
              <Grid sx={{ height: "100%" }} item xs={12}>
                <Prebuilt projectId={projectid} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={user?.type === "Developer" ? 6 : 8} lg={6} md={6} sm={12} xs={12}>
            <Grid container spacing={2}>
              {user?.type !== "Developer" && project && project._id !== "6339946d6b2a3eadb397bf60" ? (
                <Grid item xs={12}>
                  <Appointments project={project!} />
                </Grid>
              ) : null}
              {project && project._id !== "6339946d6b2a3eadb397bf60" ? (
                <Grid sx={{ alignContent: "start" }} item xs={12}>
                  <ProjectReport project={project} />
                </Grid>
              ) : null}
              {user?.type !== "Developer" && project && project._id !== "6339946d6b2a3eadb397bf60" ? (
                <Grid item xs={12}>
                  <Scrum />
                </Grid>
              ) : null}
              {/* {user?.type !== "Developer" && user?.type !== 'Sales' ? (
                <Grid item xs={12} xl={6}>
                  <Inbox />
                </Grid>
              ) : null} */}
              {user?.type !== "Developer" && user?.type !== "Sales" ? (
                <Grid item xs={12} xl={6} lg={12} sm={12} md={12}>
                  <Mailing />
                </Grid>
              ) : null}
              {user?.type !== "Developer" && user?.type !== "Sales" ? (
                <Grid item xs={12} xl={6} lg={12} sm={12} md={12}>
                  <Text />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

/* Types */

/* GQL */

export default Project;
