import { useState, useEffect, useMemo, useReducer } from "react";
import { useLazyQuery, gql } from "@apollo/client";
import { useSelector } from "react-redux";
import { Typography, Box, Grid, TextField, Modal, Backdrop } from "@mui/material";
import { useParams } from "react-router-dom";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { IProject } from "../../../types/project";
import { Container } from "../../../commonStyles";
import { IAppointment } from "../../../types/project";
import { convertAllDates, downloadExcel, downloadPdf } from "../../../utils/function";
import StandardTable from "../../tables/StandardTable";
import { useAppDispatch } from "../../../app/hooks";
import { showErrorSnackbar } from "../../../features/snackbar/snackbarSlice";
import { selectUser } from "../../../features/auth/authSlice";
import AppointmentsTable from "../../appointments/AppointmentsTable";

export const appointmentsReducer = (state: any, action: any) => {
  switch (action.type) {
    case "ADD":
      return [...state, action.payload.value];
    case "COPY":
      return [...state, action.payload];
    case "UPDATE":
      return state.map((state: any, index: number) => {
        if (state._id === action.payload._id) {
          return {
            ...state,
            [action.payload.field]: action.payload.value,
          };
        } else return state;
      });
    case "UPDATEALL":
      return action.payload;
    case "DELETE":
      return state.filter((state: any, index: number) => state._id !== action.payload._id);
    default:
      throw new Error();
  }
};

const Appointments = (props: ChildProps) => {
  const { projectid } = useParams();
  const user = useSelector(selectUser);
  const storeDispatch = useAppDispatch();
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const [appointmentsState, appointmentsDispatch] = useReducer(appointmentsReducer, []);
  const [selectedAppointment, setSelectedAppointment] = useState<any>(null);
  const [dateStart, setDateStart] = useState<any>(new Date(new Date().setHours(0, 0, 0, 0)));
  const [dateEnd, setDateEnd] = useState<any>(new Date(new Date().setHours(23, 59, 59, 59)));
  const [count, setCount] = useState<number>(0);
  const [dialogType, setDialogType] = useState<string>("");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [scheduleArray, setScheduleArray] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const [getAppointments, { loading }] = useLazyQuery(GETAPPOINTMENTS, {
    onCompleted: (data) => {
      let appointments = [...data.appointmentMany].sort((a: any, b: any) => {
        return new Date(a.date).valueOf() - new Date(b.date).valueOf();
      });

      const uniqueSchedule = [...new Set(appointments.map((item) => (item.schedule ? item.schedule.name : "No Event")))]; // [ 'A', 'B']

      let scheduleArray = uniqueSchedule.map((schedule: string) => {
        let selectedSchedule = [];
        if (schedule === "No Event") {
          selectedSchedule = appointments.filter((appointment: any) => !appointment.schedule);
        } else {
          selectedSchedule = appointments.filter((appointment: any) => appointment.schedule?.name! === schedule);
        }

        return {
          name: schedule,
          totalCreated: selectedSchedule.reduce(function (r, a) {
            return r + +(dateStart.valueOf() < new Date(a.createdAt).valueOf() && new Date(a.createdAt).valueOf() < dateEnd.valueOf());
          }, 0),
          totalBooked: selectedSchedule.reduce(function (r, a) {
            return r + +(dateStart.valueOf() < new Date(a.date).valueOf() && new Date(a.date).valueOf() < dateEnd.valueOf());
          }, 0),
          totalConfirmed: selectedSchedule.reduce(function (r, a) {
            return r + +(a.confirmed && dateStart.valueOf() < new Date(a.date).valueOf() && new Date(a.date).valueOf() < dateEnd.valueOf());
          }, 0),
          totalCancelled: selectedSchedule.reduce(function (r, a) {
            return r + +(a.cancelled && dateStart.valueOf() < new Date(a.date).valueOf() && new Date(a.date).valueOf() < dateEnd.valueOf());
          }, 0),
          totalCameIn: selectedSchedule.reduce(function (r, a) {
            return r + +(a.cameIn && dateStart.valueOf() < new Date(a.date).valueOf() && new Date(a.date).valueOf() < dateEnd.valueOf());
          }, 0),
          totalNoAction: selectedSchedule.reduce(function (r, a) {
            return (
              r +
              +(
                !a.cancelled &&
                !a.confirmed &&
                !a.cameIn &&
                dateStart.valueOf() < new Date(a.date).valueOf() &&
                new Date(a.date).valueOf() < dateEnd.valueOf()
              )
            );
          }, 0),
        };
      });

      if (scheduleArray.length) {
        let total = {
          name: "Total",
          totalCreated: scheduleArray.reduce((a, b) => a + b.totalCreated, 0),
          totalBooked: scheduleArray.reduce((a, b) => a + b.totalBooked, 0),
          totalConfirmed: scheduleArray.reduce((a, b) => a + b.totalConfirmed, 0),
          totalCancelled: scheduleArray.reduce((a, b) => a + b.totalCancelled, 0),
          totalCameIn: scheduleArray.reduce((a, b) => a + b.totalCameIn, 0),
          totalNoAction: scheduleArray.reduce((a, b) => a + b.totalNoAction, 0),
        };
        scheduleArray = [...scheduleArray, total];
      }

      setScheduleArray(scheduleArray);
      setAppointments(appointments);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Error getting appointments"));
    },
  });

  useEffect(() => {
    if (dateStart && dateEnd && dateStart.toString() !== "Invalid Date" && dateEnd.toString() !== "Invalid Date") {
      let filterQuery: any = {
        dates: [new Date(dateStart!), new Date(dateEnd!)],
        projects: [projectid],
      };
      getAppointments({
        variables: {
          filter: filterQuery,
        },
      });
    }
  }, [dateStart, dateEnd, getAppointments, projectid]);

  const downloadSchedule = (type: string, data: any) => {
    let unitColumns = [
      {
        label: "Schedule",
        id: "name",
      },
      {
        label: "Appointments Created",
        id: "totalCreated",
      },
      {
        label: "Scheduled Appointments",
        id: "totalBooked",
      },
      {
        label: "Appointments Confirmed",
        id: "totalConfirmed",
      },
      {
        label: "Appointments Cancelled",
        id: "totalCancelled",
      },
      {
        label: "Attended Appointments",
        id: "totalCameIn",
      },
      {
        label: "Appointments No Action Taken",
        id: "totalNoAction",
      },
    ];

    let widths = {
      name: 15,
      totalCreated: 15,
      totalBooked: 15,
      totalConfirmed: 15,
      totalCancelled: 15,
      totalCameIn: 15,
      totalNoAction: 15,
    };

    let pdfWidths = {
      name: 200,
      totalCreated: 200,
      totalBooked: 200,
      totalConfirmed: 200,
      totalCancelled: 200,
      totalCameIn: 200,
      totalNoAction: 200,
    };

    let allData = scheduleArray.map((data: any) => {
      return {
        name: data.name,
        totalCreated: data.totalCreated,
        totalBooked: data.totalBooked,
        totalConfirmed: data.totalConfirmed,
        totalCancelled: data.totalCancelled,
        totalCameIn: data.totalCameIn,
        totalNoAction: data.totalNoAction,
      };
    });

    let sheetTitle = `${convertAllDates(dateStart, "PP")} Schedule`;

    if (type === "excel") {
      downloadExcel([allData], [unitColumns], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([allData], [unitColumns], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  const scheduleColumn = useMemo(() => {
    const handleAppointments = (type: string, schedule: any) => {
      let filteredAppointments: IAppointment[] = [];
      if (schedule !== "No Event") {
        if (type === "created") {
          filteredAppointments = appointments.filter(
            (appointment: IAppointment) =>
              appointment.schedule &&
              appointment.schedule.name === schedule &&
              dateStart.valueOf() < new Date(appointment.createdAt).valueOf() &&
              new Date(appointment.createdAt).valueOf() < dateEnd.valueOf()
          );
        } else if (type === "scheduled") {
          filteredAppointments = appointments.filter(
            (appointment: IAppointment) =>
              appointment.schedule &&
              appointment.schedule.name === schedule &&
              dateStart.valueOf() < new Date(appointment.date).valueOf() &&
              new Date(appointment.date).valueOf() < dateEnd.valueOf()
          );
        } else if (type === "confirmed") {
          filteredAppointments = appointments.filter(
            (appointment: IAppointment) =>
              appointment.schedule &&
              appointment.schedule.name === schedule &&
              appointment.confirmed &&
              dateStart.valueOf() < new Date(appointment.date).valueOf() &&
              new Date(appointment.date).valueOf() < dateEnd.valueOf()
          );
        } else if (type === "cancelled") {
          filteredAppointments = appointments.filter(
            (appointment: IAppointment) =>
              appointment.schedule &&
              appointment.schedule.name === schedule &&
              appointment.cancelled &&
              dateStart.valueOf() < new Date(appointment.date).valueOf() &&
              new Date(appointment.date).valueOf() < dateEnd.valueOf()
          );
        } else if (type === "cameIn") {
          filteredAppointments = appointments.filter(
            (appointment: IAppointment) =>
              appointment.schedule &&
              appointment.schedule.name === schedule &&
              appointment.cameIn &&
              dateStart.valueOf() < new Date(appointment.date).valueOf() &&
              new Date(appointment.date).valueOf() < dateEnd.valueOf()
          );
        } else if (type === "noAction") {
          filteredAppointments = appointments.filter(
            (appointment: IAppointment) =>
              appointment.schedule &&
              appointment.schedule.name === schedule &&
              !appointment.cancelled &&
              !appointment.confirmed &&
              !appointment.cameIn &&
              dateStart.valueOf() < new Date(appointment.date).valueOf() &&
              new Date(appointment.date).valueOf() < dateEnd.valueOf()
          );
        }
      } else {
        if (type === "created") {
          filteredAppointments = appointments.filter(
            (appointment: IAppointment) =>
              !appointment.schedule &&
              dateStart.valueOf() < new Date(appointment.createdAt).valueOf() &&
              new Date(appointment.createdAt).valueOf() < dateEnd.valueOf()
          );
        } else if (type === "scheduled") {
          filteredAppointments = appointments.filter(
            (appointment: IAppointment) =>
              !appointment.schedule &&
              dateStart.valueOf() < new Date(appointment.date).valueOf() &&
              new Date(appointment.date).valueOf() < dateEnd.valueOf()
          );
        } else if (type === "confirmed") {
          filteredAppointments = appointments.filter(
            (appointment: IAppointment) =>
              !appointment.schedule &&
              appointment.confirmed &&
              dateStart.valueOf() < new Date(appointment.date).valueOf() &&
              new Date(appointment.date).valueOf() < dateEnd.valueOf()
          );
        } else if (type === "cancelled") {
          filteredAppointments = appointments.filter(
            (appointment: IAppointment) =>
              !appointment.schedule &&
              appointment.cancelled &&
              dateStart.valueOf() < new Date(appointment.date).valueOf() &&
              new Date(appointment.date).valueOf() < dateEnd.valueOf()
          );
        } else if (type === "cameIn") {
          filteredAppointments = appointments.filter(
            (appointment: IAppointment) =>
              !appointment.schedule &&
              appointment.cameIn &&
              dateStart.valueOf() < new Date(appointment.date).valueOf() &&
              new Date(appointment.date).valueOf() < dateEnd.valueOf()
          );
        } else if (type === "noAction") {
          filteredAppointments = appointments.filter(
            (appointment: IAppointment) =>
              !appointment.schedule &&
              !appointment.cancelled &&
              !appointment.confirmed &&
              !appointment.cameIn &&
              dateStart.valueOf() < new Date(appointment.date).valueOf() &&
              new Date(appointment.date).valueOf() < dateEnd.valueOf()
          );
        }
      }

      appointmentsDispatch({
        type: "UPDATEALL",
        payload: filteredAppointments,
      });
      setOpen(true);
    };
    return [
      {
        Header: "Schedule",
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.name === "Total" ? "bold" : "normal" }}>{rowData.name}</Box>;
        },
      },
      {
        Header: "Appointments Created",
        accessor: (rowData: any) => {
          return (
            <Box
              sx={{ fontWeight: rowData.name === "Total" ? "bold" : "normal", cursor: rowData.name !== "Total" ? "pointer" : "auto" }}
              onClick={() => (rowData.totalCreated > 0 ? handleAppointments("created", rowData.name) : null)}
            >
              {rowData.totalCreated}
            </Box>
          );
        },
      },
      {
        Header: "Scheduled Appointments",
        accessor: (rowData: any) => {
          return (
            <Box
              sx={{ fontWeight: rowData.name === "Total" ? "bold" : "normal", cursor: rowData.name !== "Total" ? "pointer" : "auto" }}
              onClick={() => (rowData.totalBooked > 0 && rowData.name !== "Total" ? handleAppointments("scheduled", rowData.name) : null)}
            >
              {rowData.totalBooked}
            </Box>
          );
        },
      },
      {
        Header: "Appointments Confirmed",
        accessor: (rowData: any) => {
          return (
            <Box
              sx={{ fontWeight: rowData.name === "Total" ? "bold" : "normal", cursor: rowData.name !== "Total" ? "pointer" : "auto" }}
              onClick={() =>
                rowData.totalConfirmed > 0 && rowData.name !== "Total" ? handleAppointments("confirmed", rowData.name) : null
              }
            >
              {rowData.totalConfirmed}
            </Box>
          );
        },
      },
      {
        Header: "Appointments Cancelled",
        accessor: (rowData: any) => {
          return (
            <Box
              sx={{ fontWeight: rowData.name === "Total" ? "bold" : "normal", cursor: rowData.name !== "Total" ? "pointer" : "auto" }}
              onClick={() =>
                rowData.totalCancelled > 0 && rowData.name !== "Total" ? handleAppointments("cancelled", rowData.name) : null
              }
            >
              {rowData.totalCancelled}
            </Box>
          );
        },
      },
      {
        Header: "Attended Appointments",
        accessor: (rowData: any) => {
          return (
            <Box
              sx={{ fontWeight: rowData.name === "Total" ? "bold" : "normal", cursor: rowData.name !== "Total" ? "pointer" : "auto" }}
              onClick={() => (rowData.totalCameIn > 0 && rowData.name !== "Total" ? handleAppointments("cameIn", rowData.name) : null)}
            >
              {rowData.totalCameIn}
            </Box>
          );
        },
      },
      {
        Header: "Appointments No Action Taken",
        accessor: (rowData: any) => {
          return (
            <Box
              sx={{ fontWeight: rowData.name === "Total" ? "bold" : "normal", cursor: rowData.name !== "Total" ? "pointer" : "auto" }}
              onClick={() => (rowData.totalNoAction > 0 && rowData.name !== "Total" ? handleAppointments("noAction", rowData.name) : null)}
            >
              {rowData.totalNoAction}
            </Box>
          );
        },
      },
    ];
  }, [appointments, dateEnd, dateStart]);

  return (
    <Container sx={{ height: "100%" }}>
      <Typography sx={{ mb: 2, alignSelf: "center" }} variant="h2" component="div" gutterBottom>
        <strong>Appointments</strong>
      </Typography>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: 300,
              sm: 500,
              md: 700,
              lg: 1100,
              xl: 1400,
            },
            maxHeight: {
              xs: 300,
              sm: 600,
              md: 600,
              lg: 650,
              xl: 850,
            },
            overflowY: "auto",
            backgroundColor: "#fff",
            padding: "20px",
            border: "2px solid #000",
          }}
        >
          <AppointmentsTable
            appointments={appointmentsState}
            loading={loading}
            appointmentsDispatch={appointmentsDispatch}
            schedule={null}
            setSchedule={null}
            schedules={[]}
            selectedAppointment={selectedAppointment}
            setSelectedAppointment={setSelectedAppointment}
            dialogOpen={dialogOpen}
            dialogType={dialogType}
            setDialogOpen={setDialogOpen}
            setDialogType={setDialogType}
            allowSchedule={true}
            selectedRegistrants={[]}
            setSelectedRegistrants={null}
          />
        </Box>
      </Modal>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label={"Start Date (YYYY/MM/DD)"}
              value={dateStart}
              onChange={(newValue) => {
                setDateStart(newValue);
              }}
              sx={{ width: '100%'}}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label={"End Date (YYYY/MM/DD)"}
              value={dateEnd}
              onChange={(newValue) => {
                setDateEnd(newValue);
              }}
              sx={{ width: '100%'}}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Box>
        <StandardTable
          data={scheduleArray}
          loading={loading}
          columns={scheduleColumn}
          count={count}
          download={downloadSchedule}
          user={user}
        />
      </Box>
    </Container>
  );
};

const GETAPPOINTMENTS = gql`
  query appointmentMany($filter: FilterFindManyAppointmentInput) {
    appointmentMany(filter: $filter, limit: 100000) {
      _id
      project {
        _id
        salesOffice
        name
      }
      user {
        fullName
        email
        realtor {
          _id
          fullName
          directPhone
        }
      }
      date
      location
      purchaserInfo {
        firstName
        lastName
        email
        primaryPhone
        numberOfGuests
      }
      questions {
        questionId {
          _id
          name
          question
          type
          choices {
            choice
            followUp {
              _id
            }
          }
        }
        answer
      }
      status
      type
      notes
      confirmed
      cancelled
      cameIn
      noResponse
      salesNotes
      salesRep {
        _id
        fullName
      }
      schedule {
        _id
        name
      }
      registrant {
        _id
        email
        rating
        firstName
        lastName
        primaryPhone
        email
        realtorType
        salesRep {
          fullName
        }
        project {
          _id
        }
        source
        statuses {
          name
          questions {
            questionId {
              _id
              question
            }
            answer
          }
          createdAt
        }
        tags
      }
      createdAt
    }
  }
`;

interface ChildProps {
  project: IProject;
}

export default Appointments;
