import { useState, useMemo, useEffect } from "react";
import { useLazyQuery, useMutation, gql } from "@apollo/client";
import { Button, Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { FlexBetween, SettingContainer } from "../../../commonStyles";
import StandardTable from "../../tables/StandardTable";
import { GlobalModal } from "../../../features/modal/Modal";
import { handleModal } from "../../../features/modal/modalSlice";
import { showErrorSnackbar, showSuccessSnackbar } from "../../../features/snackbar/snackbarSlice";
import { useAppDispatch } from "../../../app/hooks";
import {
  addStep,
  selectStepCount,
  selectSteps,
  setSteps,
  selectProcesses,
  selectProcessCount,
  setProcesses,
  addProcess,
  updateStep,
  updateProcess,
} from "../../../features/process/processSlice";
import { useProcessQuery, useStepsQuery } from "../../../features/process/processHooks";
import CreateProcess from "./CreateProcess";
import CreateProcessSteps from "./CreateProcessSteps";
import { camelToNormal, convertAllDates } from "../../../utils/function";
import { IStepTemplate, IProcessTemplate, IEmailTemplate, IStepTemplateTime, ITimeBetweenSteps } from "../../../types/process";
import { selectTemplates } from "../../../features/emailTemplate/emailTemplateSlice";
import BasicTabs from "../../common/BasicTabs";

const Process = () => {
  const storeDispatch = useAppDispatch();
  useStepsQuery(1, 10000);
  const { loading } = useProcessQuery();
  const steps = useSelector(selectSteps);
  const count = useSelector(selectStepCount);
  const processes = useSelector(selectProcesses);
  const processCount = useSelector(selectProcessCount);
  const templates = useSelector(selectTemplates);

  const [value, setValue] = useState(0);
  const [stepId, setStepId] = useState<string>("");
  const [processId, setProcessId] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);

  // Step State
  const [emailTemplate, setEmailTemplate] = useState<IEmailTemplate | null>(null);
  const [name, setName] = useState<string>("");
  const [defaultStep, setDefaultStep] = useState<boolean>(false);
  const [type, setType] = useState<string>("");
  const [subSteps, setSubSteps] = useState<IStepTemplate[]>([]);
  // Process State
  const [processType, setProcessType] = useState<string>("");
  const [processName, setProcessName] = useState<string>("");
  const [userType, setUserType] = useState<string>("");
  const [viewType, setViewType] = useState<string>("");
  const [leaseType, setLeaseType] = useState<string>("");
  const [processSourceType, setProcessSourceType] = useState<string>("");
  const [stepTemplates, setStepTemplates] = useState<IStepTemplateTime[]>([]);
  const [defaultProcess, setDefaultProcess] = useState<boolean>(false);
  const [timeBetweenSteps, setTimeBetweenSteps] = useState<ITimeBetweenSteps[]>([]);

  // Queries/Mutations //

  const [createStep] = useMutation(CREATESTEP, {
    onCompleted: (data) => {
      storeDispatch(addStep(data.stepTemplateCreateOne.record));
      storeDispatch(showSuccessSnackbar("Step Template Created!"));
      storeDispatch(handleModal(false));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [updateStepTemplate] = useMutation(UPDATESTEP, {
    onCompleted: (data) => {
      storeDispatch(updateStep(data.stepTemplateUpdateById.record));
      storeDispatch(showSuccessSnackbar("Step Template Updated!"));
      storeDispatch(handleModal(false));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [createProcess] = useMutation(CREATEPROCESS, {
    onCompleted: (data) => {
      console.log(data, 'data');
      storeDispatch(addProcess(data.processTemplateCreateOne.record));
      storeDispatch(showSuccessSnackbar("Process Template Created!"));
      storeDispatch(handleModal(false));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [updateProcessTemplate] = useMutation(UPDATEPROCESS, {
    onCompleted: (data) => {
      storeDispatch(updateProcess(data.processTemplateUpdateById.record));
      storeDispatch(showSuccessSnackbar("Process Template Updated!"));
      storeDispatch(handleModal(false));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [getSteps] = useLazyQuery(GETSTEPS, {
    onCompleted: (data) => {
      storeDispatch(setSteps(data.stepTemplatePagination));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [getProcesses] = useLazyQuery(GETPROCESSES, {
    onCompleted: (data) => {
      storeDispatch(setProcesses(data.processTemplatePagination));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  useEffect(() => {
    if (value) {
      getSteps({ variables: { page: pageNumber, perPage: 15, sort: "_ID_DESC" } });
    } else {
      getProcesses({ variables: { page: pageNumber, perPage: 15, sort: "UPDATEDAT" } });
    }
    // eslint-disable-next-line
  }, [pageNumber]);

  // Functions

  // Change Tabs

  const createModal = () => {
    setName("");
    setDefaultStep(false);
    setType("");
    setStepId("");
    setProcessName("");
    setDefaultProcess(false);
    setProcessType("");
    setViewType("");
    setUserType("");
    setLeaseType("");
    setProcessSourceType("");
    setStepTemplates([]);
    setTimeBetweenSteps([]);
    setProcessId("");
    storeDispatch(handleModal(true));
  };

  const handleStepsChange = (event: any, value: any) => {
    if (value) {
      setStepTemplates([...stepTemplates, value]);
      setTimeBetweenSteps([
        ...timeBetweenSteps,
        {
          days: "0",
        },
      ]);
    }
  };

  const deleteStep = (numIndex: number) => {
    setStepTemplates(stepTemplates.filter((processStep: IStepTemplate, index: number) => numIndex !== index));
    setTimeBetweenSteps(timeBetweenSteps.filter((time: ITimeBetweenSteps, index: number) => numIndex !== index));
  };

  const handleTime = (value: any, numIndex: number, name: string) => {
    if (name === "specialDate") {
      let specialSteps = timeBetweenSteps.map((timeBetweenStep: any, index: number) => {
        if (index === numIndex) {
          return {
            ...timeBetweenStep,
            days: value,
            hours: null,
            minutes: null,
          };
        } else return timeBetweenStep;
      });
      setTimeBetweenSteps(specialSteps);
    } else if (name === "hours") {
      let hoursTimeBetweenSteps = timeBetweenSteps.map((timeBetweenStep: any, index: number) => {
        if (index === numIndex) {
          return {
            ...timeBetweenStep,
            hours: value.getHours(),
            minutes: value.getMinutes(),
          };
        } else return timeBetweenStep;
      });
      setTimeBetweenSteps(hoursTimeBetweenSteps);
    } else if (name === "days") {
      let daysTimeBetweenSteps = timeBetweenSteps.map((timeBetweenStep: any, index: number) => {
        if (index === numIndex) {
          return {
            ...timeBetweenStep,
            days: value,
          };
        } else return timeBetweenStep;
      });
      setTimeBetweenSteps(daysTimeBetweenSteps);
    }
  };

  const handleMove = (numIndex: number, type: string) => {
    let steps = [...stepTemplates];
    if (type === "up") {
      [steps[numIndex], steps[numIndex - 1]] = [steps[numIndex - 1], steps[numIndex]];
    } else if (type === "down") {
      [steps[numIndex], steps[numIndex + 1]] = [steps[numIndex + 1], steps[numIndex]];
    }
    setStepTemplates(steps);
  };

  const handleStepSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (type === "automated" && !emailTemplate) {
      return storeDispatch(showErrorSnackbar("Email Template is required for automated emails"));
    }

    if (stepId) {
      updateStepTemplate({
        variables: {
          _id: stepId,
          record: {
            name,
            default: defaultStep,
            type,
            emailTemplate: emailTemplate ? emailTemplate._id : null,
            subSteps: subSteps.map((subStep: IStepTemplate) => subStep._id),
          },
        },
      });
    } else {
      createStep({
        variables: {
          record: {
            name,
            default: defaultStep,
            type,
            emailTemplate: emailTemplate ? emailTemplate._id : null,
            subSteps: subSteps.map((subStep: IStepTemplate) => subStep._id),
          },
        },
      });
    }
  };

  const handleProcessSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let stepIds = stepTemplates.map((step: IStepTemplate) => step._id);

    if (timeBetweenSteps.some((time: any) => time.days === "")) return storeDispatch(showErrorSnackbar("Missing Values"));
    console.log(leaseType, 'leaseType');

    if (processId) {
      updateProcessTemplate({
        variables: {
          _id: processId,
          record: {
            name: processName,
            default: defaultProcess,
            type: processType,
            userType: userType,
            viewType: viewType,
            sourceType: processSourceType,
            stepTemplates: stepIds,
            leaseType: leaseType,
            timeBetweenSteps: timeBetweenSteps,
          },
        },
      });
    } else {
      createProcess({
        variables: {
          record: {
            name: processName,
            default: defaultProcess,
            type: processType,
            userType: userType,
            viewType: viewType,
            sourceType: processSourceType,
            stepTemplates: stepIds,
            leaseType: leaseType,
            timeBetweenSteps: timeBetweenSteps,
          },
        },
      });
    }
  };

  const handleGlobalFilterValue = (value: string) => {
    setPageNumber(1);
    if (value) {
      getSteps({ variables: { filter: { search: value }, page: 1, perPage: 15, sort: "_ID_DESC" } });
    } else {
      getProcesses({ variables: { filter: { search: value }, page: 1, perPage: 15, sort: "_ID_DESC" } });
    }
  };

  // Table Columns
  const processColumns = useMemo(() => {
    const getData = (rowData: IProcessTemplate) => {
      let processRowData: any = rowData;
      setProcessName(processRowData.name);
      setDefaultProcess(processRowData.default);
      setProcessType(processRowData.type);
      setUserType(processRowData.userType);
      setViewType(processRowData.viewType);
      setProcessSourceType(processRowData.sourceType);
      setStepTemplates(processRowData.stepTemplates);
      setTimeBetweenSteps(processRowData.timeBetweenSteps);
      setProcessId(processRowData._id);
      storeDispatch(handleModal(true));
    };

    return [
      {
        Header: "Name",
        accessor: (rowData: IProcessTemplate) => {
          return (
            <strong style={{ cursor: "pointer" }} onClick={() => getData(rowData)}>
              {rowData.name}
            </strong>
          );
        },
      },
      {
        Header: "Process Type",
        accessor: (rowData: IProcessTemplate) => {
          return camelToNormal(rowData.type);
        },
      },
      {
        Header: "Source Type",
        accessor: (rowData: IProcessTemplate) => {
          return rowData.sourceType ? camelToNormal(rowData.sourceType) : '';
        },
      },
      {
        Header: "User Type",
        accessor: (rowData: IProcessTemplate) => {
          return camelToNormal(rowData.userType);
        },
      },
      {
        Header: "Viewable By",
        accessor: (rowData: IProcessTemplate) => {
          return rowData.viewType;
        },
      },
      {
        Header: "Default",
        accessor: (rowData: IProcessTemplate) => {
          return rowData.default ? <span style={{ color: "green" }}>Yes</span> : <span style={{ color: "red" }}>No</span>;
        },
      },
      {
        Header: "Date Updated",
        accessor: (rowData: IProcessTemplate) => {
          return convertAllDates(rowData.updatedAt, "PPpp");
        },
      },
    ];
  }, [storeDispatch]);

  const stepColumns = useMemo(() => {
    const getData = (rowData: IStepTemplate) => {
      let stepRowData: IStepTemplate = rowData;
      setName(stepRowData.name);
      setDefaultStep(stepRowData.default);
      setEmailTemplate(stepRowData.emailTemplate!);
      setType(stepRowData.type);
      setStepId(stepRowData._id);
      setSubSteps(stepRowData.subSteps);
      storeDispatch(handleModal(true));
    };
    return [
      {
        Header: "Name",
        accessor: (rowData: IStepTemplate) => {
          return (
            <strong style={{ cursor: "pointer" }} onClick={() => getData(rowData)}>
              {rowData.name}
            </strong>
          );
        },
      },
      {
        Header: "Type",
        accessor: (rowData: IStepTemplate) => {
          return camelToNormal(rowData.type);
        },
      },
      {
        Header: "Default",
        accessor: (rowData: IStepTemplate) => {
          return rowData.default ? <span style={{ color: "green" }}>Yes</span> : <span style={{ color: "red" }}>No</span>;
        },
      },
      {
        Header: "Date Created",
        accessor: (rowData: IStepTemplate) => {
          return convertAllDates(rowData.createdAt, "PPpp");
        },
      },
    ];
  }, []);

  console.log(processes, 'processes');

  const tabs = [
    {
      label: "Processes",
      component: (
        <StandardTable
          data={processes}
          columns={processColumns}
          loading={loading}
          handleGlobalFilterValue={handleGlobalFilterValue}
          count={processCount}
        />
      ),
    },
    {
      label: "Steps",
      component: (
        <StandardTable
          data={steps}
          columns={stepColumns}
          loading={loading}
          handleGlobalFilterValue={handleGlobalFilterValue}
          count={count}
        />
      ),
    },
  ];

  const handleEmailTemplate = (emailTemplate: string) => {
    let selectedEmailTemplate = templates.find((template: IEmailTemplate) => emailTemplate === template.name);

    if (selectedEmailTemplate) {
      setEmailTemplate(selectedEmailTemplate);
    }
  };

  return (
    <SettingContainer>
      <GlobalModal>
        {value ? (
          <CreateProcessSteps
            stepId={stepId}
            handleStepSubmit={handleStepSubmit}
            name={name}
            setName={setName}
            emailTemplate={emailTemplate}
            setEmailTemplate={handleEmailTemplate}
            type={type}
            setType={setType}
            subSteps={subSteps}
            setSubSteps={setSubSteps}
            defaultStep={defaultStep}
            setDefaultStep={setDefaultStep}
            steps={steps}
            setTime={() => {}}
            setDays={() => {}}
            create={true}
          />
        ) : (
          <div>
            <Typography variant="h2" component="div" gutterBottom>
              <strong>{processId ? "Edit Process Template" : "Add Process Template"}</strong>
            </Typography>
            <form onSubmit={handleProcessSubmit}>
              <CreateProcess
                processType={processType}
                setProcessType={setProcessType}
                processName={processName}
                setProcessName={setProcessName}
                userType={userType}
                setUserType={setUserType}
                leaseType={leaseType}
                setLeaseType={setLeaseType}
                sourceType={processSourceType}
                setSourceType={setProcessSourceType}
                viewType={viewType}
                setViewType={setViewType}
                defaultProcess={defaultProcess}
                setDefaultProcess={setDefaultProcess}
                stepTemplates={stepTemplates}
                setStepTemplates={setStepTemplates}
                timeBetweenSteps={timeBetweenSteps}
                handleStepsChange={handleStepsChange}
                deleteStep={deleteStep}
                handleTime={handleTime}
                handleMove={handleMove}
              />
              <FlexBetween sx={{ mt: 2 }}>
                <Button color="success" variant="contained" type="submit">
                  {processId ? "Update" : "Create"}
                </Button>
                <Button onClick={() => storeDispatch(handleModal(false))} color="info" variant="contained">
                  Cancel
                </Button>
              </FlexBetween>
            </form>
          </div>
        )}
      </GlobalModal>
      <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: { xs: "flex-start", md: "space-between" } }}>
        <Typography variant="h2" component="div" gutterBottom>
          <strong>Process</strong>
        </Typography>
        <Button sx={{ width: "max-content", mb: { xs: 2, md: 0 } }} onClick={() => createModal()} color="primary" variant="contained">
          {value ? "Create A New Step" : "Create A New Process"}
        </Button>
      </Box>
      <BasicTabs tabs={tabs} sub={true} value={value} setValue={setValue} />
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
          {"<"}
        </Button>
        <span>{pageNumber}</span>
        <Button disabled={pageNumber === Math.ceil((value ? count : processCount) / 15)} onClick={() => setPageNumber(pageNumber + 1)}>
          {">"}
        </Button>
      </Box>
    </SettingContainer>
  );
};

const CREATESTEP = gql`
  mutation stepTemplateCreateOne($record: CreateOneStepTemplateInput!) {
    stepTemplateCreateOne(record: $record) {
      record {
        _id
        name
        emailTemplate {
          _id
          name
        }
        default
        type
        createdAt
      }
    }
  }
`;

const UPDATESTEP = gql`
  mutation stepTemplateUpdateById($_id: MongoID!, $record: UpdateByIdStepTemplateInput!) {
    stepTemplateUpdateById(_id: $_id, record: $record) {
      record {
        _id
        name
        default
        emailTemplate {
          _id
          name
        }
        type
        createdAt
      }
    }
  }
`;

const CREATEPROCESS = gql`
  mutation processTemplateCreateOne($record: CreateOneProcessTemplateInput!) {
    processTemplateCreateOne(record: $record) {
      record {
        _id
        name
        default
        type
        userType
        sourceType
        leaseType
        viewType
        stepTemplates {
          _id
          name
          type
        }
        timeBetweenSteps {
          days
          hours
          minutes
        }
        createdAt
        updatedAt
      }
    }
  }
`;

const UPDATEPROCESS = gql`
  mutation processTemplateUpdateById($_id: MongoID!, $record: UpdateByIdProcessTemplateInput!) {
    processTemplateUpdateById(_id: $_id, record: $record) {
      record {
        _id
        name
        default
        type
        userType
        sourceType
        viewType
        stepTemplates {
          _id
          name
          type
        }
        timeBetweenSteps {
          days
          hours
          minutes
        }
        createdAt
        updatedAt
      }
    }
  }
`;

const GETSTEPS = gql`
  query stepTemplatePagination(
    $filter: FilterFindManyStepTemplateInput
    $page: Int!
    $perPage: Int!
    $sort: SortFindManyStepTemplateInput
  ) {
    stepTemplatePagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      items {
        _id
        name
        default
        type
        subSteps {
          _id
          name
          emailTemplate {
            _id
          }
          type
        }
        emailTemplate {
          name
          _id
        }
        createdAt
      }
      count
    }
  }
`;

const GETPROCESSES = gql`
  query processTemplatePagination(
    $filter: FilterFindManyProcessTemplateInput
    $page: Int!
    $perPage: Int!
    $sort: SortFindManyProcessTemplateInput
  ) {
    processTemplatePagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      items {
        _id
        type
        name
        default
        userType
        viewType
        sourceType
        leaseType
        stepTemplates {
          _id
          name
          type
        }
        timeBetweenSteps {
          days
          hours
          minutes
        }
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export default Process;
